import {createSelector} from 'reselect';
import * as NavSelectors from 'modules/navigation/selectors';
import config from 'config/config';

export const matchingLevel = createSelector(
	NavSelectors.pathnameSelector,
	(pathname) => {
		const matchedRegex = (pathname || '').match(/\/level\/([^/?]*)/) || [];
		const levelId = matchedRegex[1];
		if (levelId) {
			return {alias: levelId, label: config.levels[levelId]}
		}
		return null;
	}
);

export const matchingLevelAlias = createSelector(
	matchingLevel,
	(level) => {
		return level?.alias;
	}
);