import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Box, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, IconButton, TableContainer, Table, TableRow, TableCell, TableBody, Button, TableHead, Dialog, DialogTitle, DialogContent, Alert, DialogActions} from '@mui/material';
import {
	ExpandMore as ExpandMoreIcon,
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Reorder as ReorderIcon,
	Quiz as QuizIcon,
} from '@mui/icons-material';
import * as Yup from 'yup';

// components
import Loader from "components/Loader/Loader";
import {Actions} from 'pages/actions';
import * as QuestionsSelectors from 'modules/questions/selectors';

import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import DraggableList from 'components/DraggableList/DraggableList';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useCloseOnSubmit, usePrevious } from 'utils/hooks';
import DropzoneAreaField from 'components/Form/DropzoneAreaField';
import AudioPlayer from 'components/AudioPlayer';

const goldenQuestionScheme = Yup.object().shape({
	title: Yup.string().required(),
	answer: Yup.string().required(),
	answerAudio: Yup.string().nullable(),
})
const initalValues = {title: '', answer: '', answerAudio: null}


function CreateEditGoldenQuestion({open = false, onClose, goldenQuestion, chapter}) {
	const {loading, submitting} = useSelector(QuestionsSelectors.loaders)
	const justSubmitted = useSelector(QuestionsSelectors.justSubmitted)
	const previouslySubmitted = usePrevious(justSubmitted);
	const channelError = useSelector(QuestionsSelectors.submitError)
	const dispatch = useDispatch();
    
	const fieldsDisabled = loading || submitting
	const submitError = channelError

	useCloseOnSubmit(QuestionsSelectors.justSubmitted, open, onClose);

	useEffect(() => {
		if (open) {
			dispatch(Actions.SINGLE_GOLDEN_QUESTION_FORM_OPENED());
		}
	}, [open, dispatch])

	useEffect(() => {
		if (open && !previouslySubmitted && justSubmitted) {
			onClose();
		}
	}, [previouslySubmitted, justSubmitted, onClose, open])
      
	function handleFormSubmit(values) {
		dispatch(Actions.GOLDEN_QUESTION_SINGLE_FORM_SUBMITTED({...values, chapterId: chapter.id}));
	}

	function handleClose(event, reason) {
		if (reason === "backdropClick") {return;}
		onClose();
	}

	const title = goldenQuestion ? `עדכון שאלת זהב - ${goldenQuestion.title || ''}` : "יצירת שאלת זהב";
	return (
		<Dialog open={open} onClose={handleClose} fullWidth onBackdropClick={() => false}>
			<DialogTitle sx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}>
				{title}
			</DialogTitle>
			<Formik
				initialValues={_.defaults(goldenQuestion, initalValues)}
				enableReinitialize
				validationSchema={goldenQuestionScheme}
				submitting={fieldsDisabled}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm }) => (
					<>
						<DialogContent dividers>
							<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
								<PromptIfDirty />
								<Field
									component={TextField}
									name="title"
									type="text"
									label="שאלה"
									multiline
									disabled={fieldsDisabled}
									inputProps={{sx: {unicodeBidi: 'plaintext'}}}
								/>
								<Field
									component={TextField}
									name="answer"
									type="text"
									label="תשובה"
									disabled={fieldsDisabled}
									inputProps={{sx: {unicodeBidi: 'plaintext'}}}
								/>
								<DropzoneAreaField
									name="answerAudio"
									label="גרור קובץ שמע (MP3) או לחץ (עד 1MB)"
									loadedLabel="קובץ שמע MP3 נטען"
									acceptedFiles={["audio/mpeg"]}
									sx={{mt: 2, minHeight: '200px'}}
									maxFileSize={1024 * 1024}
									disabled={fieldsDisabled}
								/>
								{submitError && <Alert sx={{mt: 2}} severity="error">{submitError}</Alert>}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose}>ביטול</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								disabled={fieldsDisabled}
								loading={submitting}
							>
								שמירה
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</Dialog>
	)
}

function ActionsColumn({row, onEditClick, onDeleteClick}) {
	return (
		<>
			<Tooltip arrow title="עריכה">
				<IconButton
					aria-label="עריכה"
					sx={{p: 1}}
					onClick={() => onEditClick(row)}
					size="large">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Tooltip arrow title="מחיקה">
				<IconButton
					aria-label="מחיקה"
					sx={{p: 1}}
					onClick={() => onDeleteClick(row)}
					color="error"
					size="large">
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const goldenQuestionsCells = [
	{id: 'title', numeric: false, label: 'שאלה'},
	{id: 'answer', numeric: false, label: 'תשובה'},
	{id: 'answerAudio', numeric: false, label: 'קובץ שמע', width: 150},
	{id: 'actions', numeric: false, disablePadding: false, label: 'פעולות', width: 120 },
];

export default function GoldenExerciseSection({chapter, defaultExpanded}) {
	const [isExpanded, setExpanded] = useState(defaultExpanded);
	const goldenQuestions = useSelector(QuestionsSelectors.goldenQuestions)
	const submitError = useSelector(QuestionsSelectors.submitError)
	const {loading, submitting} = useSelector(QuestionsSelectors.loaders)
	const [confirmation, setConfirmation] = useState({open: false});
	const [questionModal, setQuestionModal] = useState({question: null, open: false});
	const [dragEnabled, setDragEnabled] = useState(false);
	const [orderedQuestions, setOrderedQuestions] = useState(goldenQuestions);
	const dispatch = useDispatch();

	useEffect(() => {
		setOrderedQuestions(goldenQuestions)
		setDragEnabled(false)
	}, [goldenQuestions, setOrderedQuestions])

	function handleDeleteConfirm(goldenQuestion) {
		dispatch(Actions.GOLDEN_QUESTION_DELETE_SUBMITTED(goldenQuestion));
	}

	function cancelDrag() {
		setDragEnabled(false);
		setOrderedQuestions(goldenQuestions)
	}

	function openConfirmDelete(goldenQuestion) {
		dispatch(Actions.GOLDEN_QUESTION_CONFIRM_MODAL_OPENED(goldenQuestion));
		setConfirmation({
			open: true,
			title: `מחיקה - ${goldenQuestion.title}`,
			subtitle: 'האם למחוק שאלת זהב זו?\nשאלה זו תמחק והסטודנטים לא יוכלו לראות אותה יותר',
			confirmText: 'מחיקה',
			variant: 'error',
			onConfirm: () => handleDeleteConfirm(goldenQuestion),
			onCancel: () => setConfirmation({open: false}),
		});
	}

	function handleOrderUpdate() {
		const newOrder = orderedQuestions.map(c => c.id);
		dispatch(Actions.GOLDEN_QUESTIONS_ORDER_UPDATED({chapterId: chapter.id, questions: newOrder}));
	}

	function handleModalClose() {
		setQuestionModal({open: false, question: null});
	}
	return (

		<Accordion expanded={isExpanded} onChange={(_, expaned) => setExpanded(expaned)}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
			>
				<Typography variant='h4' fontWeight="bold">תרגיל הזהב</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 2}}>
				<Loader inline loading={loading || submitting}>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography variant='h2' gutterBottom sx={{color: 'text.hint', display: 'flex', alignItems: 'center', gap: 1}}><QuizIcon fontSize='medium' /> שאלות הזהב</Typography>
						<Box sx={{display: 'flex'}}>
							<TooltipButton
								title="ארגון שאלות"
								icon={ReorderIcon}
								size="large"
								onClick={() => dragEnabled ? cancelDrag() : setDragEnabled(true)}
								sx={{p: 1}}
							/>
							<TooltipButton
								title="הוספת שאלה חדשה"
								icon={AddIcon}
								iconProps={{color: 'primary'}}
								size="large"
								onClick={() => setQuestionModal({question: null, open: true})}
								sx={{p: 1}}
							/>
						</Box>
					</Box>
					<TableContainer sx={{maxHeight: 600, minHeight: 200}}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow hover>
									<TableCell padding="none" width={35}></TableCell>
									{goldenQuestionsCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											width={headCell.width}
											align="left"
											padding={headCell.disablePadding ? 'none' : 'normal'}
										>
											{headCell.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<DraggableList
								listComponent={TableBody}
								listSX={{display: 'table-row-group'}}
								itemSX={{display: 'table-row'}}
								listItemComponent={TableRow}
								listItemProps={{hover: true}}
								items={orderedQuestions}
								onChange={setOrderedQuestions}
								dragEnabled={dragEnabled}
								renderItemContent={(goldenQuestion, drag) => (
									<>
										<TableCell padding="none">
											{drag}
										</TableCell>
										<TableCell>
											{goldenQuestion.title}
										</TableCell>
										<TableCell sx={{unicodeBidi: 'plaintext'}}>{goldenQuestion.answer}</TableCell>
										<TableCell>
											{goldenQuestion.answerAudio && <AudioPlayer
												src={goldenQuestion.answerAudio}
												title="השמעת התשובה"
											/>}
										</TableCell>
										<TableCell align="right">
											<ActionsColumn
												row={goldenQuestion}
												onDeleteClick={openConfirmDelete}
												onEditClick={(row) => setQuestionModal({question: row, open: true})}
											/>
										</TableCell>
									</>
								)}
							/>
						</Table>
					</TableContainer>
					{dragEnabled && <Box sx={{mt: 2, display: 'flex', gap: 2, justifyContent: 'flex-end'}}>
						<Button onClick={cancelDrag}>ביטול</Button>
						<ProgressButton variant="contained" color="primary" onClick={handleOrderUpdate}>שמירת שינויים</ProgressButton>
					</Box>}
				</Loader>
				<ConfirmDialog
					{...confirmation}
					loading={submitting}
					alert={submitError}
					justSubmittedSelector={QuestionsSelectors.justSubmitted}
				/>
				<CreateEditGoldenQuestion open={questionModal.open} goldenQuestion={questionModal.question} chapter={chapter} onClose={handleModalClose} />
			</AccordionDetails>
		</Accordion>
	)
}
