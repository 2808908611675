import _ from 'lodash'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Autocomplete } from 'formik-mui';
import { useEffect, useState } from 'react';

export default function AutoCompleteFormik({options, optionKeyProp = 'id', optionLabelProp = 'name', valuesOnly = false, ...props}) {
	const {name} = props.field;
	const [optionsKeys, setOptionsKeys] = useState(valuesOnly ? options : _.map(options, optionKeyProp));
	const [optionsMap, setOptionsMap] = useState(valuesOnly ? options : _.keyBy(options, optionKeyProp));
	
	useEffect(() => {
		setOptionsKeys(valuesOnly ? options : _.map(options, optionKeyProp));
		setOptionsMap(valuesOnly ? options : _.keyBy(options, optionKeyProp));
	}, [valuesOnly, options, setOptionsKeys, optionKeyProp])

	const error = (props.form.touched[name] && props.form.errors[name]);
	return (
		<Autocomplete
			{...props}
			options={optionsKeys}
			autoHighlight
			getOptionLabel={(value) => valuesOnly ? value : _.get(optionsMap[value], optionLabelProp, '<Invalid>')}
			renderOption={(props, value) => {
				const option = optionsMap[value] || {[optionLabelProp]: '<Invalid>'};
				return (
					(
						<Box component="li"  {...props} key={option[optionKeyProp] || option[optionLabelProp]}>
							{valuesOnly ? value : option[optionLabelProp]}
						</Box>
					)
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					error={!!error}
					helperText={error || props.helperText}
					label={props.label}
					inputProps={{
						...params.inputProps,
						autoComplete: 'new-password', // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}
