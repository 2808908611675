
const style = {
	logotype: {
		color: "white",
		marginRight: theme => theme.spacing(2.5),
		marginLeft: theme => theme.spacing(2.5),
		fontWeight: 500,
		fontSize: 18,
		whiteSpace: "nowrap",
		// [theme.breakpoints.down("xs")]: {
		// 	display: "none",
		// },
	},
	appBar: {
		width: "100vw",
		zIndex: theme => theme.zIndex.drawer + 1,
	},
	toolbar: {
		paddingInlineStart: theme => theme.spacing(2),
		paddingInlineEnd: theme => theme.spacing(2),
	},
	hide: {
		display: "none",
	},
	grow: {
		flexGrow: 1,
	},
	headerMenu: {
		marginTop: theme => theme.spacing(2),
	},
	headerMenuList: {
		display: "flex",
		flexDirection: "column",
	},
	headerMenuItem: {
		"&:hover, &:focus": {
			backgroundColor: theme => theme.palette.primary.main,
			color: "white",
		},
	},
	headerMenuButton: {
		mr: 2,
		p: 0.5,
	},
	headerMenuButtonCollapse: {
		marginRight: theme => theme.spacing(2),
	},
	headerIcon: {
		fontSize: 28,
		color: "rgba(255, 255, 255, 0.35)",
	},
	headerIconCollapse: {
		color: "white",
	},
	profileMenu: {
		minWidth: 265,
	},
	profileMenuUser: {
		display: "flex",
		flexDirection: "column",
		padding: theme => theme.spacing(2),
	},
	logoutMenuItem: {
		color: theme => theme.palette.error.main,
	},
	profileMenuIcon: {
		marginRight: theme => theme.spacing(1),
	},
	profileMenuLink: {
		fontSize: 16,
		textDecoration: "none",
		"&:hover": {
			cursor: "pointer",
		},
	},
};

export default style;