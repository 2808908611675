import { Box, Typography, Link, useTheme, Button, Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import pdfIcon from 'images/pdf-icon.png';
import {
	ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

function PDFContent({item}) {
	return (
		<Box
			component={Link}
			rel="noreferrer noopener"
			target="_blank"
			href={item.url}
			underline="hover"
			color="inherit"
			sx={{alignSelf: 'center', display: 'flex', gap: 2, alignItems: 'center', width: '100%', maxWidth: '400px', ...item.sx}}
		>
			<Box
				component="img"
				src={pdfIcon}
				sx={{height: '50px'}}
			/>
			<Typography sx={{flex: 1, direction: 'rtl', textAlign: 'left'}} variant="h4">{item.title}</Typography>
		</Box>
	)
}

function IFrameContent({item}) {
	const theme = useTheme();
	return (
		<Box
			component="iframe"
			src={item.url}
			sx={{...theme.iframe, alignSelf: 'center', ...item.sx}}
			allow="autoplay; fullscreen; picture-in-picture; web-share; encrypted-media; clipboard-write"
		/>
	)
}

function AudioContent({item}) {
	return (
		<Box
			component="audio"
			controls="controls"
			width="180"
			height="90"
			src={item.url}
			sx={{alignSelf: 'center'}}
			allow="autoplay; fullscreen; picture-in-picture"
		/>
	)
}

function HtmlContent({item}) {
	return (
		<Box
			sx={{width: '100%', maxWidth: 600, alignSelf: 'center', ...item.sx}}
			dangerouslySetInnerHTML={{__html: item.html}}
		/>
	)
}

function DividerContent({item}) {
	return (
		<Divider />
	)
}

function ButtonLinkContent({item}) {
	return (
		<Button
			component={Link}
			rel="noreferrer noopener"
			target="_blank"
			href={item.url}
			underline="hover"
			color="primary"
			variant={item.variant || 'contained'}
			sx={{alignSelf: 'center', ...item.sx}}
		>
			<Typography variant="h4">{item.title}</Typography>
		</Button>
	)
}

function ImageContent({item}) {
	return (
		<Box
			component="img"
			sx={{maxWidth: '100%', alignSelf: 'center', ...item.sx}}
			src={item.src}
		/>
	)
}

function AccordionContent({item}) {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
			>
				<Typography variant='h4' fontWeight="bold" sx={{display: 'flex', alignItems: 'center'}}>{item.title}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 2, display: 'flex', flexDirection: 'column', pb: 6}}>
				{item.children.map(renderItemByType)}
			</AccordionDetails>
		</Accordion>
	)
}

function TypographyContent({item}) {
	return (
		<Typography variant={item.variant} sx={item.sx}>{item.text}</Typography>
	)
}

function renderItemByType(item, index) {
	switch (item.type) {
	case 'link:pdf':
		return <PDFContent key={index} item={item} />;
	case 'iframe':
		return <IFrameContent key={index} item={item} />;
	case 'audio':
		return <AudioContent key={index} item={item} />;
	case 'html':
		return <HtmlContent key={index} item={item} />;
	case 'buttonLink':
		return <ButtonLinkContent key={index} item={item} />;
	case 'image':
		return <ImageContent key={index} item={item} />;
	case 'accordion':
		return <AccordionContent key={index} item={item} />;
	case 'typography':
		return <TypographyContent key={index} item={item} />;
	case 'divider':
		return <DividerContent key={index} item={item}  />;
	default:
		console.warn(`Invalid content type: ${item.type}`)
		return null;
	}
}
export default function StructuredContent({content = []}) {
	return (
		<Box sx={{display: 'flex', flexDirection: 'column', gap: 2, py: 5}}>
			{content.map(renderItemByType)}
		</Box>
	);
}