import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	// Golden Questions
	'FETCH_GOLDEN_QUESTIONS_REQUEST',
	'FETCH_GOLDEN_QUESTIONS_SUCCESS',
	'FETCH_GOLDEN_QUESTIONS_FAILURE',

	'UPDATE_GOLDEN_QUESTION_REQUEST',
	'UPDATE_GOLDEN_QUESTION_SUCCESS',
	'UPDATE_GOLDEN_QUESTION_FAILURE',

	'UPDATE_GOLDEN_QUESTIONS_ORDER_REQUEST',
	'UPDATE_GOLDEN_QUESTIONS_ORDER_SUCCESS',
	'UPDATE_GOLDEN_QUESTIONS_ORDER_FAILURE',

	'DELETE_GOLDEN_QUESTION_REQUEST',
	'DELETE_GOLDEN_QUESTION_SUCCESS',
	'DELETE_GOLDEN_QUESTION_FAILURE',

	'SUBMIT_GOLDEN_QUESTION_FORM_REQUEST',
	'SUBMIT_GOLDEN_QUESTION_FORM_SUCCESS',
	'SUBMIT_GOLDEN_QUESTION_FORM_FAILURE',
	
	// MC Questions
	'FETCH_MC_QUESTIONS_REQUEST',
	'FETCH_MC_QUESTIONS_SUCCESS',
	'FETCH_MC_QUESTIONS_FAILURE',

	'UPDATE_MC_QUESTION_REQUEST',
	'UPDATE_MC_QUESTION_SUCCESS',
	'UPDATE_MC_QUESTION_FAILURE',

	'UPDATE_MC_QUESTIONS_ORDER_REQUEST',
	'UPDATE_MC_QUESTIONS_ORDER_SUCCESS',
	'UPDATE_MC_QUESTIONS_ORDER_FAILURE',

	'DELETE_MC_QUESTION_REQUEST',
	'DELETE_MC_QUESTION_SUCCESS',
	'DELETE_MC_QUESTION_FAILURE',

	'SUBMIT_MC_QUESTION_FORM_REQUEST',
	'SUBMIT_MC_QUESTION_FORM_SUCCESS',
	'SUBMIT_MC_QUESTION_FORM_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
