import _ from 'lodash';
import React from "react";
import {Paper} from '@mui/material';
import {
	CardGiftcard as CardGiftcardIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import StructuredContent from 'components/StructuredContent/StructuredContent';
import interviewCover from 'images/interviewCover.png';

export default function InterviewPreparationPage() {	
	return (
		<>
			<PageTitle title="איך להתכונן לראיון עבודה" icon={CardGiftcardIcon} />
			<Paper sx={{p: 2}}>
				<StructuredContent
					content={[
						{type: 'image', src: interviewCover},
						{type: 'buttonLink', title: 'להורדת המדריך המלא', url: 'https://storage.googleapis.com/confidence-eng-shared/modules/interviewPreparation/job-interview.pdf'},
						{type: 'divider'},
						{type: 'typography', text: 'מבוא', variant: 'h3', sx: {textAlign: 'center', mt: 2} },
						{type: 'audio', url: 'https://storage.googleapis.com/confidence-eng-shared/modules/interviewPreparation/interview-intro.mp3'},
						{type: 'typography', text: 'Part 1', variant: 'h3', sx: {textAlign: 'center', mt: 2} },
						{type: 'audio', url: 'https://storage.googleapis.com/confidence-eng-shared/modules/interviewPreparation/interview-part-1.mp3'},
						{type: 'typography', text: 'Part 2', variant: 'h3', sx: {textAlign: 'center', mt: 2} },
						{type: 'audio', url: 'https://storage.googleapis.com/confidence-eng-shared/modules/interviewPreparation/interview-part-2.mp3'},
						{type: 'typography', text: 'Part 3', variant: 'h3', sx: {textAlign: 'center', mt: 2} },
						{type: 'audio', url: 'https://storage.googleapis.com/confidence-eng-shared/modules/interviewPreparation/interview-part-3.mp3'},
					]}
				/>
			</Paper>
		</>
	)
}
