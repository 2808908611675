import React from "react";
import {Route, Routes} from "react-router-dom";
import {Box} from '@mui/material';

// styles
import style from "./styles";

// components
import StudentHeader from "components/Header/StudentHeader";
import StudentSidebar from "components/Sidebar/StudentSidebar";
import PermittedRoute from "components/PermittedRoute";

// pages
import Error from "pages/error";
import PickUnitPage from "pages/pickUnitPage";
import SingleLevelPage from "pages/singleLevel";
import UnitPage from "pages/unitPage";
import UserSettingsPage from "pages/userSettings";
import StudentChapterPage from "pages/studentChapter";
import EmailHelperPage from "pages/emailHelper";
import WinningPresentationPage from "pages/winningPresentation";
import InterviewPreparationPage from "pages/interviewPreparation";
import FacebookPage from "pages/facebook";
import * as AuthSelectors from 'modules/auth/selectors';

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useSelector } from "react-redux";

function Layout() {
	// global
	var layoutState = useLayoutState();
	const user = useSelector(AuthSelectors.user);
	return (
		<Box sx={style.root}>
			<StudentHeader kind="student" />
			<StudentSidebar />
			<Box
				sx={{
					...style.content,
					...(layoutState.isSidebarOpened ? style.contentShift : {}),
				}}
			>
				<Box sx={style.fakeToolbar} />
				<Routes>
					<Route path="" element={<PickUnitPage />} />
					<Route path="/level/:level" element={<PermittedRoute permission="core" page={SingleLevelPage} />} />
					<Route path="/chapters/:chapterId" element={<PermittedRoute permission="core" page={StudentChapterPage} />} />
					<Route path="/chapters/:chapterId/*" element={<PermittedRoute permission="core" page={StudentChapterPage} />} />
					<Route path="/user/settings" element={<UserSettingsPage />} />
					<Route path="/units/:unit/:page" element={<UnitPage />} />
					<Route path="/units/:unit/:level/:page" element={<UnitPage />} />
					{/* TODO: Ugly hack to remove bonuses from secondary tenant - to remove! */}
					{user?.tenantId === 111111 &&
						<>
							<Route path="/emailHelper" element={<PermittedRoute permission="emailHelper" page={EmailHelperPage} />} />
							<Route path="/bonus/presentation" element={<PermittedRoute permission="core" page={WinningPresentationPage} />} />
							<Route path="/bonus/interview" element={<PermittedRoute permission="core" page={InterviewPreparationPage} />} />
							<Route path="/bonus/facebook" element={<PermittedRoute permission="core" page={FacebookPage} />} />
						</>
					}
					<Route path="/*" element={<Error />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default Layout;
