import React, { useState } from "react";
import {
	Grid,
	CircularProgress,
	Typography,
	Button,
	Tabs,
	Tab,
	Box,
	TextField,
} from '@mui/material';
import { Alert } from '@mui/material';

import styles from "./styles";

import logo from "images/logo.png";
import loginBG from "images/login-bg.jpg";
import { Helmet } from "react-helmet-async";
import config from "config/config";
import {Actions as PageActions} from 'pages/actions';
import { useDispatch, useSelector } from "react-redux";
import * as AuthSelectors from 'modules/auth/selectors';

function getGreeting() {
	const now = new Date();
	const isSummer = now.getTimezoneOffset() === -180;
	const eveningTime = isSummer ? 19 : 17;
	const hour = now.getHours();
	if (hour < 5 || hour > 21) {
		return 'לילה טוב!'
	}
	if (hour < 12) {
		return 'בוקר טוב!'
	}
	if (hour < eveningTime) {
		return 'צהריים טובים!'
	}
	return 'ערב טוב!'
}

function Login() {

	const dispatch = useDispatch();
	const submitError = useSelector(AuthSelectors.submitError);
	const {loading} = useSelector(AuthSelectors.loaders);

	// local
	var [activeTabId, setActiveTabId] = useState(0);
	var [username, setUsernameValue] = useState("");
	var [password, setPasswordValue] = useState("");

	function handleSubmit(e) {
		e.preventDefault();
		dispatch(PageActions.LOGIN_FORM_SUBMITTED({username, password}))
	}

	return (
		<Grid container sx={styles.container}>
			<Helmet><title>התחברות - {config.appName}</title></Helmet>
			<Box sx={styles.formContainer}>
				<Box component="form" sx={styles.form} onSubmit={handleSubmit}>
					<Box sx={{textAlign: 'center', bgcolor: 'primary.main', p: 4}}>
						<img src={logo} alt="logo" style={styles.logotypeImage} />
					</Box>
					<Tabs
						value={activeTabId}
						onChange={(e, id) => setActiveTabId(id)}
						indicatorColor="primary"
						textColor="primary"
						centered
					>
						<Tab label="התחברות" sx={styles.tab} />
					</Tabs>
					{activeTabId === 0 && (
						<Box sx={{p: 4, pt: 0}}>
							<Typography variant="h2" sx={styles.greeting}>
								{getGreeting() + ' מוכנים ללמוד?'}
							</Typography>
							<TextField
								id="username"
								value={username}
								onChange={e => setUsernameValue(e.target.value)}
								margin="normal"
								label="שם משתמש"
								fullWidth
								inputProps={{sx: {direction: 'rtl'}}} // flipped by framework to ltr
							/>
							<TextField
								id="password"
								value={password}
								onChange={e => setPasswordValue(e.target.value)}
								margin="normal"
								label="סיסמה"
								type="password"
								fullWidth
								inputProps={{sx: {direction: 'rtl'}}} // flipped by framework to ltr
							/>
							{submitError && (<Alert severity="error">{submitError}</Alert>)}
							<Box sx={styles.formButtons}>
								{loading ? (
									<CircularProgress size={26} sx={styles.loginLoader} />
								) : (
									<Button
										disabled={
											username.length === 0 || password.length === 0
										}
										type="submit"
										variant="contained"
										color="primary"
										size="large"
										sx={{mt: 2}}
									>
										התחברות
									</Button>
								)}
							</Box>
						</Box>
					)}
				</Box>
			</Box>
			<Box sx={styles.logotypeContainer}>
				<Box component="img" src={loginBG} alt="background" sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
			</Box>
		</Grid>
	);
}

export default Login;
