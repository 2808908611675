/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

// Yup.addMethod(Yup.string, 'domain', function(message) {
//   const msg = message || 'Not a valid domain';
//   return this.test('domain', msg, function (value) {
//     const validHostRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
//     return !value || validHostRegex.test(value);
//   })
// })

Yup.addMethod(Yup.array, 'minOccurences', function(prop, value, num, message) {
	return this.test('minOccurences', message, function(list) {
	  return list.filter(l => l[prop] === value).length >= num;
	});
});

Yup.setLocale({
	mixed: {
		required: '${path} הינו שדה חובה',
	},
	string: {
		email: '${path} צריך להיות אימייל',
	},
	array: {
		min: '${path} צריך לפחות ערך אחד',
	},
})

export default Yup;
