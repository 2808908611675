import React from "react";
import { Grid, Paper, Typography, Button, Tooltip } from '@mui/material';
import { Link } from "react-router-dom";

// styles
import styles from "./styles";

export default function Error() {
	return (
		<Grid container sx={styles.container}>
			<Paper sx={styles.paperRoot} elevation={3}>
				<Typography
					variant="h1"
					color="primary"
					sx={{...styles.textRow, ...styles.errorCode}}
				>
					404
				</Typography>
				<Typography variant="h5" color="primary" sx={styles.textRow}>
					אופסס, נראה שהדף שחיפשתם is <Tooltip title="Absent without leave - נפקד (מונח צבאי)"><span>AWOL</span></Tooltip>
				</Typography>
				<Typography
					variant="h6"
					color="text"
					sx={{...styles.textRow}}
				>
					אל דאגה, בואו נחזור לדף הבית
				</Typography>
				<Button
					variant="contained"
					color="primary"
					component={Link}
					to="/"
					size="large"
					sx={styles.backButton}
				>
					חזרה לדף הבית
				</Button>
			</Paper>
		</Grid>
	);
}
