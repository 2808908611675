import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { DraggableItem } from './DraggableItem';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { List } from '@mui/material';


function DraggableListImpl({renderItemContent, renderSecondaryAction, items = [], onChange, listComponent, listItemComponent, listItemProps, listSX, itemSX, dragEnabled}) {
	const findCard = useCallback((id) => {
		const card = items.filter((c) => `${c.id}` === id)[0];
		return {
			card,
			index: items.indexOf(card),
		};
	}, [items]);
	const moveCard = useCallback((id, atIndex) => {
		const { card, index } = findCard(id);
		onChange(update(items, {
			$splice: [
				[index, 1],
				[atIndex, 0, card],
			],
		}));
	}, [findCard, items, onChange]);
	const [, drop] = useDrop(() => ({ accept: 'card' }));
	return (
		<DndProvider backend={HTML5Backend}>
			<List component={listComponent} ref={drop} sx={listSX}>
				{items.map((card) => (
					<DraggableItem
						key={card.id}
						id={`${card.id}`}
						item={card}
						sx={itemSX}
						dragEnabled={dragEnabled}
						itemComponent={listItemComponent}
						itemProps={listItemProps}
						renderItemContent={renderItemContent}
						renderSecondaryAction={renderSecondaryAction}
						moveCard={moveCard}
						findCard={findCard}/>
				))}
			</List>
		</DndProvider>
	);
};

export default function DraggableList(props) {
	return (
		<DndProvider backend={HTML5Backend}>
			<DraggableListImpl {...props} />
		</DndProvider>
	);
}
