import React, { useEffect, useState } from "react";
import {Route, Routes, Navigate, useLocation } from "react-router-dom";
import ScrollToTop from 'navigation/ScrollToTop';
import * as AuthSelectors from 'modules/auth/selectors';

// components
import AdminLayout from "../Layout/AdminLayout";
import StudentLayout from "../Layout/StudentLayout";

// pages
import Error from "../../pages/error/Error";
import Login from "../../pages/login";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";

export default function App() {
	const isAuthenticated = useSelector(AuthSelectors.isLoggedIn);
	const isAuthComplete = useSelector(AuthSelectors.isAuthComplete);
	const userRole = useSelector(AuthSelectors.userRole);
	const [showLoader, setShowLoader] = useState(false);
	const adminRoles = ['superAdmin'];

	useEffect(() => {
		if (!isAuthComplete) {
			setTimeout(() => setShowLoader(true), 300);
		}
	}, [isAuthComplete])

	if (!isAuthComplete) {
		return showLoader && (
			<Box sx={{bgcolor: 'background.paper', width: '95%', maxWidth: 400, my: 10, mx: 'auto', display: 'flex', flexDirection: 'column', gap: 6, alignItems: 'center', p: 10}}>
				<Typography variant="h1">Loading</Typography>
				<CircularProgress />
			</Box>
		)
	}

	const Layout = adminRoles.includes(userRole) ? AdminLayout : StudentLayout;

	// global
	return (
		<ScrollToTop>
			<Routes>
				<Route exact path="/" element={<Navigate to="/app" />} />
				<Route
					exact
					path="/app/login"
					element={<PublicRoute component={Login} />}
				/>
				<Route
					path="/app/*"
					element={<PrivateRoute component={Layout} />}
				/>
				<Route path="*" element={<Error />} />
			</Routes>
		</ScrollToTop>
	);

	// #######################################################################

	function PrivateRoute({ component }) {
		const location = useLocation();
		return isAuthenticated ? 
			(React.createElement(component)) : 
			(<Navigate
				to={{
					pathname: "/app/login",
					state: {
						from: location,
					},
				}}
			/>)
	}

	function PublicRoute({ component }) {
		return isAuthenticated ? (
			<Navigate
				to={{
					pathname: "/app",
				}}
			/>
		) : (
			React.createElement(component)
		)
	}
}
