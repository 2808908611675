import React from "react";
import {CircularProgress, Box, alpha} from '@mui/material';

export default function Loader({children, loading, sx = {}}) {
	return (
		<Box
			sx={{position: 'relative', minHeight: '100px', height: '100%', ...sx}}
		>
			{children}
			{
				loading && (<>
					<Box sx={{
						position: 'absolute',
						top: 0, bottom: 0, left: 0, right: 0,
						background: theme => alpha(theme.palette.background.paper, 0.5),
					}} />
					<CircularProgress sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
				</>)
			}
		</Box>
	);
}
