

const style = {
	root: {
		display: "flex",
		maxWidth: "100vw",
		overflowX: "hidden",
	},
	content: {
		flexGrow: 1,
		padding: theme => theme.spacing(3),
		width: `calc(100vw - 240px)`,
		height: '100vh',
		overflow: 'auto',
	},
	fakeToolbar: theme => theme.mixins.toolbar,
};

export default style;
