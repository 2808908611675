import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Box, Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Button} from '@mui/material';
import {
	MenuBook as MenuBookIcon,
	ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import * as Yup from 'yup';
import {v4 as uuid4} from 'uuid';
// components
import PageTitle from "../../components/PageTitle";
import Loader from "components/Loader/Loader";
import {Actions} from 'pages/actions';
import * as ChaptersSelectors from 'modules/chapters/selectors';
import {useParams} from 'react-router-dom';
import { Formik, Field, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import RichEditor from 'components/Form/RichEditor';
import GoldenExerciseSection from './GoldenExerciseSection';
import MultipleChoiceSection from './MultipleChoiceSection';
import DropzoneAreaField from 'components/Form/DropzoneAreaField';
import NamedDropzoneAreaField from 'components/Form/NamedDropzoneAreaField';

const chapterDetailsScheme = Yup.object().shape({
	name: Yup.string().required(),
	comments: Yup.string().nullable(),
	vocabulary: Yup.string().nullable(),
	summaryPDF: Yup.string().nullable(),
	content: Yup.string(),
	downloads: Yup.array().of(
		Yup.object().shape({
		  id: Yup.string().required(),
		  name: Yup.string(),
		  content: Yup.string(),
		})
	),
})
const chapterDetailsInitalValues = {name: '', vocabulary: '', content: '', comments: '', summaryPDF: null, downloads: []}

function ChapterDetailsForm({chapter, onSubmit, defaultExpanded}) {
	const [isExpanded, setExpanded] = useState(defaultExpanded);
	const {loading, submitting} = useSelector(ChaptersSelectors.loaders);
	const isDisabled = loading || submitting;

	return (
		<Formik
			initialValues={_.defaults(chapter, chapterDetailsInitalValues)}
			enableReinitialize
			validationSchema={chapterDetailsScheme}
			submitting={loading || submitting}
			onSubmit={(values, { setSubmitting }) => {
				onSubmit(values);
				setSubmitting(false);
			}}
		>
			{({ submitForm, values }) => (
				<Accordion expanded={isExpanded} onChange={(_, expaned) => setExpanded(expaned)}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
					>
						<Typography variant='h4' fontWeight="bold" sx={{display: 'flex', alignItems: 'center'}}>שם ותוכן הפרק</Typography>
						{isExpanded && <ProgressButton
							variant="contained"
							color="primary"
							size="small"
							sxWrap={{alignSelf: 'flex-start'}}
							onClick={e => {
								e.stopPropagation();
								submitForm()
							}}
							loading={submitting}
						>
							שמירה
						</ProgressButton>}
					</AccordionSummary>
					<AccordionDetails sx={{pt: 2}}>
						<Loader inline loading={loading}>
							<Box component="form">
								<PromptIfDirty />
								<Grid container spacing={2}>
									<Grid item md={6} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
										<Field
											component={TextField}
											name="name"
											type="text"
											label="שם הפרק"
											disabled={isDisabled}
										/>
										<Field
											component={TextField}
											multiline
											rows={4}
											label="אוצר מילים"
											name="vocabulary"
											disabled={isDisabled}
										/>
										<Field
											component={TextField}
											multiline
											rows={4}
											label="הערות"
											name="comments"
											disabled={isDisabled}
										/>
										<Box sx={{display: 'flex', gap: 2}}>
											<DropzoneAreaField
												name="summaryPDF"
												label="גרור קובץ סיכום (PDF) או לחץ"
												loadedLabel="קובץ PDF סיכום נטען"
												acceptedFiles={["application/pdf"]}
												sx={{mt: 2, minHeight: '200px'}}
												maxFileSize={30000000}
												disabled={isDisabled}
											/>
											<DropzoneAreaField
												name="vocabularyPDF"
												label="גרור קובץ אוצר מילים (PDF) או לחץ"
												loadedLabel="קובץ PDF אוצר מילים נטען"
												acceptedFiles={["application/pdf"]}
												sx={{mt: 2, minHeight: '200px'}}
												maxFileSize={30000000}
												disabled={isDisabled}
											/>
										</Box>
										<Box sx={{borderWidth: 1, borderRadius: 1, borderColor: t => t.palette.borderColor.light, borderStyle: 'solid', p: 3, position: 'relative'}}>
											<Typography component="legend" sx={{position: 'absolute', top: -10, left: 12, bgcolor: 'background.paper', fontSize: 14}}>קבצים להורדה</Typography>
											<Grid container spacing={2}>
												<FieldArray
													name="downloads"
													render={arrayHelpers => (
														<>
															{
																values.downloads.map((val, index) => (
																	<Grid item key={index} xs={6}>
																		<NamedDropzoneAreaField
																			name={`downloads.${index}`}
																			label="גרור קובץ {{name}} (PDF) או לחץ"
																			loadedLabel="קובץ PDF {{name}} נטען"
																			acceptedFiles={["application/pdf"]}
																			sx={{mt: 2, minHeight: '200px'}}
																			maxFileSize={30000000}
																			disabled={isDisabled}
																		/>
																	</Grid>
																))
															}
															<Grid item xs={12}>
																<Button variant='contained' color="primary" onClick={() => arrayHelpers.push({id: uuid4()})}>הוספת קובץ</Button>
															</Grid>
														</>
													)}
												/>

											</Grid>
										</Box>
									</Grid>
									<Grid item md={6}>
										<RichEditor
											name="content"
											label="תוכן הפרק"
											disabled={isDisabled}
										/>
									</Grid>
								</Grid>
							</Box>
						</Loader>
					</AccordionDetails>
				</Accordion>
			)}
		</Formik>
	)
}

export default function SingleChapter({isNew}) {
	const {formSubmitting} = useSelector(ChaptersSelectors.loaders)
	const rawSelectedChapter = useSelector(ChaptersSelectors.selectedChapter)


	const dispatch = useDispatch();
	const routeParams = useParams()
	const {chapterId} = routeParams;
	const selectedChapter = isNew ? _.omit(rawSelectedChapter, ['id']) : rawSelectedChapter;

	useEffect(() => {
		dispatch(Actions.SINGLE_CHAPTER_EDIT_MOUNTED({id: chapterId}));
	}, [chapterId, dispatch])

	function handleFormSubmit(values) {
		dispatch(Actions.SINGLE_CHAPTER_FORM_SUBMITTED(values));
	}

	/* Feed Modal */
	const title = chapterId ? `${_.get(selectedChapter, 'name', '')}` : "פרק חדש";

	/* End: Feed Modal */


	return (
		<>
			<PageTitle title={title} icon={MenuBookIcon} />
			<ChapterDetailsForm defaultExpanded={true} chapter={selectedChapter} loading={formSubmitting} onSubmit={handleFormSubmit} />
			{!isNew && (
				<>
					<GoldenExerciseSection defaultExpanded={false} chapter={selectedChapter} />
					<MultipleChoiceSection defaultExpanded={false} chapter={selectedChapter} />
				</>
			)}
		</>
	)
}
