import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	'FETCH_UNIT_PAGE_REQUEST',
	'FETCH_UNIT_PAGE_SUCCESS',
	'FETCH_UNIT_PAGE_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
