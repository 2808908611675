import React from "react";
import {Paper, Box, Grid, Typography, ButtonBase} from '@mui/material';
import {
	WorkspacePremium as WorkspacePremiumIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle";
import {Link} from 'react-router-dom';
import config from "config/config";

export default function LevelsPage() {
	return (
		<>
			<PageTitle title="שלבים" icon={WorkspacePremiumIcon} />
			<Grid container spacing={{xs: 2, md: 10}} justifyContent="center">
				{Object.entries(config.levels).map(([key, value]) => (
					<Grid item key={key}>
						<ButtonBase>
							<Box component={Link} to={`level/${key}`} sx={{textDecoration: 'none'}}>
								<Paper sx={{px: 6, py: 10, textAlign: 'center', minWidth: 300}}>
									<Box>
										<WorkspacePremiumIcon color="primary" sx={{fontSize: 60}} />
									</Box>
									<Typography variant='h1'>{value}</Typography>
								</Paper>
							</Box>
						</ButtonBase>
					</Grid>
				))}
			</Grid>
		</>
	)
}
