import _ from 'lodash';
import React from "react";
import {Paper} from '@mui/material';
import {
	CardGiftcard as CardGiftcardIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import StructuredContent from 'components/StructuredContent/StructuredContent';

export default function FacebookPage() {	
	return (
		<>
			<PageTitle title="קבוצת פייסבוק" icon={CardGiftcardIcon} />
			<Paper sx={{p: 2}}>
				<StructuredContent
					content={[
						{type: 'typography', text: 'הצטרפו לקבוצת הפייסבוק שלנו', variant: 'h1', sx: {textAlign: 'center', mt: 2, color: 'primary.main'} },
						{type: 'typography', text: 'קבוצה סגורה לתלמידי התוכנית', variant: 'h5', sx: {textAlign: 'center', mt: 2} },
						{type: 'typography', text: 'חברי הקבוצה מוזמנים להתייעץ, לשאול שאלות, לנהל דיונים, ולקבל תמיכה לגבי נושאי התוכנית ולימוד אנגלית באופן כללי', variant: 'h5', sx: {textAlign: 'center'} },
						// {type: 'buttonLink', title: 'הצטרפות לקבוצה', url: 'https://www.facebook.com/groups/english21days/', sx: {mt: 2}},
						{type: 'iframe', url: 'https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2Fenglish21days%2F&width=280&show_metadata=false&appId=863945553664833&height=239&locale=he_IL', sx: {width: 280, height: 240} },
					]}
				/>
			</Paper>
		</>
	)
}
