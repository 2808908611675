import React, { useEffect, useRef, useState } from "react";
import {
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { Inbox as InboxIcon } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

// styles
import styles from "./styles";

// components
import Dot from "../Dot";

export default function SidebarLink({
	link,
	icon,
	label,
	children,
	isSidebarOpened,
	nested,
	type,
	exact,
	baseLink,
	forwardRef,
}) {

	// local
	const [isOpen, setIsOpen] = useState(false);
	const itemsRef = useRef([]);
	const location = useLocation();
	const actualLink = link || baseLink;
	var isLinkActive =
    actualLink &&
    (location.pathname === actualLink || (!exact && location.pathname.indexOf(actualLink) !== -1));

	useEffect(() => {
		if (children) {
			itemsRef.current = itemsRef.current.slice(0, children.length);
		}
	 }, [children]);

	useEffect(() => {
		if (children?.length) {
			setIsOpen(isLinkActive)
		}
	}, [isLinkActive, location.pathname, children])

	useEffect(() => {
		if (isOpen && children?.length) {
			const index = children.findIndex(c => c.link === location.pathname)
			itemsRef.current[index]?.scrollIntoView();
		}
	}, [isOpen, location.pathname, children, itemsRef.current]) // eslint-disable-line react-hooks/exhaustive-deps
	if (type === "title")
		return (
			<Typography
				sx={{
					...styles.linkText,
					...styles.sectionTitle,
					...(isSidebarOpened ? styles.linkTextHidden : {}),
				}}
			>
				{label}
			</Typography>
		);

	if (type === "divider") return <Divider sx={styles.divider} />;

	if (!children)
		return (
			<ListItem
				ref={forwardRef}
				button
				component={link && Link}
				to={link}
				sx={{
					...styles.link,
					...styles.linkRoot,
					...(isLinkActive && !nested ? styles.linkActive : {}),
					...(nested ? styles.linkNested : {}),
				}}
			>
				<ListItemIcon
					sx={{
						...styles.linkIcon,
						...(isLinkActive ? styles.linkIconActive : {}),
					}}
				>
					{nested ? <Dot color={isLinkActive && "primary"} /> : icon}
				</ListItemIcon>
				<ListItemText
					sx={{
						...styles.linkText,
						...(isLinkActive ? styles.linkTextActive : {}),
						...(!isSidebarOpened ? styles.linkTextHidden : {}),
					}}
					primary={label}
				/>
			</ListItem>
		);

	return (
		<>
			<ListItem
				ref={forwardRef}
				button
				component={link && Link}
				onClick={toggleCollapse}
				sx={styles.link}
				to={link}
				disableRipple
			>
				<ListItemIcon
					sx={{
						...styles.linkIcon,
						...(isLinkActive ? styles.linkIconActive : {}),
					}}
				>
					{icon ? icon : <InboxIcon />}
				</ListItemIcon>
				<ListItemText
					sx={{
						...styles.linkText,
						...(isLinkActive ? styles.linkTextActive : {}),
						...(!isSidebarOpened ? styles.linkTextHidden : {}),
					}}
					primary={label}
				/>
			</ListItem>
			{children && (
				<Collapse
					in={isOpen && isSidebarOpened}
					timeout="auto"
					unmountOnExit
					sx={styles.nestedList}
				>
					<List component="div" disablePadding dense>
						{children.map((child, i) => (
							child.component ? child.component : 
								(<SidebarLink
									forwardRef={el => itemsRef.current[i] = el}
									key={child && child.link}
									location={location}
									isSidebarOpened={isSidebarOpened}
									nested
									{...child}
								/>)
						))}
					</List>
				</Collapse>
			)}
		</>
	);

	// ###########################################################

	function toggleCollapse(e) {
		if (isSidebarOpened) {
			e.preventDefault();
			setIsOpen(!isOpen);
		}
	}
}
