import _ from 'lodash';
import {ActionTypes} from './actions';
import {ActionTypes as PagesAT} from 'pages/actions';
import { filterBy, mergeBy, setLoader } from 'utils/reduxUtils';

const initialValue = {
	loaders: {},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	case 'emailExists':
		return 'משתמש עם אימייל זה כבר קיים במערכת';
	default:
		return 'אופס. הבקשה נכשלה';
	}
}
const chaptersReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.FETCH_USERS_REQUEST:
		nextState = {...state, users: [], ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_USERS_SUCCESS:
		nextState = {...state, users: payload.items, usersTotal: payload._meta.total, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_USERS_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.SUBMIT_USER_FORM_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false};
		break;
	case ActionTypes.SUBMIT_USER_FORM_SUCCESS:
		nextState = {...state, users: mergeBy(state.users, payload), ...setLoader(state, 'submitting', false), justSubmitted: true};
		break;
	case ActionTypes.SUBMIT_USER_FORM_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case PagesAT.USER_CONFIRM_MODAL_OPENED:
	case PagesAT.SINGLE_USER_FORM_OPENED:
		nextState = {...state, submitting: false, justSubmitted: false, submitError: null};
		break;
	case ActionTypes.DELETE_USER_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: false};
		break;
	case ActionTypes.DELETE_USER_SUCCESS:
		nextState = {...state, users: filterBy(state.users, payload), justSubmitted: true, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.DELETE_USER_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default chaptersReducer;
