import _ from 'lodash';
import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogActions, DialogContent, Button, Box, DialogTitle, Alert, Drawer} from '@mui/material';
import Yup from 'services/yup';

// components
import { useCloseOnSubmit } from "utils/hooks";
import {Actions} from 'pages/actions';
import * as UserSelectors from 'modules/users/selectors';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import AutoComplete from "components/Form/AutoCompleteFormik";
import { levelOptions, permissionsOptions } from 'utils/autoComplete';

function getSchema() {
	let userScheme = Yup.object().shape({
		name: Yup.string().required(),
		email: Yup.string().email().required(),
		permissions: Yup.array().of(Yup.string.apply()).min(1).required(),
		selectedLevel: Yup.string().required(),
	})
	return userScheme;
}
const initalValues = {
	name: '', email: '', permissions: ['core'], selectedLevel: 'beginner',
}

function DialogWrapper({children, open, onClose, title}) {
	return (
		<Dialog open={open} onClose={onClose} fullWidth onBackdropClick={() => false}>
			<DialogTitle sx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}>
				{title}
			</DialogTitle>
			{children}
		</Dialog>
	)
}

function DrawerWrapper({children, open, onClose, title}) {
	return (
		<Drawer
			anchor="left"
			open={open}
			onClose={onClose}
			sx={{zIndex: 1800}}
		>
			<DialogTitle sx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}>
				{title}
			</DialogTitle>
			{children}
		</Drawer>
	)
}
function CreateEditUser({open = false, onClose, user, wrapper = "dialog"}) {
	const isNew = !user?.id;
	const {submitting} = useSelector(UserSelectors.loaders)
	const submitError = useSelector(UserSelectors.submitError)
	const dispatch = useDispatch();

	useEffect(() => {
		if (open) {
			dispatch(Actions.SINGLE_USER_FORM_OPENED());
		}
	}, [open, dispatch, user, isNew])

	useCloseOnSubmit(UserSelectors.justSubmitted, open, onClose);
      
	function handleFormSubmit(values) {
		dispatch(Actions.SINGLE_USER_FORM_SUBMITTED(values));
	}

	const title = user ? `עדכון משתמש - ${user.name || ''}` : "יצירת משתמש חדש";
	const isDialog = wrapper === 'dialog';

	const ContentWrapper = isDialog ? DialogWrapper : DrawerWrapper;

	return (
		<ContentWrapper
			open={open}
			onClose={onClose}
			title={title}
		>
			<Formik
				initialValues={_.defaults(user, initalValues)}
				enableReinitialize
				validationSchema={getSchema()}
				submitting={submitting}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm }) => (
					<>
						<DialogContent dividers>
							<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, flex: 1}}>
								<PromptIfDirty />
								<Field
									component={TextField}
									name="name"
									type="text"
									label="שם מלא"
									disabled={submitting}
								/>
								<Field
									component={TextField}
									type="email"
									label="אימייל"
									name="email"
									disabled={submitting}
								/>
								<Field
									component={AutoComplete}
									options={permissionsOptions}
									label="הרשאות"
									name="permissions"
									multiple
									disabled={submitting}
									disablePortal={!isDialog}
								/>
								<Field
									component={AutoComplete}
									options={levelOptions}
									label="רמת המשתמש"
									name="selectedLevel"
									disableClearable
									disabled={submitting}
									disablePortal={!isDialog}
								/>
							</Box>
							{submitError && <Alert sx={{mt: 2}} severity="error">{submitError}</Alert>}
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose}>ביטול</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								loading={submitting}
							>
								שמירה
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</ContentWrapper>
	)
}

export default CreateEditUser;