import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogActions, DialogContent, Button, Box, DialogTitle, Alert, Typography, useTheme, Grid, ButtonBase, Slide} from '@mui/material';

// components
import {Actions} from 'pages/actions';
import * as QuestionsSelectors from 'modules/questions/selectors';
import * as ChaptersSelectors from 'modules/chapters/selectors';
import {Close as CloseIcon} from '@mui/icons-material';
import LabeledLinearProgress from 'components/LabeledLinearProgress';
import { convertToDuration } from 'utils/transformUtils';
import { styled } from '@mui/material/styles';
import { delay } from 'utils/generalUtils';
import config from 'config/config';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import {
	ArrowForward as ArrowForwardIcon,
	ArrowBack as ArrowBackIcon,
	TextSnippet as TextSnippetIcon,
	Timer as TimerIcon,
} from "@mui/icons-material";
import Loader from 'components/Loader';
import PopoverButton from 'components/PopoverButton/PopoverButton';
import AudioPlayer from 'components/AudioPlayer';

function ContentStructure({top, bottom}) {
	const theme = useTheme();
	const txtColor = theme.palette.getContrastText(theme.palette.golden.main);
	return (
		<>
			<Box sx={{p: 2, display: 'flex', flexDirection: "column", bgcolor: 'golden.main', color: txtColor}}>
				{top}
			</Box>
			<Box sx={{p: 2, display: 'flex', flexDirection: "column" }}>
				{bottom}
			</Box>
		</>
	);
}

const ShakeableButton = styled(ButtonBase, {
	shouldForwardProp: (prop) => !['hasError'].includes(prop),
})(({ hasError }) => ({
	'&': {
		animation: hasError ? `shake 0.82s cubic-bezier(.36,.07,.19,.97) both` : null,
	},
	'@keyframes shake': {
		'10%, 90%': {transform: 'translate3d(-1px, 0, 0)' },
		'20%, 80%': {transform: 'translate3d(2px, 0, 0)' },
		'30%, 50%, 70%': {transform: 'translate3d(-4px, 0, 0)' },
		'40%, 60%': {transform: 'translate3d(4px, 0, 0)' },
	},
}));

const defaultSliderProps = {timout: 1000, sliding: true, direction: 'right'}
const questionTimerTime = 10;

const possibleAnswers = [{title: 'קלעתי בול!', isCorrect: true, id: 1}, {title: 'כמעט הצלחתי...', id: 2}]
function useGoldenProgress({questions, onClose}) {
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [indexToShow, setIndexToShow] = useState(-1);
	const [score, setScore] = useState(0);
	const [timer, setTimer] = useState({});
	const [selectedAnswers, updatedSelectedAnswers] = useState({});
	const [sliderProps, setSliderProps] = useState(defaultSliderProps);
	const [shuffledQuestions, setShuffledQuestions] = useState([]);
	const [revealAnswer, setRevealAnswer] = useState(false);
	const [questionTimer, setQuestionTimer] = useState({timer: 0});
	
	const theme = useTheme();
	const sliderContainer = useRef(null);
	const sliderChild = useRef(null);

	const currentQuestion  = shuffledQuestions[currentIndex];
	const currentSelectedAnswer = selectedAnswers[currentQuestion?.id];

	const questionToShow  = shuffledQuestions[indexToShow];
	const selectedAnswerToShow = selectedAnswers[questionToShow?.id];

	const updateIndex = useCallback((newIndex) => {
		if (newIndex === 0) {
			setTimer({start: Date.now()})
		} else if (newIndex >= shuffledQuestions.length) {
			setTimer({start: timer.start, end: Date.now()})
		}
		setCurrentIndex(newIndex);
		setIndexToShow(newIndex);
		performSlide();
		setRevealAnswer(false);
		
	}, [shuffledQuestions.length, timer.start])

	useEffect(() => {
		setCurrentIndex(-1);
		setIndexToShow(-1);
		setScore(0);
		setTimer({});
		updatedSelectedAnswers({});
		setShuffledQuestions(_.shuffle(questions))
	}, [questions])

	useEffect(() => {
		let interval;
		if (revealAnswer || indexToShow < 0) {
			clearInterval(questionTimer.interval);
			setQuestionTimer({timer: questionTimerTime, interval: 0, indexToShow: -1})
			return;
		}

		if (questionTimer.indexToShow !== indexToShow) {
			clearInterval(questionTimer.interval);
			let currentTimer = questionTimerTime;
			interval = setInterval(() => {
				setQuestionTimer({timer: --currentTimer, interval, indexToShow})
				if (currentTimer < 0) {
					console.log('Clearing interval: ' + interval)
					clearInterval(interval);
					setRevealAnswer(true);
				}
			}, 1000);
		}
	}, [revealAnswer, indexToShow, questionTimer.indexToShow, questionTimer.interval])

	useEffect(() => {
		if (currentSelectedAnswer) {
			delay(config.chapters.exercise.delay.nextMillis)
				.then(() => updateIndex(currentIndex + 1));
		} else {
		}
	}, [currentSelectedAnswer, updateIndex, currentIndex])
	
	let top, bottom;

	const progress = _.clamp((currentIndex + (currentSelectedAnswer ? 1 : 0))  * 100 / shuffledQuestions.length, 0, 100)

	function performSlide(props) {
		setSliderProps({sliding: false, timeout: 0, direction: 'left'});
		delay(200).then(() => setSliderProps({...defaultSliderProps, ...props}))
	}

	function updateIndexToShow(newIndex, slideDirection) {
		setIndexToShow(newIndex);
		const nextQuestion = shuffledQuestions[newIndex];
		const answeredNextIndex = nextQuestion && selectedAnswers[nextQuestion.id];

		setRevealAnswer(answeredNextIndex)
		performSlide({direction: slideDirection});
	}

	function setAnswer(answer) {
		if (currentSelectedAnswer || currentIndex !== indexToShow) {return;}

		if (answer.isCorrect) {
			setScore(score + 1);
		}
		updatedSelectedAnswers({...selectedAnswers, [currentQuestion.id]: answer});
	}

	if (currentIndex < 0) {
		top = (
			<>
				<Typography variant='h2' whiteSpace="pre-line" align='center' sx={{mb: 2}}>
					תרגמו את המשפטים הבאים מעברית לאנגלית
				</Typography>
				<Typography variant='h2' whiteSpace="pre-line" align='center' sx={{mb: 2}}>
					(יש לומר את המשפטים בקול רם)
				</Typography>
			</>
		)
		bottom = (
			<Button size="large" sx={{alignSelf: 'center', my: 4}} variant='contained' color="golden" onClick={() => updateIndex(0)}>התחל</Button>
		)
	} else if (questionToShow) {
		const showBackward = indexToShow > 0;
		const showForward = indexToShow < currentIndex;
		top = (
			<Typography variant='h2' whiteSpace="pre-line" align='center' sx={{mb: 2}}>
				{questionToShow.title}
			</Typography>
		)
		if (revealAnswer) {
			bottom = (
				<Box sx={{overflow: 'hidden'}}>
					<Slide direction={sliderProps.direction} in={sliderProps.sliding} timeout={sliderProps.timout} exit={false}>
						<Box sx={{height: sliderChild.current?.clientHeight}} ref={sliderContainer}>
							<Grid container spacing={2} ref={sliderChild}>
								<Grid item xs={12}>
									<Typography variant="h2" sx={{textAlign: 'center', my: 2, unicodeBidi: 'plaintext'}}>{questionToShow.answer}</Typography>
								</Grid>
								{questionToShow.answerAudio && (
									<Grid item xs={12} sx={{textAlign: 'center'}}>
										<AudioPlayer
											src={questionToShow.answerAudio}
											title="השמעת התשובה"
										/>
									</Grid>
								)}
								{possibleAnswers.map(a => {
									let bgcolor = theme.palette.background.paper;
									let hasError = false;
									if (selectedAnswerToShow === a && !a.isCorrect) {
										bgcolor = theme.palette.error.main;
										hasError = true;
									} else if (selectedAnswerToShow && a.isCorrect) {
										bgcolor = theme.palette.success.main;
									}
	
									const color = theme.palette.getContrastText(bgcolor)
									return (
										<Grid key={a.id} item xs={6}>
											<ShakeableButton hasError={hasError} sx={{width: '100%', bgcolor, color}} onClick={() => setAnswer(a)}>
												<Typography sx={{width: '100%', border: t => `1px solid ${t.palette.borderColor.light}`, p: 2}}>
													{a.title}
												</Typography>
											</ShakeableButton>
										</Grid>
									)
								})}
							</Grid>
						</Box>
					</Slide>
					<Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4, mb: 2}}>
						{showBackward ? <TooltipButton title="אחורה" icon={ArrowForwardIcon} onClick={() => updateIndexToShow(indexToShow - 1, 'left')} /> : <Box />}
						{showForward ? <TooltipButton title="קדימה" icon={ArrowBackIcon} onClick={() => updateIndexToShow(indexToShow + 1, 'right')} /> : <Box />}
					</Box>
				</Box>
			)
		} else {
			bottom = (
				<Box sx={{overflow: 'hidden'}}>
					<Slide direction={sliderProps.direction} in={sliderProps.sliding} timeout={sliderProps.timout} exit={false}>
						<Box ref={sliderContainer} sx={{display: "flex", flexDirection: 'column'}}>
							<Typography variant="h3" sx={{width: '100%', textAlign: 'center', mt: 4}}>(אמרו את המשפט באנגלית בקול רם)</Typography>
							<Typography color={questionTimer.timer > 3 ? "text" : "error.main"} textAlign="center" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mt: 4}}><TimerIcon /> 00:{String(questionTimer.timer).padStart(2, '0')}</Typography>
							<Button size="large" sx={{alignSelf: 'center', my: 4}} variant='contained' color="golden" onClick={() => setRevealAnswer(true)}>הצגת התשובה</Button>
						</Box>
					</Slide>
				</Box>
			)
		}
	} else {
		const duration = convertToDuration(timer.end - timer.start)
		const calculatedScore = Math.round(score / shuffledQuestions.length * 100);
		top = (
			<>
				<Typography variant='h2' whiteSpace="pre-line" align='center' sx={{mb: 2}}>
					יפה! השלמת את התרגיל ב {duration}{"\n"}
				</Typography>
				<Typography variant='h2' whiteSpace="pre-line" align='center' sx={{mb: 2}}>
					הציון שלך הוא: {calculatedScore}
				</Typography>
			</>
		)
		bottom = (
			<Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4, mb: 2}}>
				<TooltipButton title="אחורה" icon={ArrowForwardIcon} onClick={() => updateIndexToShow(indexToShow - 1, 'left')} />
				<Button size="large" sx={{alignSelf: 'center'}} variant='contained' color="golden" onClick={onClose}>סיום</Button>
				<Box />
			</Box>
		)
	}
	return {top, bottom, progress}
}

export default function GoldenExercise({open = false, onClose}) {
	const selectedChapter = useSelector(ChaptersSelectors.selectedChapter)
	const {loading} = useSelector(QuestionsSelectors.loaders)
	const questions = useSelector(QuestionsSelectors.goldenQuestions)
	const {top, bottom, progress} = useGoldenProgress({questions, onClose, open});
	const submitError = useSelector(QuestionsSelectors.submitError)
	const dispatch = useDispatch();

	useEffect(() => {
		if (open && selectedChapter) {
			dispatch(Actions.STUDENT_GOLDEN_EXERCISE_START({id: selectedChapter.id}));
		}
	}, [open, dispatch, selectedChapter])

	if (!selectedChapter) {return null;}
	const title = `תרגול - ${selectedChapter.name || ''}`;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle sx={{bgcolor: 'golden.main', color: t => t.palette.getContrastText(t.palette.golden.main)}}>
				{title}
				<Box
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}
				>
					{selectedChapter.vocabulary && 
						<PopoverButton
							button={(
								<TooltipButton
									icon={TextSnippetIcon}
									sx={{color: t => t.palette.getContrastText(t.palette.golden.main)}}
									title="אוצר מילים"
								/>
							)}
							popoverContent={<Typography whiteSpace="pre-line" sx={{p: 2, maxHeight: 300}}>{selectedChapter.vocabulary}</Typography>}
						/>}
					<TooltipButton
						icon={CloseIcon}
						title="סגירה"
						aria-label="close"
						sx={{color: t => t.palette.getContrastText(t.palette.golden.main)}}
						onClick={onClose}
					/>
				</Box>
			</DialogTitle>
			<DialogContent sx={{p: 0}}>
				<Loader loading={loading}>
					<ContentStructure top={top} bottom={bottom} />

					{submitError && <Alert sx={{m: 2}} severity="error">{submitError}</Alert>}
				</Loader>
			</DialogContent>
			<DialogActions sx={{height: 50, p: 0}}>
				<LabeledLinearProgress progress={Math.round(progress)} />
			</DialogActions>
		</Dialog>
	)
}
