import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Tooltip, IconButton, Paper, Box, Typography, TableContainer, Table, TableCell, TableRow, TableHead, TableBody, Button} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	WorkspacePremium as WorkspacePremiumIcon,
	MenuBook as MenuBookIcon,
	Reorder as ReorderIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle";
import Loader from "components/Loader/Loader";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import {Actions} from 'pages/actions';
import * as ChaptersSelectors from 'modules/chapters/selectors';
import * as LevelsSelector from 'modules/levels/selectors';
import { Link } from "react-router-dom";
import DraggableList from 'components/DraggableList/DraggableList';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import ProgressButton from 'components/ProgressButton/ProgressButton';


function ActionsColumn({row, onDeleteClick}) {
	return (
		<>
			<Tooltip arrow title="עריכת פרק">
				<IconButton
					LinkComponent={Link}
					to={`chapters/${row.id}`}
					aria-label="עריכת פרק"
					sx={{p: 1}}
					size="large">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Tooltip arrow title="מחיקת פרק">
				<IconButton
					aria-label="מחיקת פרק"
					sx={{p: 1}}
					onClick={() => onDeleteClick(row)}
					color="error"
					size="large">
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const headCells = [
	{id: 'name', numeric: false, label: 'שם הפרק'},
	{id: 'goldenQuestionsCount', numeric: true, disablePadding: false, label: '# שאלות זהב', width: 150 },
	{id: 'mcQuestionsCount', numeric: true, disablePadding: false, label: '# שאלות אמריקאיות', width: 150 },
	{id: 'actions', numeric: false, disablePadding: false, label: 'פעולות', width: 120 },
];

function SingleLevelPage() {
	const {loading, submitting} = useSelector(ChaptersSelectors.loaders)
	const selectedLevel = useSelector(LevelsSelector.matchingLevel)
	const chapters = useSelector(ChaptersSelectors.chapters)
	const chapterSubmitError = useSelector(ChaptersSelectors.submitError)
	const chapterJustSubmitted = useSelector(ChaptersSelectors.justSubmitted)
	const [confirmation, setConfirmation] = useState({open: false});
	const [dragEnabled, setDragEnabled] = useState(false);
	const [orderedChapters, setOrderedChapters] = useState(chapters);
	
	const dispatch = useDispatch();

	useEffect(() => {
		if (chapterJustSubmitted) {
			setConfirmation({open: false});
		}
	}, [setConfirmation, chapterJustSubmitted])

	useEffect(() => {
		setOrderedChapters(chapters)
		setDragEnabled(false)
	}, [chapters, setOrderedChapters])
	
	function cancelDrag() {
		setDragEnabled(false);
		setOrderedChapters(chapters)
	}

	function handleOrderUpdate() {
		const newOrder = orderedChapters.map(c => c.id);
		dispatch(Actions.LEVEL_CHAPTERS_ORDER_UPDATED(newOrder));
	}

	function handleDeleteConfirm(chapter) {
		dispatch(Actions.SINGLE_CHAPTER_DELETE_SUBMITTED(chapter));
	}

	function openConfirmDelete(chapter) {
		dispatch(Actions.SINGLE_CHAPTER_CONFIRM_MODAL_OPENED(chapter));
		setConfirmation({
			open: true,
			title: `מחיקה - ${chapter.name}`,
			subtitle: 'האם למחוק פרק זה?\nפרק זה ימחק, כולל כל השאלות שלו. פעולה זו אינה הפיכה.',
			confirmText: 'מחיקה',
			variant: 'error',
			onConfirm: () => handleDeleteConfirm(chapter),
			onCancel: () => setConfirmation({open: false}),
		});
	}

	const title = `שלב ${selectedLevel?.label}`;
	
	return (
		<>
			<PageTitle title={title} icon={WorkspacePremiumIcon} />
			<Paper sx={{p: 2, maxWidth: 750}}>
				<Loader inline loading={loading || submitting}>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography variant='h2' gutterBottom sx={{color: 'text.hint'}}><MenuBookIcon fontSize='medium' /> פרקים</Typography>
						<Box sx={{display: 'flex'}}>
							<TooltipButton
								title="ארגון פרקים"
								icon={ReorderIcon}
								size="large"
								onClick={() => dragEnabled ? cancelDrag() : setDragEnabled(true)}
								sx={{p: 1}}
							/>
							<Link to="chapters/new">
								<TooltipButton
									title="הוספת פרק חדש"
									icon={AddIcon}
									iconProps={{color: 'primary'}}
									size="large"
									sx={{p: 1}}
								/>
							</Link>
						</Box>
					</Box>
					<TableContainer sx={{maxHeight: 600, minHeight: 200}}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow hover>
									<TableCell padding="none" width={35}></TableCell>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											width={headCell.width}
											align="left"
											padding={headCell.disablePadding ? 'none' : 'normal'}
										>
											{headCell.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<DraggableList
								listComponent={TableBody}
								listSX={{display: 'table-row-group'}}
								itemSX={{display: 'table-row'}}
								listItemComponent={TableRow}
								listItemProps={{hover: true}}
								items={orderedChapters}
								onChange={setOrderedChapters}
								dragEnabled={dragEnabled}
								renderItemContent={(chapter, drag) => (
									<>
										<TableCell padding="none">
											{drag}
										</TableCell>
										<TableCell>
											{chapter.name}
										</TableCell>
										<TableCell align="left">{chapter.goldenQuestionsCount}</TableCell>
										<TableCell align="left">{chapter.mcQuestionsCount}</TableCell>
										<TableCell align="right">
											<ActionsColumn
												row={chapter}
												onDeleteClick={openConfirmDelete}
												// onEditClick={(row) => setQuestionModal({question: row, open: true})}
											/>
										</TableCell>
									</>
								)}
							/>
						</Table>
					</TableContainer>
					{dragEnabled && <Box sx={{mt: 2, display: 'flex', gap: 2, justifyContent: 'flex-end'}}>
						<Button onClick={cancelDrag}>ביטול</Button>
						<ProgressButton variant="contained" color="primary" onClick={handleOrderUpdate}>שמירת שינויים</ProgressButton>
					</Box>}
				</Loader>
				<ConfirmDialog
					{...confirmation}
					loading={submitting}
					alert={chapterSubmitError}
				/>
			</Paper>
		</>
	)
}

export default SingleLevelPage;