import React, { useState } from "react";
import {
	AppBar as MuiAppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	Box,
	Typography,
	Autocomplete,
	TextField,
} from '@mui/material';
import {
	Menu as MenuIcon,
	Person as AccountIcon,
	ArrowForward as ArrowForwardIcon,
	ArrowBack as ArrowBackIcon,
	ExitToApp as ExitToAppIcon,
	Settings as SettingsIcon,
} from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import * as ChapterSelectors from 'modules/chapters/selectors';
import * as LevelSelectors from 'modules/levels/selectors';
import * as AuthSelectors from 'modules/auth/selectors';
import {Actions} from 'pages/actions';

// styles
import styles from "./styles";

// context
import {
	useLayoutState,
	useLayoutDispatch,
	toggleSidebar,
} from "../../context/LayoutContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";


const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => !['open', 'kind'].includes(prop),
})(({ theme, open, kind }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: theme.sidebar[kind].width,
		width: `calc(100% - ${theme.sidebar[kind].width}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default function StudentHeader() {
	const dispatch = useDispatch();
	const user = useSelector(AuthSelectors.user)
	const layoutState = useLayoutState();
	const layoutDispatch = useLayoutDispatch();
	const levelAlias = useSelector(LevelSelectors.matchingLevelAlias);
	const chapters = useSelector(ChapterSelectors.chapters);
	const [profileMenu, setProfileMenu] = useState(null);
	const navigation = useNavigate();

	function handleChapterSelected(_event, chapter) {
		navigation(`/app/admin/level/${levelAlias}/chapters/${chapter.id}`);
	}

	function handleLogoutClick() {
		dispatch(Actions.HEADER_LOGOUT_REQUESTED());
	}
	return (
		<AppBar position="fixed">
			<Toolbar sx={styles.toolbar}>
				<IconButton
					color="inherit"
					onClick={() => toggleSidebar(layoutDispatch)}
					sx={{
						...styles.headerMenuButton,
						...styles.headerMenuButtonCollapse,
					}}
					size="large">
					{layoutState.isSidebarOpened ? (
						<ArrowForwardIcon
							sx={{
								...styles.headerIcon,
								...styles.headerIconCollapse,
							}}
						/>
					) : (
						<MenuIcon
							sx={{
								...styles.headerIcon,
								...styles.headerIconCollapse,
							}}
						/>
					)}
				</IconButton>
				<Typography variant="h6" weight="medium" xs={styles.logotype}>
					Confidence
				</Typography>
				<Box sx={styles.grow}></Box>
				<Typography variant="h6" weight="medium" sx={{display: 'inline-flex', alignItems: 'center', gap: 1}}>
					לשינוי רמה
					<ArrowBackIcon />
				</Typography>
				<IconButton
					aria-haspopup="true"
					color="inherit"
					aria-controls="profile-menu"
					onClick={e => setProfileMenu(e.currentTarget)}
					size="large">
					<AccountIcon sx={styles.headerIcon} />
				</IconButton>
				<Menu
					id="profile-menu"
					open={Boolean(profileMenu)}
					anchorEl={profileMenu}
					onClose={() => setProfileMenu(null)}
					sx={styles.headerMenu}
					// classes={{ paper: classes.profileMenu }}
					disableAutoFocusItem
				>
					<Box sx={styles.profileMenuUser}>
						<Typography variant="h4" weight="medium">
							{user.name}
						</Typography>
					</Box>

					<MenuItem
						component={Link}
						to="/app/user/settings"
						sx={{
							...styles.headerMenuItem,
						}}
					>
						<SettingsIcon sx={styles.profileMenuIcon} /> הגדרות
					</MenuItem>
					<MenuItem
						onClick={handleLogoutClick}
						sx={{
							...styles.headerMenuItem,
							...styles.logoutMenuItem,
						}}
					>
						<ExitToAppIcon sx={styles.profileMenuIcon} /> התנתקות
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
}
