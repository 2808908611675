import React from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import store, {history} from './redux/store'
import './index.css';
import App from 'components/App';
import reportWebVitals from './reportWebVitals';
import { LayoutProvider } from "./context/LayoutContext";
import Themes from "./themes";
import { HistoryRouter } from "redux-first-history/rr6";
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'modules/notify/snackBarUtils';
import { HelmetProvider } from 'react-helmet-async';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { Zoom } from '@mui/material';
import { AudioPlayerProvider } from 'react-use-audio-player';

// Create rtl cache
const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});

ReactDOM.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<LayoutProvider>
				<HelmetProvider>
					<CacheProvider value={cacheRtl}>
						<ThemeProvider theme={Themes.default}>
							<CssBaseline />
							<SnackbarProvider maxSnack={3} TransitionComponent={Zoom}>
								<SnackbarUtilsConfigurator />
								<HistoryRouter history={history}>
									<App />
								</HistoryRouter>
							</SnackbarProvider>
						</ThemeProvider>
					</CacheProvider>
				</HelmetProvider>
			</LayoutProvider>
		</ReduxProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
