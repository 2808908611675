import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Box, Paper, Alert, TextField as MuiTextField} from '@mui/material';
import {
	Settings as SettingsIcon,
} from '@mui/icons-material';
import * as Yup from 'yup';

// components
import PageTitle from "../../components/PageTitle";
import Loader from "components/Loader/Loader";
import {Actions} from 'pages/actions';
import * as AuthSelectors from 'modules/auth/selectors';
import {useParams} from 'react-router-dom';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import AutoComplete from 'components/Form/AutoCompleteFormik';
import {levelOptions} from 'utils/autoComplete';

const settingsScheme = Yup.object().shape({
	name: Yup.string().required(),
	selectedLevel: Yup.string().required(),
})
const settingsInitalValues = {name: '', selectedLevel: levelOptions[0].id}

function SettingsForm({onSubmit}) {
	const {loading, submitting} = useSelector(AuthSelectors.loaders);
	const user = useSelector(AuthSelectors.user)
	const submitError = useSelector(AuthSelectors.submitError)
	const isDisabled = loading || submitting;

	return (
		<Formik
			initialValues={_.defaults(user, settingsInitalValues)}
			enableReinitialize
			validationSchema={settingsScheme}
			submitting={loading || submitting}
			onSubmit={(values, { setSubmitting }) => {
				onSubmit(values);
				setSubmitting(false);
			}}
		>
			{({ submitForm }) => (
				<Paper sx={{p: 2, maxWidth: 600}}>
					<Loader inline loading={loading || submitting}>
						<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
							<PromptIfDirty />
							<Field
								component={TextField}
								name="name"
								type="text"
								label="שם מלא"
								disabled={isDisabled}
							/>
							<Field
								component={TextField}
								name="email"
								type="text"
								label="אימייל"
								helperText="לשינוי אימייל יש לשלוח אימייל למרצה"
								disabled
							/>
							<Field
								component={AutoComplete}
								options={levelOptions}
								label="רמת קושי"
								name="selectedLevel"
								disableClearable
								disabled={loading}
							/>
							{submitError && <Alert severity="error">{submitError}</Alert>}
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								loading={loading}
							>
								שמירה
							</ProgressButton>
						</Box>
					</Loader>
				</Paper>
			)}
		</Formik>
	)
}

export default function UserSettingsPage() {
	const dispatch = useDispatch();

	function handleFormSubmit(values) {
		dispatch(Actions.USER_SETTINGS_SUBMITTED(values));
	}

	const title = "הגדרות אישיות";

	return (
		<>
			<PageTitle title={title} icon={SettingsIcon} />
			<SettingsForm defaultExpanded={true} onSubmit={handleFormSubmit} />
		</>
	)
}
