import _ from 'lodash';
import {Typography, Box, Link} from '@mui/material';
import pdfIcon from 'images/pdf-icon.png';


export default function FileLink({link, name, sx}) {
	return (
		<Box
			component={Link}
			rel="noreferrer noopener"
			target="_blank"
			href={link}
			underline="hover"
			color="inherit"
			sx={{display: 'flex', gap: 2, alignItems: 'center', width: '100%', maxWidth: '400px', ...sx}}
		>
			<Box
				component="img"
				src={pdfIcon}
				sx={{height: '50px'}}
			/>
			<Typography sx={{flex: 1, direction: 'rtl', textAlign: 'left'}} variant="h4">{name}</Typography>
		</Box>
	)
}