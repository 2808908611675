import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";

export default function GridToolBar({searchValue, onSearchChange, clearSearch, actions = [], noSearch}) {
	return (
		<Box
			sx={{
				p: 0.5,
				pb: 0,
				justifyContent: 'space-between',
				display: 'flex',
				alignItems: 'flex-start',
				flexWrap: 'wrap',
			}}
		>
			<Box>
				{!noSearch && <TextField
					variant="standard"
					value={searchValue}
					onChange={onSearchChange}
					autoFocus
					placeholder="חיפוש.."
					InputProps={{
						startAdornment: <SearchIcon fontSize="small" />,
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: searchValue ? 'visible' : 'hidden' }}
								onClick={clearSearch}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						),
					}}
					sx={{
						width: {xs: 1, sm: 'auto' },
						m: (theme) => theme.spacing(1, 0.5, 1.5),
						'& .MuiSvgIcon-root': {
							mr: 0.5,
						},
						'& .MuiInput-underline:before': {
							borderBottom: 1,
							borderColor: 'divider',
						},
					}}
				/>}
			</Box>
			<Box sx={{mr: 2}}>
				{actions.map((action, index) => {
					let btn = (
						<IconButton
							key={index}
							aria-label={action.hint}
							size="medium"
							color={action.primary ? 'primary' : 'default'}
							onClick={action.onClick}
						>
							<action.icon fontSize="large" />
						</IconButton>
					)
					if (action.link) {
						btn = (
							<Link to={action.link}>
								{btn}
							</Link>
						)
					}
					return (
						<Tooltip key={index} title={action.hint}>
							{btn}
						</Tooltip>
					)
				})}
			</Box>
		</Box>
	);
}