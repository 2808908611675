import {Chip, Box} from '@mui/material';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import PropTypes from 'prop-types';

const styles = {
	root: {},
	imageContainer: {
		position: 'relative',
		zIndex: 10,
		textAlign: 'center',
		'&:hover $image': {
			opacity: 0.3,
		},
		'&:hover $removeButton': {
			opacity: 1,
		},
	},
	image: {
		height: 100,
		width: 'initial',
		maxWidth: '100%',
		color: t => t.palette.text.primary,
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		boxSizing: 'border-box',
		boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
		borderRadius: t => t.shape.borderRadius,
		zIndex: 5,
		opacity: 1,
	},
	removeButton: {
		transition: '.5s ease',
		position: 'absolute',
		opacity: 0,
		top: t => t.spacing?.(-1),
		right: t => t.spacing?.(-1),
		width: 40,
		height: 40,
		'&:focus': {
			opacity: 1,
		},
	},
};

function PreviewList({
	fileObjects,
	handleRemove,
	showFileNames,
	useChipsForPreview,
	previewChipProps,
	previewGridClasses,
	previewGridProps,
	getPreviewIcon,
	renderFilePreview,
}) {
	if (useChipsForPreview) {
		return (
			<Box
				{...previewGridProps.container}
				className={previewGridClasses.container}
				sx={{display: 'flex', px: 2, alignItems: 'center', justifyContent: 'center'}}
			>
				{fileObjects.map((fileObject, i) => {
					if (renderFilePreview) {
						return renderFilePreview(fileObject, {handleRemove: () => handleRemove(i)})
					}
					return (
						<Chip
							{...previewChipProps}
							{...previewGridProps.item}
							key={`${fileObject.file?.name ?? 'file'}-${i}`}
							variant="outlined"
							label={fileObject.file.name}
							onDelete={handleRemove(i)}
						/>
					);
				})}
			</Box>
		);
	}

	return (
		<Grid
			spacing={8}
			{...previewGridProps.container}
			container={true}
			sx={styles.root}
			className={previewGridClasses.container}
		>
			{fileObjects.map((fileObject, i) => {
				return (
					<Grid
						xs={4}
						{...previewGridProps.item}
						item={true}
						key={`${fileObject.file?.name ?? 'file'}-${i}`}
						sx={styles.imageContainer}
						className={previewGridClasses.item}
					>
						{getPreviewIcon(fileObject, styles)}

						{showFileNames && (
							<Typography variant="body1" component="p">
								{fileObject.file.name}
							</Typography>
						)}

						<Fab
							onClick={handleRemove(i)}
							aria-label="Delete"
							sx={styles.removeButton}
						>
							<DeleteIcon />
						</Fab>
					</Grid>
				);
			})}
		</Grid>
	);
}

PreviewList.propTypes = {
	fileObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
	getPreviewIcon: PropTypes.func.isRequired,
	handleRemove: PropTypes.func.isRequired,
	previewChipProps: PropTypes.object,
	previewGridClasses: PropTypes.object,
	previewGridProps: PropTypes.object,
	showFileNames: PropTypes.bool,
	useChipsForPreview: PropTypes.bool,
};

export default PreviewList;