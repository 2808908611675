import tinycolor from "tinycolor2";

const primary = "#a2432c";
const secondary = "#FFB30F";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const golden = "#FFD700";

const lightenRate = 7.5;
const alphaRate = 0.3;
const darkenRate = 15;

const theme = {
	palette: {
		primary: {
			main: primary,
			lightest: tinycolor(primary)
				.lighten(lightenRate)
				.setAlpha(alphaRate)
				.toHex8String(),
			light: tinycolor(primary)
				.lighten(lightenRate)
				.toHex8String(),
			dark: tinycolor(primary)
				.darken(darkenRate)
				.toHex8String(),
		},
		secondary: {
			main: secondary,
			lightest: tinycolor(secondary)
				.lighten(lightenRate)
				.setAlpha(alphaRate)
				.toHex8String(),
			light: tinycolor(secondary)
				.lighten(lightenRate)
				.toHexString(),
			dark: tinycolor(secondary)
				.darken(darkenRate)
				.toHexString(),
			contrastText: "#FFFFFF",
		},
		warning: {
			main: warning,
			lightest: tinycolor(warning)
				.lighten(lightenRate)
				.setAlpha(alphaRate)
				.toHex8String(),
			light: tinycolor(warning)
				.lighten(lightenRate)
				.toHexString(),
			dark: tinycolor(warning)
				.darken(darkenRate)
				.toHexString(),
		},
		success: {
			main: success,
			lightest: tinycolor(success)
				.lighten(lightenRate)
				.setAlpha(alphaRate)
				.toHex8String(),
			light: tinycolor(success)
				.lighten(lightenRate)
				.toHexString(),
			dark: tinycolor(success)
				.darken(darkenRate)
				.toHexString(),
		},
		info: {
			main: info,
			lightest: tinycolor(info)
				.lighten(lightenRate)
				.setAlpha(alphaRate)
				.toHex8String(),
			light: tinycolor(info)
				.lighten(lightenRate)
				.toHexString(),
			dark: tinycolor(info)
				.darken(darkenRate)
				.toHexString(),
		},
		golden: {
			main: golden,
			lightest: tinycolor(golden)
				.lighten(lightenRate)
				.setAlpha(alphaRate)
				.toHex8String(),
			light: tinycolor(golden)
				.lighten(lightenRate)
				.toHexString(),
			dark: tinycolor(golden)
				.darken(darkenRate)
				.toHexString(),
		},
		text: {
			primary: "#4A4A4A",
			secondary: "#6E6E6E",
			hint: "#B9B9B9",
		},
		background: {
			default: "#F6F7FF",
			light: "#F3F5FF",
		},
		borderColor: {
			light: 'rgba(0, 0, 0, 0.23)',
			dark: 'rgba(255, 255, 255, 0.23)',
		},
	},
};

export default theme;
