import { put, takeEvery, select } from 'redux-saga/effects';
import { ActionTypes as AuthActionTypes } from 'modules/auth/actions';
import { ActionTypes as ChapterActionTypes } from 'modules/chapters/actions';
import * as NavigationSelectors from './selectors';
import {push} from 'redux-first-history';
// import _ from 'lodash';


function* handleNavigation(action) {
	try {
		switch (action.type) {
		case AuthActionTypes.LOGOUT_SUCCESS: {
			const pathname = yield select(NavigationSelectors.pathnameSelector);
			if (!pathname.startsWith('/app/login')) {
				let query = '';
				const redirectReasons = ['expired', 'timeout'];
				if (redirectReasons.includes(action.payload.reason)) {
					query = `?return=${encodeURIComponent(pathname)}`;
				}
				yield put(push(`/app/login${query}`));
			}
			break;
		}
		case ChapterActionTypes.SUBMIT_CHAPTER_FORM_SUCCESS:
			if (action.meta.isNew) {
				yield put(push(`./${action.payload.id}`));
			}
			break;
		default:
			break;
		}
	} catch (err) {
		console.error(err);
	}
}

const sagas = [
	takeEvery('*', handleNavigation),
]
export default sagas;
