import _ from 'lodash';
import {ActionTypes} from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';
import { filterBy, mergeBy, setLoader } from 'utils/reduxUtils';

const initialValue = {
	loaders: {},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	default:
		return 'אופס. הבקשה נכשלה';
	}
}
const chaptersReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.FETCH_LEVEL_CHAPTERS_REQUEST:
		nextState = {...state, chapters: [], ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_LEVEL_CHAPTERS_SUCCESS:
		nextState = {...state, chapters: payload.items, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_LEVEL_CHAPTERS_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.UPDATE_CHAPTERS_ORDER_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true)};
		break;
	case ActionTypes.UPDATE_CHAPTERS_ORDER_SUCCESS:
		nextState = {...state, chapters: payload.items, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.UPDATE_CHAPTERS_ORDER_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.FETCH_SINGLE_CHAPTER_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_SINGLE_CHAPTER_REQUEST:
		nextState = {...state, selectedChapter: null, ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_SINGLE_CHAPTER_SUCCESS:
		nextState = {...state, selectedChapter: payload, ...setLoader(state, 'loading', false)};
		break;
	case PagesActionTypes.SINGLE_CHAPTER_EDIT_MOUNTED:
		nextState = {...state, selectedChapter: null};
		break;
	case ActionTypes.SUBMIT_CHAPTER_FORM_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: false};
		break;
	case ActionTypes.SUBMIT_CHAPTER_FORM_SUCCESS:
		nextState = {...state, chapters: mergeBy(state.chapters, payload), selectedChapter: payload, ...setLoader(state, 'submitting', false), justSubmitted: true};
		break;
	case ActionTypes.SUBMIT_CHAPTER_FORM_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case ActionTypes.DELETE_SINGLE_CHAPTER_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: false};
		break;
	case ActionTypes.DELETE_SINGLE_CHAPTER_SUCCESS:
		nextState = {...state, chapters: filterBy(state.chapters, payload), justSubmitted: true, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.DELETE_SINGLE_CHAPTER_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default chaptersReducer;
