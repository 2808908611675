import { put, takeLatest, call } from 'redux-saga/effects';
import { Actions as LevelActions } from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';
import axios from 'services/axios';

// import _ from 'lodash';


function* fetchUnitPage(action) {
	yield put(LevelActions.FETCH_UNIT_PAGE_REQUEST());
	try {
		const {unit, page, level} = action.payload;
		const levelPath = level ? `/${level}` : '';
		const url = `pages/${unit}${levelPath}/${page}`;
		const {data} = yield call(axios.get, url);
		yield put(LevelActions.FETCH_UNIT_PAGE_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(LevelActions.FETCH_UNIT_PAGE_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActionTypes.UNIT_PAGE_MOUNTED, fetchUnitPage),
]

export default sagas;
