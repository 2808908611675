import React, {useRef, useState} from 'react';
import {Box, Alert, Typography, List, ListItem} from '@mui/material';
import {Field, useFormikContext } from 'formik';
import _ from 'lodash';
import config from 'config';
import SingleFileDropzoneArea from 'components/DropzoneArea/SingleFileDropzoneArea';
import {
	CloudUpload as CloudUploadIcon,
	OpenInNew as OpenInNewIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';
import TooltipButton from 'components/TooltipButton/TooltipButton';

export default function DropzoneAreaField({name, label, acceptedFiles, maxFileSize, ...props}) {
	const context = useFormikContext();
	const error = (context.touched[name] && context.errors[name]);

	return (
		<Field
			name={name}
			id={name}
			fullWidth
			{...props}
		>
			{({ field }) => (
				<SingleFileDropzoneArea
					dropzoneText={label}
					acceptedFiles={acceptedFiles}
					maxFileSize={maxFileSize}
					filesLimit={1}
					useChipsForPreview
					value={field.value}
					onChange={(value) => field.onChange({ target: { name, value} })}
					onClearFiles={() => field.onChange({ target: { name, value: null} })}
					showAlerts={false}
					dropzoneProps={{disabled: props.isDisabled}}
					showPreviewsInDropzone={false}
					renderContent={({value, handleRemove}) => (
						<Box sx={{p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2}}>
							{value.length ? (
								<Box sx={{display: 'flex', alignItems: 'center'}}>
									<CheckCircleOutlineIcon color="success" sx={{mr: 0.5}} />
									{props.loadedLabel}
									{
										<TooltipButton
											title="פתיחה בטאב חדש"
											icon={OpenInNewIcon}
											iconProps={{fontSize: 'small'}}
											component="a"
											target="_blank"
											href={value[0]}
											onClick={e => e.stopPropagation()}
										/>
									}
								</Box>
							) : (
								<>
									<Typography
									>
										{label}
									</Typography>
									<CloudUploadIcon fontSize='large' />
								</>
							)}
						</Box>
					)}
				/>
			)}
		</Field>
	);
}

//