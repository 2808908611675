const styles = {
	container: {
		height: "100vh",
		width: "100vw",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: 0,
		left: 0,
	},
	logotypeContainer: {
		height: "100%",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: {
			xs: '100%',
			lg: '60%',
		},
		display: {
			xs: 'none',
			lg: 'flex',
		},
	},
	logotypeImage: {
		width: '95%',
		maxWidth: 220,
		margin: 'auto',
		marginBottom: theme => theme.spacing(4),
	},
	logotypeText: {
		fontWeight: 500,
		fontSize: {
			xs: 48,
			lg: 84,
		},
	},
	formContainer: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// backgroundColor: theme => theme.palette.primary.main,
		width: {
			xs: '100%',
			lg: '40%',
		},
	},
	form: {
		maxWidth: 420,
		background: theme => theme.palette.background.light,
		border: theme => `1px solid ${theme.palette.primary.main}`,
		width: {
			xs: '95%',
			lg: '100%',
		},
		mt: 2,
	},
	tab: {
		fontWeight: 400,
		fontSize: 18,
	},
	greeting: {
		fontWeight: 500,
		fontSize: 36,
		textAlign: "center",
		marginTop: theme => theme.spacing(4),
	},
	subGreeting: {
		fontWeight: 500,
		textAlign: "center",
		marginTop: theme => theme.spacing(2),
	},
	googleButton: {
		marginTop: theme => theme.spacing(6),
		backgroundColor: "white",
		width: "100%",
		textTransform: "none",
	},
	googleButtonCreating: {
		marginTop: 0,
	},
	googleIcon: {
		width: 30,
		marginRight: theme => theme.spacing(2),
	},
	creatingButtonContainer: {
		marginTop: theme => theme.spacing(2.5),
		height: 46,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	createAccountButton: {
		height: 46,
		textTransform: "none",
	},
	formDividerContainer: {
		marginTop: theme => theme.spacing(4),
		marginBottom: theme => theme.spacing(4),
		display: "flex",
		alignItems: "center",
	},
	formDividerWord: {
		paddingInlineStart: theme => theme.spacing(2),
		paddingInlineEnd: theme => theme.spacing(2),
	},
	formDivider: {
		flexGrow: 1,
		height: 1,
		backgroundColor: theme => theme.palette.text.hint + "40",
	},
	errorMessage: {
		textAlign: "center",
	},
	formButtons: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	loginLoader: {
		marginLeft: theme => theme.spacing(4),
	},
	copyright: {
		marginTop: theme => theme.spacing(4),
		whiteSpace: "nowrap",
		position: {
			md: 'absolute',
		},
		bottom: {
			md: theme => theme.spacing(2),
		},
	},
}

export default styles;
