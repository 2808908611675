import React from "react";
import { useAudioPlayer } from "react-use-audio-player";
import TooltipButton from 'components/TooltipButton/TooltipButton';
import {
	Campaign as CampaignIcon,
	StopCircleOutlined as StopCircleOutlinedIcon,
} from "@mui/icons-material";
import { AudioPlayerProvider } from 'react-use-audio-player';

function AudioPlayer({src, title}) {
	console.log(src, title)
	const { togglePlayPause, playing } = useAudioPlayer({
		src,
		format: "mp3",
		autoplay: false,
	})

	return (
		
		<TooltipButton
			sx={{mb: 2,
				position: 'relative',
				'&:before': {
					// content: '""',
					// position: 'absolute',
					// width: '100%',
					// height: '100%',
					// backgroundColor: t => t.palette.grey[400],
					// borderRadius: '50%',
					// zIndex: 0,
					// animation: 'ripple 1.5s ease-out infinite',
					// '@keyframes ripple': {
					// 	from: {
					// 	  opacity: 1,
					// 	  transform: 'scale(0)',
					// 	},
					// 	to: {
					// 	  opacity: 0,
					// 	  transform: 'scale(1)',
					// 	},
					// },
				}}}
			title={playing ? 'עצירה' : title}
			icon={playing ? StopCircleOutlinedIcon : CampaignIcon}
			iconProps={{sx: {fontSize: 40, color: 'primary.main', zIndex: 1}}}
			onClick={togglePlayPause}
		/>
	);
}


export default function AudioPlayerHOC(props) {
	console.log(props)
	return (
		<AudioPlayerProvider>
			<AudioPlayer {...props} />
		</AudioPlayerProvider>
	)
}