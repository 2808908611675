import { IconButton, ListItem, ListItemIcon } from '@mui/material';
import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export const DraggableItem = memo(function DraggableItem({ id, moveCard, item, findCard, renderItemContent, renderSecondaryAction, itemComponent, itemProps, sx, dragEnabled}) {
	const originalIndex = findCard(id).index;
	const [{ isDragging }, drag, preview] = useDrag(() => ({
		type: 'card',
		item: { id, originalIndex },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: (item, monitor) => {
			const { id: droppedId, originalIndex } = item;
			const didDrop = monitor.didDrop();
			if (!didDrop) {
				moveCard(droppedId, originalIndex);
			}
		},
	}), [id, originalIndex, moveCard]);
	const [, drop] = useDrop(() => ({
		accept: 'card',
		hover({ id: draggedId }) {
			if (draggedId !== id) {
				const { index: overIndex } = findCard(id);
				moveCard(draggedId, overIndex);
			}
		},
	}), [findCard, moveCard]);
	const opacity = isDragging ? 0.5 : 1;
	return (
		<ListItem ref={preview}
			component={itemComponent}
			sx={{
				opacity,
				...sx,
			}}
			{...itemProps}
			secondaryAction={renderSecondaryAction && renderSecondaryAction(item)}
		>
			{renderItemContent(item, dragEnabled && (
				<ListItemIcon sx={{minWidth: 'auto'}}>
					<IconButton
						ref={(node) => drag(drop(node))}
						sx={{cursor: 'move'}}
						title="Drag"
						aria-label="Drag"
						size="small"
					>
						<DragIndicatorIcon />
					</IconButton>
				</ListItemIcon>
			))}
		</ListItem>
	);
});
