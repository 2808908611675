import { takeEvery } from 'redux-saga/effects';
import notify from './snackBarUtils';
// import _ from 'lodash';

// eslint-disable-next-line require-yield
function* handleNotifications(action) {
	try {
		switch (action.type) {
		default:
			const successRegex = [/SUBMIT_.*_FORM_SUCCESS/, /UPLOAD_.*_SUCCESS/, /GENERATE_.*_SUCCESS/]
			const failureRegex = [/SUBMIT_.*_FORM_FAILURE/, /UPLOAD_.*_FAILURE/, /GENERATE_.*_FAILURE/]
			if (successRegex.some(sr => sr.test(action.type))) {
				notify.success('הפעולה בוצעה בהצלחה');
			} else if (failureRegex.some(sr => sr.test(action.type))) {
				notify.error('הפעולה נכשלה');
			}
			break;
		}
	} catch (err) {
		console.error(err);
	}
}

const sagas = [
	takeEvery('*', handleNotifications),
]
export default sagas;
