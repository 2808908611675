import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Paper, Box, Button, Typography, Alert} from '@mui/material';
import {
	DirectionsWalk as DirectionsWalkIcon,
	KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
	KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "components/Loader/Loader";
import * as UnitSelectors from 'modules/units/selectors';
import {useParams, useLocation, Link} from 'react-router-dom';
import config from 'config/config';
import {Actions} from 'pages/actions';
import StructuredContent from 'components/StructuredContent/StructuredContent';

export default function UnitPage() {
	const dispatch = useDispatch();
	const {loading} = useSelector(UnitSelectors.loaders)
	const selectedPage = useSelector(UnitSelectors.selectedPage)
	const submitError = useSelector(UnitSelectors.submitError)
	const [unitIndex, setModuleIndex] = useState(-100);
	const location = useLocation();
	const {unit, page, level} = useParams();
	const unitConfig = level ? config.units[unit].levels[level] : config.units[unit];
	
	useEffect(() => {
		const currentIndex = unitConfig.pages.findIndex(p => p.link === location.pathname)
		if (currentIndex < 0) {
			console.warn(`Invalid page found: ${location.pathname}`);
			setModuleIndex(-100);
		} else {
			setModuleIndex(currentIndex);
		}

		dispatch(Actions.UNIT_PAGE_MOUNTED({unit, page, level}));
		
	}, [location.pathname, unit, level, page, dispatch, unitConfig]);

	const pageLabel = unitConfig.pages[unitIndex]?.label;
	const title = unitConfig.title;
	
	return (
		<>
			<PageTitle title={title} navigationTitle={`${pageLabel} - ${title}`} icon={DirectionsWalkIcon} />
			<Paper sx={{p: 2}}>
				<Loader inline loading={loading} sx={{display: 'flex', flexDirection: 'column'}}>
					<Box sx={{flex: 1, px: [2, 0]}}>
						<Typography variant="h1" sx={{textAlign: 'center'}}>{pageLabel}</Typography>
						<StructuredContent content={selectedPage?.content} />
						{submitError && <Alert sx={{my: 2}} severity="error">{submitError}</Alert>}
					</Box>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						{unitConfig.pages[unitIndex - 1] && (
							<Button
								variant='outlined'
								LinkComponent={Link}
								to={unitConfig.pages[unitIndex - 1].link}
								startIcon={<KeyboardDoubleArrowRightIcon />}
							>
								הקודם
							</Button>
						)}
						<Box sx={{flex: 1}} />
						{unitConfig.pages[unitIndex + 1] && (
							<Button
								variant='outlined'
								LinkComponent={Link}
								to={unitConfig.pages[unitIndex + 1].link}
								endIcon={<KeyboardDoubleArrowLeftIcon />}
							>
								הבא
							</Button>
						)}
					</Box>
				</Loader>
			</Paper>
		</>
	)
}
