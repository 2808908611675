import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export const useDebouncedEffect = (effect, deps, delay) => {
	useEffect(() => {
		const handler = setTimeout(() => effect(), delay);

		return () => clearTimeout(handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...(deps || []), delay]);
};


export const useCloseOnSubmit = (justSubmittedSelector, open, onClose) => {
	const justSubmitted = useSelector(justSubmittedSelector || (() => false))
	const previouslySubmitted = usePrevious(justSubmitted)

	useEffect(() => {
		if (open && !previouslySubmitted && justSubmitted) {
			onClose();
		}
	}, [justSubmitted, previouslySubmitted, onClose, open])
};
