import axios from 'axios';
import { retrieveAccessToken } from 'utils/authUtils';

const baseURL = `/api`;

const axiosInstance = axios.create({baseURL});

axiosInstance.interceptors.request.use(async request => {
	const accessToken = await retrieveAccessToken();
	request.headers.Authorization = `Bearer ${accessToken}`;
	return request;
});

export default axiosInstance;
