const { WbSunny, Handshake, MarkAsUnread, ThumbUp, WifiCalling3, Numbers, QuestionMark, Person, Feed, Notifications, Gavel, Group, Announcement, FlightTakeoff, PermContactCalendar, DragHandle, Mood, CheckCircleOutline, Event, Campaign, SentimentVeryDissatisfied, FavoriteBorder, Recommend, Info, DoneAll, Balance, ThumbDown, CallSplit, Star, Redeem, MedicalServices, ConnectWithoutContact, SportsScore, PowerSettingsNew, QueryStats } = require("@mui/icons-material")

const blocks = [
	{
		options: [
			"Dear Sir or Madam,",
			"Dear Mr. (Last Name):",
			"Dear (First Name),",
			"Dear Colleagues,",
			"Dear (First Name + Last Name),",
			"(First Name),",
			"Hi, (First Name),",
			"Good Morning, (First Name).",
		],
		key: "opening",
		title: "פתיחה",
		icon: WbSunny,
	},
	{
		options: [
			"I hope this email finds you well.",
			"I trust this email finds you doing well.",
			"It's good to hear from you.",
			"It's nice hearing from you.",
			"Hope you had a great time.",
			"I hope you have a pleasant trip back.",
			"I hope you enjoyed your stay in...",
			"Hope you enjoyed the event last week.",
			"It's been a while since we last spoke at the event in...",
		],
		key: "greeting",
		title: "ברכות",
		icon: Handshake,
	},
	{
		options: [
			"I am not sure if you have received my last email.",
			"I am not sure if you have seen my previous comment about...",
			"I am not sure if you have read my last email.",
			"I am not sure if you have reviewed my previous email, so I am resending it here.",
			"I was wondering if my email has gone through, since I haven't received your reply.",
			"I was wondering if you have received my last email.",
		],
		key: "answer",
		title: "קבלת מענה",
		icon: MarkAsUnread,
	},
	{
		options: [
			"Thanks for your prompt reply.",
			"Thanks for your time and your quick response.",
			"I appreciate your speedy response.",
			"I really appreciate your immediate reply.",
			"I really appreciate your immediate reply.",
			"Thank you for getting back to me so promptly regarding...",
		],
		key: "reply",
		title: "תודה על מענה",
		icon: ThumbUp,
	},
	{
		options: [
			"It was a real pleasure to meet with you last week.",
			"It was a great pleasure to work with you on the project.",
			"I really enjoyed our time together.",
			"I truly had fun talking with you.",
			"I wanted to thank you for your time today.",
			"I would like to thank you for all your help and support in this matter.",
			"It was so nice to meet you at the meeting last week.",
			"It was so great to finally meet you in person.",
			"It was great to speak with you at the event and it would be great to keep in touch.",
			"It was wonderful meeting you at the conference yesterday.",
		],
		key: "comment",
		title: "תגובה / הערה",
		icon: WifiCalling3,
	},
	{
		options: [
			"As per our conversation earlier today about...",
			"Per your email of January 1st, concerning...",
			"With reference to your previous email regarding...",
			"With reference to your phone call of January 1st, I'd like to...",
			"Following your request in relation to...",
			"Following your kind request, I would like to...",
			"Further to our recent meeting on Sunday, I would like to...",
			"Further to our earlier email exchange, I'd greatly appreciate if we could...",
			"I am just following up on our discussion of last week regarding the status of the project.",
			"With regards to our recent conversation about _, please be advised that...",
			"In accordance with our discussion on Sunday, I suggest that...",
		],
		key: "referencing",
		title: "התייחסות",
		icon: Numbers,
	},
	{
		options: [
			"I am writing to confirm the...",
			"I am writing to inquire about...",
			"I am contacting you about...",
			"I am contacting you on behalf of...",
			"I would like to open by sharing my appreciation to...",
			"I would like to start by telling you that...",
			"First and foremost, I would like to thank you for...",
			"First and foremost, I would like to begin by saying that...",
			"I would be interested to know how you feel about...",
			"I would be interested to know your thoughts in relation to...",
		],
		key: "reason",
		title: "ציון סיבה",
		icon: QuestionMark,
	},
	{
		options: [
			"Please allow me to introduce myself. My name is (First Name + Last Name).",
			"Please allow me to introduce myself as the new (Title) at (Company), effective January 1st.",
			"I would like to take this opportunity to introduce myself. My name is (First Name + Last Name).",
			"Please let me take this opportunity to introduce myself as the newly appointed (Title) at (Company).",
			"My name is (First Name + Last Name), and I am from (Company).",
			"I am (First Name), the new (Title) here at (Company).",
			"Great to e-meet you.",
			"Nice to e-meet you via email.",
		],
		key: "introduce",
		title: "הצגה עצמית",
		icon: Person,
	},
	{
		options: [
			"I just wanted to follow up with you on...",
			"Just to follow up on our previous email, I would like to...",
			"Just wanted to touch base with you regarding...",
			"I just wanted to touch base with you and see if we could...",
			"Just to update you on...",
			"I just wanted to give you an update on...",
			"I wanted to keep you in the loop regarding...",
			"Just wanted to keep you in the loop and let you know that...",
			"Just a quick note to tell you...",
			"Just a quick line to let you know that...",
			"I would like to keep you posted on...",
			"I wanted to keep you updated on a number of planned events.",
		],
		key: "update",
		title: "עדכון קצר",
		icon: Feed,
	},
	{
		options: [
			"I just wanted to send you a kind reminder about...",
			"Just want to give you a gentle reminder that...",
			"Just a quick note to remind you that...",
			"I would like to send you a short email to inform you of the...",
			"This is just a friendly reminder asking you to please...",
			"Just a gentle reminder requesting to kindly...",
			"I was wondering if you have had the chance to...",
			"Have you got the chance to...",
		],
		key: "remind",
		title: "עדכון ידידותי",
		icon: Notifications,
	},
	{
		options: [
			"I believe we should consider first what works best before making our final decisions.",
			"I feel we should currently focus on trying to come up with a good plan.",
			"As I see it, we have a number of possibilities we need to evaluate:",
			"In my opinion, we have two principal concerns that are causing so much confusion.",
			"I am sure that this is the best approach to take, as it makes it much easier to manage the process this way.",
			"I am certain about one thing: it is about time we move onto the next level of our plan.",
			"I believe our approach should be both practical and appropriate in helping to minimize the risk of...",
			"I feel our direction should be guided by the following key principles:",
		],
		key: "opinion",
		title: "הבעת דעה",
		icon: Gavel,
	},
	{
		options: [
			"Hi (John), ",
			"I would like to introduce you to (Jane Doe). ",
			"(Jane) is (Job Title) at (Company Name). (Jane) and I have been collaborating on various business ventures over the last few years. ",
			"(Jane), (John) is (Job Title) at (Company Name), and based on your mutual interest in... , I think it would be worthwhile for you two to connect. ",
			"(John), you can reach (Jane) at (Email Address) or (Phone / Cell Number). (Jane), you can reach (John) at the above email or (Phone / Cell Number). ",
			"I am sure you can take it from here. ",
			"Best of luck and success to you both, ",
			"(Your Name)",
			"Hi (John), ",
			"It is my pleasure to introduce you to (Jane Doe). ",
			"(Jane) is (Job Title) at (Company Name) and we go back a long way, from our early days working together at (Company Name). (Jane), (John) is (Job Title) at (Company Name). ",
			"(John), I told (Jane) about your work and she is extremely interested in meeting you and learning more about your business initiatives. I think the two of you might be able to partner and do some great things for both of your businesses. ",
			"Both contact details are below. I hope you are able to find some time to meet and will let you both take it from here. ",
			"All the best, ",
			"(Your Name)",
			"Hi (John), ",
			"I would like you to meet (Jane Doe), who is CC'd to this email. ",
			"(Jane) is (Job Title) at (Company Name). I am proud to say that I personally know (Jane) from interesting projects we have worked on together in the past. (Jane), (John) is (Job Title) at (Company Name). ",
			"  I am introducing you two, because I remember (John) that you have voiced an interest in... and that is why I immediately thought about connecting you with (Jane). I think you would both enjoy meeting and sharing your knowledge and experience. ",
			"Your email addresses are here, so I will leave it to you to connect directly. ",
			"Good luck to you both, ",
			"(Your Name)",
		],
		key: "introduction",
		title: "היכרות בין שני צדדים",
		icon: Group,
	},
	{
		options: [
			"Please be advised that...",
			"Kindly be advised of the following information:",
			"Please be informed that...",
			"Kindly be informed that according to...",
			"Please note that...",
			"Kindly take note that the schedule may be subject to change, due to unavoidable circumstances.",
			"Please be kindly reminded that...",
			"Please be politely reminded of the following information:",
			"I would like to call your attention to the following issue:",
			"I would like to draw your attention to the fact that...",
		],
		key: "highlight1",
		title: "הדגשת מידע",
		icon: Announcement,
	},
	{
		options: [
			"Dear Sender, ",
			"Thank you for your email. ",
			"I am out of the office and will be back on (MM/DD/YYYY). ",
			"During this period, I will have limited access to emails. In urgent cases only, please contact me on my cell phone at (Number). ",
			"All the best, ",
			"(Your Name)",
			"Hi, and thanks for your email. ",
			"I will be out of the office starting (MM/DD/YYYY) and will be returning to work on (MM/DD/YYYY). ",
			"If you need immediate assistance, please contact (Name of Colleague) at (Office / Cell Number) or (Email Address). Otherwise, I promise to get back to you upon my return. ",
			"Warmest regards, ",
			"(Your Name)",
			"Hey there, ",
			"Thank you for your message. ",
			"I am currently out of the office with no email access. ",
			"I will be returning on (MM/DD/YYYY). ",
			"For urgent matters only, you may reach me at (Cell Number). ",
			"Thanks and regards, ",
			"(Your Name)",
		],
		key: "office",
		title: "מחוץ למשרד",
		icon: FlightTakeoff,
	},
	{
		options: [
			"I received your contact details from (Name).",
			"I got your email address from (Name).",
			"I was referred to you by (Name).",
			"Your name was given to me by (Name).",
			"I was recommended to contact you regarding...",
			"(Name) suggested I contact you about...",
			"(Name) advised me to contact you directly regarding...",
			"I have been told to contact you concerning...",
		],
		key: "suggest",
		title: "המלצת קשר",
		icon: PermContactCalendar,
	},
	{
		options: [
			"Please see inline below.",
			"Please find my inline comments.",
			"Please find my elaborated comments below in (Color).",
			"Please see my response in (Color) below.",
			"My comments are embedded below.",
			"My answers are presented below.",
		],
		key: "inline",
		title: "מענה בהמשך",
		icon: DragHandle,
	},
	{
		options: [
			"I would greatly appreciate if you could please provide me with the...",
			"I would appreciate if you could kindly help me with...",
			"Could you please send me the document at your earliest convenience?",
			"Can you please advise me on...?",
			"It would be really helpful if you could please send me the...",
			"It'd be really great if you could please provide us any information on...",
			"Would you be so kind to guide me on how to...?",
			"Would you be so kind, as to help me with...?",
			"I would be grateful if you could please let me know...",
			"I would be grateful if you could help me in this matter.",
		],
		key: "request",
		title: "בקשה מנומסת",
		icon: Mood,
	},
	{
		options: [
			"I would be glad to help you with your...",
			"I'd be glad to talk with you about...",
			"I'd be happy to give you details about the...",
			"I will be happy to discuss further ideas.",
			"I will be delighted to discuss with you the...",
			"We would be delighted to exchange views with you regarding...",
			"I will be pleased to meet with you to further discuss...",
			"I would be pleased to know how we can move forward with...",
		],
		key: "agree",
		title: "הסכמה לבקשה",
		icon: CheckCircleOutline,
	},
	{
		options: [
			"Please let me know what time would be convenient for you to meet.",
			"Please let me know when would be a convenient time for me to contact you.",
			"Please let me know your availability to meet next week.",
			"Please let me know the best time to call you.",
			"Will you be available for a quick call tomorrow? If (Time) works for you, that is best on my end.",
			"Are you free for a chat this Sunday, January 1st, at (Time) to discuss...? Looking forward to talking with you soon.",
			"Do you have some time for a call this coming Sunday at (Time)? Feel free to suggest another time that works best for you.",
		],
		key: "meeting",
		title: "תאום פגישה",
		icon: Event,
	},
	{
		options: [
			"I'd appreciate if you could please attend to this matter at your earliest convenience.",
			"Please advise at your earliest convenience.",
			"Due to the urgency of the situation, I would appreciate receiving your prompt answer.",
			"As this matter is of utmost urgency and importance, please get back to me promptly.",
			"I would appreciate your prompt reply.",
			"Your prompt answer regarding this matter is highly appreciated.",
			"It is very important that we review the above issues as soon as possible.",
			"It is imperative that we go over any questions or concerns that you may have at our next meeting.",
		],
		key: "urgency",
		title: "דחיפות",
		icon: Campaign,
	},
	{
		options: [
			"I'm sorry it's taken me so long to get back to you.",
			"I would like to apologize for any inconvenience caused by this.",
			"Sorry for not being able to get back to you with an answer regarding...",
			"Unfortunately, I will not be able to participate in the call.",
			"I am afraid I will not be able to make it on Sunday. Sorry about that.",
			"Due to unfortunate circumstances, I will not be able to...",
		],
		key: "apology",
		title: "התנצלות",
		icon: SentimentVeryDissatisfied,
	},
	{
		options: [
			"I would like to thank you for your help and support.",
			"I'd like to thank you for your kind consideration.",
			"Thanks a lot for all your help and attention. I really appreciate it.",
			"Thank you so much for your efforts in trying to help me with...",
			"I would be grateful for any help you are able to provide me with this matter.",
			"I will be grateful if you can send me more information about...",
		],
		key: "advance",
		title: "תודה מראש",
		icon: FavoriteBorder,
	},
	{
		options: [
			"I recommend that we schedule a meeting to discuss this issue further.",
			"I suggest that we immediately move forward with option number one.",
			"I think it would be best if we kept these things to ourselves.",
			"I think it would be best if we considered this issue closed and moved on to something else.",
			"Below are some important alternatives you may want to consider:",
			"Several ideas you may want to consider when deciding which actions to take:",
			"I think your best bet would be to get in contact with (Name).",
			"I think your best bet would be to talk to (Name) about this issue.",
			"I personally think we should probably figure out other ways of addressing these concerns.",
			"I personally feel we should find time to learn new things and take on larger projects.",
		],
		key: "recommendation",
		title: "המלצה",
		icon: Recommend,
	},
	{
		options: [
			"Please find attached the... for your review.",
			"I am enclosing the... as per your request.",
			"Attached please find, as requested, the...",
			"Enclosed you will see the... you have asked for.",
			"Below please find the information you requested.",
			"Please see below the answers to your questions.",
			"Please find below the information you asked for.",
			"Please see the requested details below.",
		],
		key: "info",
		title: "צירוף מסמכים ומידע",
		icon: Info,
	},
	{
		options: [
			"I think you are exactly right about that.",
			"I think you may well be right in saying we need to...",
			"I totally agree with you on what you are saying here.",
			"I agree with you on this one, because I think...",
			"I tend to agree with you on this for the most part. However, as you know, there are always exceptions and this should also be taken into consideration.",
			"I quite agree with you on most of the points you have raised. The one I have a problem agreeing to is the point above where you indicated that...",
			"This is an interesting approach you have raised here. I will try to give it a shot; thanks a lot for sharing.",
			"This is an interesting observation and I look forward to experimenting with it.",
		],
		key: "doublecheck",
		title: "הכרה, הסכמה",
		icon: DoneAll,
	},
	{
		options: [
			"Please advise.",
			"Please advise, as this issue is very urgent.",
			"Could you please recommend me the best approach to take here?",
			"Can you please suggest me any kind of new insight on this matter?",
			"What's your take on that?",
			"Would love to get your take on this from your experience; thanks a lot.",
			"Could you please shed some light on the best way to do this?",
			"Can you please shed some light, as to what the issue is?",
		],
		key: "advice",
		title: "בקשה לעצה",
		icon: Balance,
	},
	{
		options: [
			"I have to respectfully disagree with you here.",
			"I think I have to respectfully disagree with your opinion.",
			"I apologize, but I disagree with you when you say that...",
			"I am sorry that I must disagree with you on your position regarding...",
			"Sorry, but I beg to differ on your answer.",
			"I understand your point of view, but I agree to differ. What I propose is to...",
			"I understand what you are saying. However, I personally feel that...",
			"I hear exactly what you are saying. Let's just say I have a different view of things.",
			"I see where you are coming from, but I still do not agree with your decision.",
			"I understand exactly where you are coming from, but I will have to respectfully disagree with you here.",
		],
		key: "disagree",
		title: "אי הסכמה",
		icon: ThumbDown,
	},
	{
		options: [
			"Please get back to me by the end of the business day.",
			"I would appreciate if you could please get back to me with an answer by end of business on Monday.",
			"Please respond to this email by the end of the business week.",
			"Please answer to this email by the close of business tomorrow.",
			"I would appreciate if you respond to this email, as this matter is very urgent and cannot be delayed.",
			"I would appreciate if you could please reply to this message as early as possible, but no later than Sunday, January 1st. Thank you very much for your time and assistance with this request.",
			"Please take some action, since this is an ongoing problem I am facing with...",
			"Please seek action, as this is the only way to resolve this problem.",
		],
		key: "action",
		title: "בקשה לפעולה",
		icon: CallSplit,
	},
	{
		options: [
			"I really appreciate your hard work and diligence in getting this done.",
			"I highly appreciate all your hard work and efforts; they are much appreciated and noticed.",
			"It could not have happened without your continued help and support.",
			"It simply would not have happened without your contribution and for that I am grateful.",
			"We wouldn't be where we are if it wasn't for you.",
			"We would not be where we are today if it was not for your investment and belief.",
			"Thank you for spending time and efforts to make sure that...",
			"Thanks for a job well done and right on time.",
		],
		key: "praise",
		title: "שבחים",
		icon: Star,
	},
	{
		options: [
			"I just wanted to take a moment to sincerely thank you for...",
			"Just wanted to write a note to say thank you for all you have done.",
			"Thank you for taking the time to help me with...",
			"I would like to thank you for taking the time to...",
			"Thanks for all you have done for me. I really appreciate it.",
			"Thanks for everything you have done to date on these issues.",
			"Thanks again for meeting with me last week. It was an excellent exchange of views. I really appreciate your time and valuable feedback.",
			"Thanks again for making the event a huge success and we look forward to another great event next year.",
			"Thank you.",
			"Thanks a lot.",
		],
		key: "thanks",
		title: "תודות",
		icon: Redeem,
	},
	{
		options: [
			"Please feel free to let me know if you require any further information.",
			"Please do not hesitate to contact me should you have any questions.",
			"Should you need help in any way, please don't hesitate to contact me.",
			"Should I be of any further assistance, please feel free to call me.",
			"Please let me know, if you have any questions. I am more than happy to assist in any way I can.",
			"Do let me know, if you need any information. I will be happy to assist you.",
			"If I can be of any assistance, please let me know.",
			"Please feel free to call me, if I can be of any assistance.",
		],
		key: "help",
		title: "הצעת עזרה, תמיכה",
		icon: MedicalServices,
	},
	{
		options: [
			"I look forward to meeting you soon.",
			"Looking forward to discussing this matter with you.",
			"It would be great to meet you and hear about your business in more detail.",
			"It will be a pleasure to meet you for a quick chat sometime next week.",
			"I hope to hear from you soon.",
			"Awaiting your reply at your earliest.",
		],
		key: "future",
		title: "הזמנה לקשר עתידי",
		icon: ConnectWithoutContact,
	},
	{
		options: [
			"Please let me know how you wish to move forward with the...",
			"Please let me know how you would like to proceed from here.",
			"What do you think would be the next necessary step moving forward?",
			"What do you think should be the next move?",
			"How do you think we should proceed with...?",
			"How do you suggest we progress from here?",
		],
		key: "nextsteps",
		title: "צעדים הבאים",
		icon: QueryStats,
	},
	{
		options: [
			"Have a nice day.",
			"Have a good one.",
			"Have a great holiday.",
			"Hope you have a wonderful holiday with your loved ones.",
			"Have a safe trip.",
			"Hope you have a productive business trip and I will see you soon.",
			"Talk to you later.",
			"Take care.",
			"Hope this helps.",
			"I hope this answers your question.",
			"Your thoughts are always welcome.",
			"Your ideas and remarks are appreciated.",
		],
		key: "final",
		title: "משפטי סיום",
		icon: SportsScore,
	},
	{
		options: [
			"Faithfully,",
			"Yours faithfully,",
			"Sincerely,",
			"Yours sincerely,",
			"Regards,",
			"Kind regards,",
		],
		key: "finalword",
		title: "סיומת",
		icon: PowerSettingsNew,
	},
]

export default blocks;