import React from "react";
import {Popover} from '@mui/material';

export default function PopoverButton({button, popoverContent, id = 'popover', anchor}) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const popoverId = open ? id : undefined;
	const anchorOrigin = {vertical: 'bottom', horizontal: 'left', ...anchor}
	return (
		<>
			{React.cloneElement(button, {'aria-describedby': popoverId, onClick: handleClick})}
			<Popover
				id={popoverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={anchorOrigin}
			>
				{popoverContent}
			</Popover>
		</>
	);
}
