import React, {useRef, useState} from 'react';
import {Box, Alert, Typography, List, ListItem, TextField} from '@mui/material';
import {Field, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import config from 'config';
import SingleFileDropzoneArea from 'components/DropzoneArea/SingleFileDropzoneArea';
import {
	CloudUpload as CloudUploadIcon,
	OpenInNew as OpenInNewIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
	Save as SaveIcon,
} from '@mui/icons-material';
import TooltipButton from 'components/TooltipButton/TooltipButton';

export default function DropzoneAreaField({label, acceptedFiles, maxFileSize, ...props}) {
	const [fileName, setFileName] = useState();
	const [field, meta, helpers] = useField(props)

	return (

		field.value.name ? (
			<SingleFileDropzoneArea
				dropzoneText={label}
				acceptedFiles={acceptedFiles}
				maxFileSize={maxFileSize}
				filesLimit={field.value.name ? 1 : 0}
				useChipsForPreview
				value={field.value.content}
				onChange={(value) => helpers.setValue({ ...field.value, content: value})}
				onClearFiles={() => helpers.setValue({ ...field.value, content: null})}
				showAlerts={false}
				dropzoneProps={{disabled: props.isDisabled}}
				showPreviewsInDropzone={false}
				renderContent={({handleRemove}) => (
					<Box sx={{p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2}}>
						{field.value.content ? (
							<Box sx={{display: 'flex', alignItems: 'center'}}>
								<CheckCircleOutlineIcon color="success" sx={{mr: 0.5}} />
								{props.loadedLabel.replace('{{name}}', field.value.name)}
								{
									<TooltipButton
										title="פתיחה בטאב חדש"
										icon={OpenInNewIcon}
										iconProps={{fontSize: 'small'}}
										component="a"
										target="_blank"
										href={field.value.content}
										onClick={e => e.stopPropagation()}
									/>
								}
							</Box>
						) : (
							<>
								<Typography
								>
									{label.replace('{{name}}', field.value.name)}
								</Typography>
								<CloudUploadIcon fontSize='large' />
							</>
						)}
					</Box>
				)}
			/>
		) : (
			<Box sx={{
				position: 'relative',
				width: '100%',
				backgroundColor: t => t.palette.background.paper,
				border: 'dashed',
				borderColor: t => t.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
				borderRadius: 2,
				borderWidth: 2,
				p: 2,
				display: 'flex',
				justifyContent: 'center',
			}}>
				<TextField
					label="שם הקובץ להצגה"
					size="small"
					value={fileName}
					onChange={(e) => setFileName(e.target.value)}
				/>
				<TooltipButton
					title="בחירת שם"
					icon={SaveIcon}
					color="primary"
					onClick={() => helpers.setValue({...field.value, name: fileName})}
				/>
			</Box>
		)
	);
}

//