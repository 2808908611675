import _ from 'lodash';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { Actions } from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';
import * as LevelSelectors from 'modules/levels/selectors';
import axios from 'services/axios';

// import _ from 'lodash';


function* fetchLevelChapters(action) {
	yield put(Actions.FETCH_LEVEL_CHAPTERS_REQUEST());
	try {
		const url = `level/${action.payload.level}/chapters/query?offset=0&limit=-1&orderBy=chapter.order&order=asc&includes=name,level,order`;
		const {data} = yield call(axios.post, url, {});
		yield put(Actions.FETCH_LEVEL_CHAPTERS_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_LEVEL_CHAPTERS_FAILURE(err));
	}
}

function* reorderChapters(action) {
	yield put(Actions.UPDATE_CHAPTERS_ORDER_REQUEST());
	try {
		const url = `chapters/reorder`;
		const {data} = yield call(axios.post, url, action.payload);
		yield put(Actions.UPDATE_CHAPTERS_ORDER_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.UPDATE_CHAPTERS_ORDER_FAILURE(err));
	}
}


function* fetchSingleChapter(action) {
	const {id} = action.payload;
	if (!id) {return;}

	yield put(Actions.FETCH_SINGLE_CHAPTER_REQUEST());
	try {
		const url = `chapters/${id}`;
		const {data} = yield call(axios.get, url);
		yield put(Actions.FETCH_SINGLE_CHAPTER_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_SINGLE_CHAPTER_FAILURE(err));
	}
}

function* submitChapterForm(action) {
	try {
		yield put(Actions.SUBMIT_CHAPTER_FORM_REQUEST());
		
		const {payload} = action;
		const chapterId = payload.id;

		const fields = _.pick(payload, [
			'name', 'vocabulary', 'comments', 'content', 'summaryPDF', 'vocabularyPDF', 'downloads',
		]);
		
		const level = yield select(LevelSelectors.matchingLevelAlias);
		const method = chapterId ? axios.patch : axios.post;
		const url = chapterId ? `chapters/${chapterId}` : `level/${level}/chapters`;
		const result = yield call(method, url, fields);

		yield put(Actions.SUBMIT_CHAPTER_FORM_SUCCESS(result.data, {isNew: !chapterId}));
		
	} catch (err) {
		yield put(Actions.SUBMIT_CHAPTER_FORM_FAILURE(err));
	}
}

function* deleteChapter(action) {
	const {id} = action.payload;
	if (!id) {
		return;
	}
	yield put(Actions.DELETE_SINGLE_CHAPTER_REQUEST());
	try {
		const {data} = yield call(axios.delete, `chapters/${id}`);
        
		yield put(Actions.DELETE_SINGLE_CHAPTER_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.DELETE_SINGLE_CHAPTER_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActionTypes.SIDEBAR_LEVEL_CHANGED, fetchLevelChapters),
	takeLatest(PagesActionTypes.LEVEL_CHAPTERS_ORDER_UPDATED, reorderChapters),
	takeLatest(PagesActionTypes.SINGLE_CHAPTER_EDIT_MOUNTED, fetchSingleChapter),
	takeLatest(PagesActionTypes.SINGLE_STUDENT_CHAPTER_MOUNTED, fetchSingleChapter),
	takeLatest(PagesActionTypes.SINGLE_CHAPTER_FORM_SUBMITTED, submitChapterForm),
	takeLatest(PagesActionTypes.SINGLE_CHAPTER_DELETE_SUBMITTED, deleteChapter),
]

export default sagas;
