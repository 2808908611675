export {connect} from 'react-redux';

export function createActionCreator(type) {
	return (payload, meta) => {
		const action = {type};
		if (typeof payload !== 'undefined') {
			action.payload = payload;
		}

		if (typeof meta !== 'undefined') {
			action.meta = meta;
		}

		if (payload instanceof Error) {
			action.error = true;
		}

		return action;
	};
}

export function createActionCreators(typesArray) {
	return typesArray.reduce((result, type) => {
		result[type] = createActionCreator(type);
		return result;
	}, {})
}

export const createActionTypes = (actionTypes) => (
	actionTypes.reduce((prev, current) => {
		const next = prev;
		next[current] = current;
		return next;
	}, {})
);

export const mergeBy = (items, rawItem, by = 'id', placeAtTop = true) => {
	const newItems = Array.isArray(rawItem) ? rawItem : [rawItem]
	const result = (items || []).slice();

	newItems.forEach(item => {
		const position = result.findIndex(c => c[by] === item[by]);
		if (position >= 0) {
			result[position] = item;
		} else if (placeAtTop) {
			result.unshift(item);
		} else {
			result.push(item)
		}
	})
	return result;
}

export const setLoader = (state, loader, newState) => {
	return {loaders: {...state.loaders, [loader]: newState}}
}

export const mergeItemsBy = (source, items, by = 'id') => {
	return items.reduce((aggr, item) => {
		return mergeBy(aggr, item, by)
	}, source);
}

export const updateBy = (items, item, id, by = 'id') => {
	const result = (items || []).slice();
	const position = result.findIndex(c => c[by] === id);
	if (position >= 0) {
		Object.keys(item).map((keyName) => result[position][keyName] = item[keyName])
	} 
	else {
		result.unshift(item);
	}
	return result;
}

export const filterBy = (items, item, by = 'id') => {
	const result = (items || []).filter(i => i[by] !== item[by]);
	return result;
}