import _ from 'lodash';
import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Tooltip, IconButton, Paper, Box,  Chip} from '@mui/material';
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	Add as AddIcon,
	Password as PasswordIcon,

	Group as GroupIcon,
} from '@mui/icons-material';
import {DataGrid, GridOverlay} from '@mui/x-data-grid';

// components
import PageTitle from "../../components/PageTitle";
import Loader from "components/Loader/Loader";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { useDebouncedEffect } from "utils/hooks";
import {Actions} from 'pages/actions';
import * as UsersSelectors from 'modules/users/selectors';
import GridToolBar from 'components/GridToolBar/GridToolBar';
import config from 'config/config';
import CreateEditUser from 'pages/createEditUser';
import { permissionsOptionsMap } from 'utils/autoComplete';
import { dateToDateTimeString } from 'utils/transformUtils';


function getColumns({renderActionsColumn}) {
	
	return [
		{ field: 'name', headerName: 'שם מלא', minWidth: 250},
		{ field: 'email', headerName: 'אימייל', minWidth: 250},
		{ field: 'selectedLevel', headerName: 'רמת המשתמש', minWidth: 150, valueFormatter: ({value}) => config.levels[value]},
		{ field: 'permissionsVal', sortable: false, headerName: 'הרשאות', minWidth: 150, flex: 1,
			renderCell: ({row}) => (
				<Box sx={{display: 'flex', gap: 1}}>
					{row.permissions?.map(p => (<Chip key={p} label={permissionsOptionsMap[p].name} />))}
				</Box>
			)},
		{ field: 'createdAt', headerName: 'נוצר', type: 'date', width: 160, align: 'center', renderCell: ({value}) => <Box sx={{direction: "ltr"}}>{dateToDateTimeString(value)}</Box>},
		{ field: 'updatedAt', headerName: 'עודכן לאחרונה', type: 'date', width: 160, align: 'center', renderCell: ({value}) => <Box sx={{direction: "ltr"}}>{dateToDateTimeString(value)}</Box>},
		{ 
			field: 'actions', headerName: 'פעולות', sortable: false,
			renderCell: renderActionsColumn,
			width: 150,
		},
	]
}

function ActionsColumn({row, onEditClick, onDeleteClick, onPasswordResetClick}) {
	return (
		<>
			<Tooltip arrow title="איפוס סיסמה">
				<IconButton
					aria-label="איפוס סיסמה"
					sx={{p: 1}}
					onClick={() => onPasswordResetClick(row)}
					size="large">
					<PasswordIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Tooltip arrow title="עריכה">
				<IconButton
					aria-label="עריכה"
					sx={{p: 1}}
					onClick={() => onEditClick(row)}
					size="large">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Tooltip arrow title="מחיקה">
				<IconButton
					aria-label="מחיקה"
					sx={{p: 1}}
					onClick={() => onDeleteClick(row)}
					color="error"
					size="large">
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

export default function UsersPage() {
	const users = useSelector(UsersSelectors.users)
	const usersTotal = useSelector(UsersSelectors.usersTotal)
	const userSubmitError = useSelector(UsersSelectors.submitError)
	const {loading, submitting: userSubmitting} = useSelector(UsersSelectors.loaders)
	const [confirmation, setConfirmation] = useState({open: false});
	const [filter, setFilter] = useState({text: ''});
	const [pagination, setPagination] = useState({page: 0, pageSize: 10});
	const [userModal, setUserModal] = useState({user: null, open: false});
	const [sortModel, setSortModel] = useState([{field: 'updatedAt', sort: 'desc'}])
	
	const dispatch = useDispatch();

	function updateFilter(newValue) {
		setFilter({...filter, text: newValue});
		setPagination({page: 0, pageSize: pagination.pageSize});
	}

	function handleDeleteConfirm(user) {
		dispatch(Actions.USER_DELETE_SUBMITTED(user));
	}

	function openConfirmDelete(user) {
		dispatch(Actions.USER_CONFIRM_MODAL_OPENED(user));
		setConfirmation({
			open: true,
			title: `מחיקה - ${user.name}`,
			subtitle: 'האם למחוק את המשתמש הזה?\nהמשתמש וכל הנתונים שלו ימחקו ולא תהיה לו יותר גישה למערכת',
			confirmText: 'מחיקה',
			variant: 'error',
			onConfirm: () => handleDeleteConfirm(user),
			onCancel: () => setConfirmation({open: false}),
		});
	}

	function openConfirmResetPassword(user) {
		dispatch(Actions.USER_CONFIRM_MODAL_OPENED(user));
		setConfirmation({
			open: true,
			title: `איפוס סיסמה - ${user.name}`,
			subtitle: 'האם לאפס סיסמה למשתמש זה?\nאימייל ישלח למשתמש עם פרטי ההתחברות החדשים שלו',
			confirmText: 'איפוס סיסמה',
			onConfirm: () => dispatch(Actions.USER_RESET_PASSWORD_SUBMITTED({id: user.id, generatePassword: true})),
			onCancel: () => setConfirmation({open: false}),
		});
	}
	
	function handleDoubleCellClick(params) {
		if (params.field !== 'actions') {
			setUserModal({user: params.row, open: true})
		}
	}
	
	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onDeleteClick={openConfirmDelete}
				onPasswordResetClick={openConfirmResetPassword}
				onEditClick={() => setUserModal({user: params.row, open: true})}
			/>
		);
	}

	useDebouncedEffect(() => {
		dispatch(Actions.USERS_FILTERING_CHANGED({pagination, filter, sortModel}));
	}, [dispatch, pagination, filter, sortModel], 300)

	/* Users Modal */
	const title = `משתמשים`;
	
	function handleModalClose() {
		setUserModal({open: false, user: null});
	}
	/* End: Users Modal */

	return (
		<>
			<PageTitle title={title} icon={GroupIcon} />
			<Paper sx={{p: 2}}>
				<Loader inline loading={loading}>
					<Box sx={{height: 687}}>
						<DataGrid
							components={{
								Toolbar: GridToolBar,
								NoRowsOverlay: () => <GridOverlay>אין משתמשים להציג</GridOverlay>,
								NoResultsOverlay: () => <GridOverlay>No results. Try changing the filter</GridOverlay>,
							}}
							rows={users}
							columns={getColumns({renderActionsColumn})}
							disableColumnMenu
							pageSize={pagination.pageSize}
							rowCount={usersTotal}
							paginationMode="server"
							page={pagination.page}
							onPageSizeChange={(newPageSize) => setPagination({...pagination, pageSize: newPageSize})}
							onPageChange={(page) => setPagination({ ...pagination, page })}
							sortingMode="server"
							sortModel={sortModel}
							onSortModelChange={setSortModel}
							rowsPerPageOptions={config.paginationOptions}
							componentsProps={{
								toolbar: {
									searchValue: filter.text,
									onSearchChange: (event) => updateFilter(event.target.value),
									clearSearch: () => updateFilter(''),
									actions: [{
										icon: AddIcon,
										primary: true,
										hint: 'Add new publisher',
										onClick: () => setUserModal({user: null, open: true}),
									}],
								},
							}}
							onCellDoubleClick={handleDoubleCellClick}
						/>
					</Box>
				</Loader>
				<CreateEditUser open={userModal.open} user={userModal.user} onClose={handleModalClose} />
				<ConfirmDialog
					{...confirmation}
					loading={userSubmitting}
					alert={userSubmitError}
					justSubmittedSelector={UsersSelectors.justSubmitted}
				/>
			</Paper>
		</>
	)
}
