import _ from 'lodash';
import { createSelector } from 'reselect';
import * as NavSelectors from 'modules/navigation/selectors';

export const sliceSelector = (state) => state.chapters;
export const chapters = createSelector(sliceSelector, slice => slice.chapters || []);
export const chaptersTotal = createSelector(sliceSelector, slice => slice.chaptersTotal || 0);
export const selectedChapter = createSelector(sliceSelector, slice => slice.selectedChapter);
export const loaders = createSelector(sliceSelector, slice => slice.loaders);
export const justSubmitted = createSelector(sliceSelector, slice => !!slice.justSubmitted);
export const submitError = createSelector(sliceSelector, slice => slice.submitError);

export const filter = createSelector(sliceSelector, slice => slice.filter);

export const matchingChapterId = createSelector(
	NavSelectors.pathnameSelector,
	(pathname) => {
		const matchedRegex = (pathname || '').match(/\/chapters\/([^/?]*)/) || [];
		const id = matchedRegex[1];
		return parseInt(id);
	}
);
