import {IconButton, Box} from '@mui/material';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import * as React from 'react';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles = {
	successAlert: {
		bgcolor: '#43a047', // green
		color: '#fff',
	},
	errorAlert: {
		bgcolor: 'error.main',
	},
	infoAlert: {
		bgcolor: 'info.main',
	},
	warningAlert: {
		bgcolor: 'warning.main',
	},
	message: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			marginRight: t => t.spacing(1),
		},
	},
	icon: {
		fontSize: 20,
		opacity: 0.9,
	},
	closeButton: {},
};

const SnackbarContentWrapper = React.forwardRef((props, ref) => {
	const {className, message, onClose, variant, ...other} = props;
	const Icon = variantIcon[variant];
	return (
		<SnackbarContent
			ref={ref}
			className={className}
			sx={styles[`${variant}Alert`]}
			aria-describedby="client-snackbar"
			message={
				<Box component="span" id="client-snackbar" sx={styles.message}>
					<Icon sx={styles.icon} />
					{message}
				</Box>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					sx={styles.closeButton}
					onClick={onClose}
				>
					<CloseIcon sx={styles.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
});

SnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

SnackbarContentWrapper.displayName  = "SnackbarContentWrapper";

export default SnackbarContentWrapper;