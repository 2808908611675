import React, { useState, useEffect } from "react";
import { Drawer as MuiDrawer, List, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
	MenuBook as MenuBookIcon,
	DirectionsWalk as DirectionsWalkIcon,
	ListAlt as ListAltIcon,
	Chat as ChatIcon,
	Email as EmailIcon,
	CardGiftcard as CardGiftcardIcon,
} from "@mui/icons-material";
import { useTheme, styled } from '@mui/material/styles';
import * as ChapterSelectors from 'modules/chapters/selectors';
import * as AuthSelectors from 'modules/auth/selectors';
import * as NavSelectors from 'modules/navigation/selectors';
import {useDispatch, useSelector} from 'react-redux';

// styles
import styles from "./styles";
import Logo from 'images/logo.png';

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
	toggleSidebar,
	useLayoutDispatch,
	useLayoutState,
} from "../../context/LayoutContext";
import { Actions } from "pages/actions";
import config from "config/config";
import { levelOptions } from "utils/autoComplete";
import { useLocation, useNavigate } from "react-router";

const structure = [
	{
		label: "English By The Way", baseLink: "/app/units/btw", icon: <DirectionsWalkIcon />, permission: 'byTheWay',
		children: config.units.btw.pages,
	},
	{
		label: "5k Words", link: "/app/units/words/list", icon: <ListAltIcon />, permission: 'words',
	},
	{
		label: "Email Helper", link: "/app/emailHelper", icon: <EmailIcon />, permission: 'emailHelper',
	},
	{
		label: "בונוסים", baseLink: "/app/bonus", icon: <CardGiftcardIcon />, permission: 'core',
		children: config.units.bonuses.pages,
	},
];


const Drawer = styled(MuiDrawer)(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: theme.sidebar.student.width,
			paddingTop: theme.spacing(6),
			[theme.breakpoints.up('md')]: {
				paddingTop: theme.spacing(8),
			},
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9),
				},
			}),
		},
	}),
);

export default function StudentSidebar() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const user = useSelector(AuthSelectors.user);
	const userLevel = useSelector(AuthSelectors.userLevel);
	const userPermissions = useSelector(AuthSelectors.userPermissions);
	const matchingLevel = useSelector(NavSelectors.matchingLevel);
	const chapters = useSelector(ChapterSelectors.chapters);
	const navigate = useNavigate();
	const location = useLocation();

	// global
	const { isSidebarOpened } = useLayoutState();
	const layoutDispatch = useLayoutDispatch();
	// local
	const [isPermanent, setPermanent] = useState(true);
	const [smallTalkLevel, setSmallTalkLevel] = useState(userLevel);

	useEffect(() => {
		if (!isPermanent) {
			toggleSidebar(layoutDispatch, false);
		}
	}, [location.key, isPermanent, layoutDispatch]);
	
	useEffect(function() {
		window.addEventListener("resize", handleWindowWidthChange);
		handleWindowWidthChange();
		return function cleanup() {
			window.removeEventListener("resize", handleWindowWidthChange);
		};
	});

	useEffect(() => {
		if (userLevel) {
			dispatch(Actions.SIDEBAR_LEVEL_CHANGED({level: userLevel}));
		}
	}, [userLevel, dispatch]);

	useEffect(() => {
		setSmallTalkLevel(matchingLevel || userLevel);
	}, [userLevel, matchingLevel])

	const smallTalkLevelConfig = config.units.smallTalk?.levels[smallTalkLevel];

	function handleSmallTalkLevelChange(newLevel) {
		setSmallTalkLevel(newLevel);
		const smallTalkLink = config.units.smallTalk.levels[newLevel].pages[0].link;
		navigate(smallTalkLink)
	}

	/* TODO: Ugly hack to remove bonuses from secondary tenant - to remove! */
	const actualStracture = user?.tenantId === 111111 ? structure : structure.slice(0, -1);
	return (
		<Drawer
			variant={isPermanent ? "permanent" : "temporary"}
			sx={{
				...styles.drawer,
				...(isSidebarOpened ? styles.drawerOpen : styles.drawerClose),
			}}
			open={isSidebarOpened}
			anchor="left"
		>
			<Box sx={{p: '20px 10px', mb: 2, textAlign: 'center', filter: 'brightness(0)' }}>
				<img src={Logo} alt="logo" style={styles.logo} />
			</Box>
			<List sx={isSidebarOpened ? styles.sidebarListMargin : null}>
				{userPermissions.includes('core') && (
					<SidebarLink
						key="core"
						isSidebarOpened={isSidebarOpened}
						label="קורס קונפידנס"
						baseLink="/app/chapters"
						icon={<MenuBookIcon />}
						children={chapters.map(c => ({label: c.name, link: `/app/chapters/${c.id}`}))}
					/>
				)}
				{userPermissions.includes('smallTalk') && (
					<SidebarLink
						key="smallTalk"
						isSidebarOpened={isSidebarOpened}
						label="Small Talk"
						baseLink="/app/units/smallTalk"
						icon={<ChatIcon />}
						children={[{
							component: (
								<SidebarLink
									key="smallTalkSelector"
									isSidebarOpened={isSidebarOpened}
									label={(
										<FormControl fullWidth size="small">
											<InputLabel id="levelSelect">בחירת שלב</InputLabel>
											<Select
												size="small"
												label="בחירת שלב"
												labelId="levelSelect" fullWidth
												value={smallTalkLevel}
												onChange={e => handleSmallTalkLevelChange(e.target.value)}
											>
												{levelOptions.map(({id, name}) => (
													<MenuItem key={id} value={id}>{name}</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								/>
							),
						},
						...smallTalkLevelConfig?.pages,
						]}
					/>
				)}
				{actualStracture.filter(s => !s.permission || userPermissions.includes(s.permission)).map((link, i) => (
					<SidebarLink
						key={link.id || i}
						isSidebarOpened={isSidebarOpened}
						{...link}
					/>
				))}
			</List>
		</Drawer>
	);

	// ##################################################################
	function handleWindowWidthChange() {
		var windowWidth = window.innerWidth;
		var breakpointWidth = theme.breakpoints.values.md;
		var isSmallScreen = windowWidth < breakpointWidth;

		if (isSmallScreen && isPermanent) {
			setPermanent(false);
		} else if (!isSmallScreen && !isPermanent) {
			setPermanent(true);
		}
	}
}
