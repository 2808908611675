import React from "react";
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';

// styles
var styles = {
	dotBase: {
		width: 8,
		height: 8,
		backgroundColor: theme => theme.palette.text.hint,
		borderRadius: "50%",
		transition: theme => theme.transitions.create("background-color"),
	},
	dotSmall: {
		width: 5,
		height: 5,
	},
	dotLarge: {
		width: 11,
		height: 11,
	},
};

export default function Dot({ size, color }) {
	var theme = useTheme();

	return (
		<Box
			sx={{
				...styles.dotBase,
				...(size === "large" ? styles.dotLarge : {}),
				...(size === "small" ? styles.dotSmall : {}),
			}}
			style={{
				backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
			}}
		/>
	);
}
