import _ from 'lodash';
import React from "react";
import {Paper} from '@mui/material';
import {
	CardGiftcard as CardGiftcardIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import StructuredContent from 'components/StructuredContent/StructuredContent';
import presentationCover from 'images/presentationCover.png';
import {presentationSteps, presentationTemplates} from './presentationSteps';

export default function WinningPresentationPage() {	
	return (
		<>
			<PageTitle title="המדריך לבניית מצגת מנצחת" icon={CardGiftcardIcon} />
			<Paper sx={{p: 2}}>
				<StructuredContent
					content={[
						{type: 'image', src: presentationCover},
						{type: 'buttonLink', title: 'להורדת המדריך המלא', url: 'https://storage.googleapis.com/confidence-eng-shared/modules/winningPresentation/presentation-guide.pdf'},
						...presentationSteps.map((pc, index) => ({
							type: 'accordion', title: `חלק ${index + 1} - ${pc.title}`,
							children: [
								{type: 'typography', text: pc.title, variant: 'h2', sx: {textAlign: 'center', color: 'primary.main'} },
								{type: 'html', html: pc.text, sx: {textAlign: 'center', whiteSpace: 'pre-line'}},
							],
						})),
						{
							type: 'accordion', title: `תבניות למצגת`,
							children: [
								{type: 'typography', text: 'הכנו עבורך תבניות מוכנות ומעוצבות לפי נושאים', variant: 'h2', sx: {textAlign: 'center', color: 'primary.main'} },
								{type: 'typography', text: 'כל מה שעליך לעשות זה לבחור את המצגת המתאימה עבורך! להתאים את התוכן, ולבנות את המצגת הבאה שלך', variant: 'h3', sx: {textAlign: 'center', mt: 2} },
								...presentationTemplates.map(pt => ({
									type: 'buttonLink', url: pt.link, title: pt.text, sx: {mt: 2}, variant: 'text',
								})),
							],
						},
					]}
				/>
			</Paper>
		</>
	)
}
