import defaultTheme from "./default";

import { createTheme } from '@mui/material/styles';
import {heIL} from '@mui/x-data-grid';

const theme = createTheme()
const overrides = {
	typography: {
		// fontFamily: 'Heebo, sans-serif',
		h1: {
			fontSize: "3rem",
		},
		h2: {
			fontSize: "2rem",
		},
		h3: {
			fontSize: "1.64rem",
		},
		h4: {
			fontSize: "1.5rem",
		},
		h5: {
			fontSize: "1.285rem",
		},
		h6: {
			fontSize: "1.142rem",
		},
	},
	sidebar: {
		admin: {width: 240},
		student: {width: 340},
	},
	iframe: {
		border: 'none',
		width: '100%',
		maxWidth: '640px',
		height: {
			'xs': 200,
			'sm': 360,
		},
	},
};

const obj = {
	default: createTheme(theme, { ...defaultTheme, ...overrides, direction: 'rtl' }, heIL),
};

export default obj;
