import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	'FETCH_USERS_REQUEST',
	'FETCH_USERS_SUCCESS',
	'FETCH_USERS_FAILURE',

	'SUBMIT_USER_FORM_REQUEST',
	'SUBMIT_USER_FORM_SUCCESS',
	'SUBMIT_USER_FORM_FAILURE',

	'DELETE_USER_REQUEST',
	'DELETE_USER_SUCCESS',
	'DELETE_USER_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
