import queryString from 'query-string';
import {eventChannel, buffers} from 'redux-saga';
import {put, call, takeLatest, fork, take, cancel, delay, race} from 'redux-saga/effects';
import {ActionTypes as AuthActionTypes, Actions as AuthActions} from 'modules/auth/actions';
import axios from 'services/axios';
import config from 'config';
import {ActionTypes as PagesActions} from 'pages/actions';
import { retrieveAccessToken, parseAccessToken, storeAccessToken, clearAccessToken } from 'utils/authUtils';
import _ from 'lodash';


export function* logout(reason) {
	yield call(clearAccessToken);
	yield put(AuthActions.LOGOUT_SUCCESS({reason}));
};

function* updateOnMouseOrKeyboardTouch() {
	const channel = eventChannel(emit => {
		document.addEventListener('click', emit);
		document.addEventListener('keydown', emit);
		return () => {
			document.removeEventListener('click', emit);
			document.removeEventListener('keydown', emit);
		}
	}, buffers.dropping(1));
	try {
		while (true) {
			yield delay(config.idleThrottle * 1000);
			const {activity} = yield race({
				activity: take(channel),
				timeout: delay(config.sessionIdleExpiration * 1000),
			})
			if (!activity) {
				yield call(logout, 'timeout');
			}
		}
	} finally {
		channel.close();
	}
}

export function* monitorActivity() {
	const task = yield fork(updateOnMouseOrKeyboardTouch);
	yield take(AuthActionTypes.LOGOUT_SUCCESS);
	yield cancel(task);
}

function* loginWithPassword(action) {
	try {
		yield put(AuthActions.LOGIN_REQUEST());
		const {username, password} = action.payload;
		const {data} = yield call(axios.post, `auth/login`, {username, password});
		const {access_token} = data;
		if (!access_token) {
			throw new Error('No access token');
		}
		yield call(storeAccessToken, access_token)

		const userRes = yield call(axios.get, `auth/user`);
		yield put(AuthActions.LOGIN_SUCCESS(userRes.data));
	} catch (err) {
		console.error(err)
		yield put(AuthActions.LOGIN_FAILURE(err));
	}
}

function* consolidateAuth() {
	try {
		const query = queryString.parse(window.location.search, {ignoreQueryPrefix: true})
		if (query.access_token) {
			yield call(storeAccessToken, query.access_token);
		}
		const accessToken = yield call(retrieveAccessToken);
		const user = parseAccessToken(accessToken)
		if (!user || (user.exp * 1000) < Date.now()) {
			yield call(logout, 'expired');
		} else {
			const userRes = yield call(axios.get, `auth/user`);
			yield put(AuthActions.LOGIN_SUCCESS(userRes.data));
		}
	} catch (err) {
		console.error(err)
		yield call(logout, 'expired');
	}
}

function* logoutOn401() {
	while (true) {
		const skippedActions = [AuthActionTypes.LOGIN_FAILURE]
		const action = yield take('*')
		if (skippedActions.includes(action.type)) {
			continue;
		}
		if (action.error && _.get(action.payload, 'response.status') === 401) {
			yield fork(logout, 'expired')
			yield delay(config.logoutThrottle)
		}
	}
}

function* updateUserSettings(action) {
	try {
		yield put(AuthActions.SUBMIT_USER_SETTINGS_FORM_REQUEST());
		
		const {payload} = action;

		const fields = _.pick(payload, ['name', 'selectedLevel']);

		const result = yield call(axios.patch, `auth/user/settings`, fields);

		yield put(AuthActions.SUBMIT_USER_SETTINGS_FORM_SUCCESS(result.data));
		
	} catch (err) {
		yield put(AuthActions.SUBMIT_USER_SETTINGS_FORM_FAILURE(err));
	}
}

const sagags = [
	takeLatest(AuthActionTypes.LOGIN_SUCCESS, monitorActivity),
	takeLatest(PagesActions.LOGIN_FORM_SUBMITTED, loginWithPassword),
	takeLatest(PagesActions.HEADER_LOGOUT_REQUESTED, logout, 'byHeader'),
	takeLatest(PagesActions.USER_SETTINGS_SUBMITTED, updateUserSettings),
	takeLatest('STORE_LOADED', consolidateAuth),
	takeLatest('STORE_LOADED', logoutOn401),
]
export default sagags;
