import { IconButton, Tooltip } from '@mui/material';

export default function TooltipButton({icon: Icon, title, iconProps, ...props}) {
	return (
		<Tooltip title={title}>
			<IconButton {...props}>
				<Icon fontSize="inherit" {...iconProps} />
			</IconButton>
		</Tooltip>
	)
};