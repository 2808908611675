import {useEffect} from 'react';
import {useLocation} from 'react-router';
import { usePrevious } from 'utils/hooks';

function ScrollToTop({children}) {
	const location = useLocation();
	const previousLocation = usePrevious(location)
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location, previousLocation])

	return children;
} 

export default ScrollToTop
