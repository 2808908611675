import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const allActions = [
	'SIDEBAR_LEVEL_CHANGED',
	'LOGIN_FORM_SUBMITTED',
	'HEADER_LOGOUT_REQUESTED',

	'LEVEL_CHAPTERS_ORDER_UPDATED',

	'SINGLE_CHAPTER_EDIT_MOUNTED',
	'SINGLE_CHAPTER_FORM_SUBMITTED',
	'SINGLE_CHAPTER_DELETE_SUBMITTED',
	'SINGLE_CHAPTER_CONFIRM_MODAL_OPENED',

	'USERS_FILTERING_CHANGED',
	'USER_CONFIRM_MODAL_OPENED',
	'USER_DELETE_SUBMITTED',
	'USER_RESET_PASSWORD_SUBMITTED',
	'SINGLE_USER_FORM_OPENED',
	'SINGLE_USER_FORM_SUBMITTED',

	'UNIT_PAGE_MOUNTED',

	'GOLDEN_QUESTIONS_ORDER_UPDATED',
	'GOLDEN_QUESTION_CONFIRM_MODAL_OPENED',
	'GOLDEN_QUESTION_DELETE_SUBMITTED',
	'SINGLE_GOLDEN_QUESTION_FORM_OPENED',
	'GOLDEN_QUESTION_SINGLE_FORM_SUBMITTED',

	'MC_QUESTIONS_ORDER_UPDATED',
	'MC_QUESTION_CONFIRM_MODAL_OPENED',
	'MC_QUESTION_DELETE_SUBMITTED',
	'SINGLE_MC_QUESTION_FORM_OPENED',
	'MC_QUESTION_SINGLE_FORM_SUBMITTED',

	'USER_SETTINGS_SUBMITTED',

	'SINGLE_STUDENT_CHAPTER_MOUNTED',

	'STUDENT_MULTI_CHOICE_EXERCISE_START',
	'STUDENT_GOLDEN_EXERCISE_START',
]

export const ActionTypes = createActionTypes(allActions);
export const Actions = createActionCreators(allActions);
