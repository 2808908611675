import _ from 'lodash';
import React, { useState } from "react";
import {Paper, Box, Typography, ButtonBase, Accordion, AccordionSummary, AccordionDetails, TextField, Button} from '@mui/material';
import {
	Email as EmailIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import emailBlocks from './emailHelperBlocks';

function HoverEmailButton({onClick, item, selected}) {
	const {icon: Icon, title} = item;

	const selectedStyle = selected ?
		{'& .MuiPaper-root': {bgcolor: 'primary.light', color: 'white'}} :
		{'&:hover .MuiPaper-root': {bgcolor: 'primary.lightest'}}
	return (
		<ButtonBase
			onClick={() => onClick(selected ? null : item)}
			sx={selectedStyle}
		>
			<Paper elevation={3} sx={{width: 180, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
				<Icon fontSize="large" />
				<Typography>{title}</Typography>
			</Paper>
		</ButtonBase>
	)
}

export default function EmailHelperPage() {
	const [selectedBlock, setSelectedBlock] = useState();
	const [emailTemplate, setEmailTemplate] = useState('');

	return (
		<>
			<PageTitle title="Email Helper" icon={EmailIcon} />
			<Accordion expanded>
				<AccordionSummary
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
				>
					<Typography variant='h4' fontWeight="bold">1. בחירת נושא</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{pt: 2}}>
					<Typography variant='h2' fontWeight="bold" textAlign="center" sx={{mt: 2, mb: 4}}>יש לבחור את הקטגוריה הנכונה על מנת להציג הצעות תואמות</Typography>
					<Box sx={{display: 'flex', flexFlow: 'row wrap', gap: 2, justifyContent: 'center'}}>
						{emailBlocks.map((item) => (
							<HoverEmailButton key={item.key} item={item} onClick={setSelectedBlock} selected={selectedBlock === item} />
						))}
					</Box>
		
				</AccordionDetails>
			</Accordion>
			<Accordion expanded={!!selectedBlock}>
				<AccordionSummary
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
				>
					<Typography variant='h4' fontWeight="bold">2. בחירת פורמט</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{pt: 2}}>
					<Typography variant='h2' fontWeight="bold" textAlign="center" sx={{mt: 2, mb: 4}}>יש לבחור משפט על מנת להוסיף אותו לתבנית המייל</Typography>

					<Box sx={{margin: 'auto', display: 'flex', flexFlow: 'row wrap', gap: 2, maxWidth: 1000, direction: 'rtl' /* flipped */}}>
						{selectedBlock?.options.map((item, i) => (
							<ButtonBase
								key={i}
								onClick={() => setEmailTemplate(t => `${t.trim()}\n${item}`.trim())}
								sx={{border: t => `1px solid ${t.palette.borderColor.light}`, p: 2, fontSize: 25, flex: `1 1 40%`}}
							>
								{item}
							</ButtonBase>
						))}
					</Box>
				</AccordionDetails>
			</Accordion>
			<Accordion expanded>
				<AccordionSummary
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
				>
					<Typography variant='h4' fontWeight="bold">3. עריכת אימייל</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{display: 'flex', justifyContent: 'center'}}>
					<Box sx={{pt: 2, maxWidth: 1000, flex: 1}}>
						<TextField
							name="emailTemplate"
							multiline
							label="עריכת אימייל"
							rows={10}
							fullWidth
							value={emailTemplate}
							InputProps={{sx: {direction: 'rtl'}}}
							onChange={e => setEmailTemplate(e.target.value)}
						/>
						<Box sx={{display: 'flex', gap: 2, mt: 2}}>
							<Button variant='contained' onClick={() => window.navigator.clipboard.writeText(emailTemplate)}>העתקה</Button>
							<Button variant='text' onClick={() => setEmailTemplate('')}>ניקוי</Button>
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>
		</>
	)
}
