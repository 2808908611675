// import { put, takeEvery, select, takeLatest, call } from 'redux-saga/effects';
// import { Actions as LevelActions } from './actions';
// import {ActionTypes as PagesActionTypes} from 'pages/actions';
// import axios from 'services/axios';

// import _ from 'lodash';

// async function fetchChaptersLogic() {
// const {pagination, filter, sortModel, id} = action.payload;
// const offset = pagination.pageSize * pagination.page;
// const payload = {query: {search: filter.text, feed: {advertiserId: id}}};
// const orderBy = sortModel.length ? `feed.${sortModel[0].field}` : '';
// const order = sortModel.length ? sortModel[0].sort : '';
// const url = `feeds/query?offset=${offset}&limit=${pagination.pageSize}&orderBy=${orderBy}&order=${order}`;
// const {data} = yield call(axios.post, url, payload);
// }



const sagas = [
	// takeLatest(PagesActionTypes.SIDEBAR_LEVEL_CHANGED, fetchLevelChapters),
]

export default sagas;
