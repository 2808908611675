import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
	MenuBook as MenuBookIcon,
	ExpandMore as ExpandMoreIcon,
	CloudDownload as CloudDownloadIcon,
	EmojiEvents as EmojiEventsIcon,
	DownhillSkiing as DownhillSkiingIcon,
} from '@mui/icons-material';
import {Accordion, AccordionSummary, Typography, AccordionDetails, Box, ButtonBase, Paper, useMediaQuery} from '@mui/material';
import {Link, useParams, useNavigate} from 'react-router-dom';

// components
import PageTitle from "../../components/PageTitle";
import {Actions} from 'pages/actions';
import * as ChaptersSelectors from 'modules/chapters/selectors';
import Loader from "components/Loader/Loader";
import FileLink from 'components/FileLink/FileLink';
import MultiChoiceExercise from 'pages/multiChoiceExercise/MultiChoiceExercise';
import { useTheme } from '@emotion/react';
import GoldenExercise from 'pages/goldenExercise/GoldenExercise';

function ChapterContent({chapter}) {
	const [isExpanded, setExpanded] = useState(true);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setExpanded(true);
	}, [chapter])

	if (!chapter) {return null;}

	const downloadables = [...chapter.downloads]
	chapter.vocabularyPDF && downloadables.unshift({id: 'vocabularyPDF', name: 'אוצר מילים', content: chapter.vocabularyPDF})
	chapter.summaryPDF && downloadables.unshift({id: 'summaryPDF', name: 'סיכום הפרק', content: chapter.summaryPDF})

	const mobileStyle = isMobile ? {'& iframe': {height: 'auto', maxWidth: "100%"}} : {}
	return (
		<Accordion expanded={isExpanded} onChange={(_, expaned) => setExpanded(expaned)}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
			>
				<Typography variant='h4' fontWeight="bold" sx={{display: 'flex', alignItems: 'center'}}>שם ותוכן הפרק</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 2, display: 'flex', flexDirection: 'column', pb: 6}}>
				<Box dangerouslySetInnerHTML={{__html: chapter.content}} sx={mobileStyle} />
				<Box sx={{display: 'flex', gap: 2, mt: 4, justifyContent: 'center', flexWrap: "wrap"}}>
					<ExerciseButton title="1. תרגול" link="multiExercise" icon={<DownhillSkiingIcon sx={{fontSize: 90}} color="primary" />} />
					<ExerciseButton title="2. תרגיל הזהב" link="goldenExercise" icon={<EmojiEventsIcon sx={{fontSize: 90, color: 'golden.main'}} />} />
				</Box>
				<Box sx={{display: 'inline-flex', flexDirection: 'column', gap: 4, mt: 4, alignSelf: 'center'}}>
					{!!downloadables.length && (
						<>
							<Typography variant='h1' sx={{display: 'inline-flex', alignItems: 'center', mt: 4, gap: 2}}><CloudDownloadIcon color="primary" fontSize='big' />קבצים להורדה</Typography>
							{downloadables.map(d => (
								<FileLink
									key={d.id}
									link={d.content}
									name={d.name}
									sx={{width: 'auth'}}
								/>
							))}
						</>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	)
}

function ExerciseButton({title, icon, link}) {
	return (
		<ButtonBase component={Link} to={link} sx={{minWidth: {xs: '100%', md: 360}}}>
			<Paper sx={{px: 4, py: {xs: 6, md: 10}, textAlign: 'center', width: '100%'}} elevation={6}>
				<Box>
					{icon}
				</Box>
				<Typography variant='h1' sx={{mt: 2, fontSize: {xs: 20, md: 50}}}>{title}</Typography>
			</Paper>
		</ButtonBase>
	)
}
export default function StudentChapterPage() {
	const selectedChapter = useSelector(ChaptersSelectors.selectedChapter)
	const {loading} = useSelector(ChaptersSelectors.loaders);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const routeParams = useParams()
	const {chapterId} = routeParams;

	useEffect(() => {
		dispatch(Actions.SINGLE_STUDENT_CHAPTER_MOUNTED({id: chapterId}));
	}, [chapterId, dispatch])

	const title = _.get(selectedChapter, 'name', '');

	function handleNavigationModalClose() {
		navigate('./')
	}
	return (
		<>
			<PageTitle title={title} icon={MenuBookIcon} />
			<Loader inline loading={loading} sx={{height: 'auto'}}>
				<ChapterContent chapter={selectedChapter} />
				<MultiChoiceExercise open={routeParams['*'] === 'multiExercise'} onClose={handleNavigationModalClose} />
				<GoldenExercise open={routeParams['*'] === 'goldenExercise'} onClose={handleNavigationModalClose} />
			</Loader>
		</>
	)
}
