import React from 'react';
import {
	Button, Dialog, DialogActions, DialogContent,
	DialogContentText, DialogTitle, Alert,
} from '@mui/material';

import styles from './styles';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import { useCloseOnSubmit } from 'utils/hooks';

export default function ConfirmDialog({open, variant = 'primary', onClose, loading, title, subtitle, customContent, onConfirm, onCancel, confirmText, cancelText = 'ביטול', alert, justSubmittedSelector}) {
	const isError = variant === 'error';
	const titleStyle = {...styles.dialogTitle, ...(isError ? styles.bgError : {})}
	
	useCloseOnSubmit(justSubmittedSelector, open, onCancel);
	
	function handleClose(event, reason) {
		if (reason === "backdropClick") {return;}
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="cofirm-dialog-title"
			disableEscapeKeyDown={loading}>
			<DialogTitle id="cofirm-dialog-title" sx={titleStyle}>{title}</DialogTitle>
			<DialogContent sx={styles.dialogContent}>
				{subtitle && <DialogContentText id="cofirm-dialog-description">
					{subtitle}
				</DialogContentText>}
				{customContent}
				{alert && <Alert sx={{mt: 2}} severity="error">{alert}</Alert>}
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} autoFocus color={isError ? 'error' : 'primary'}>
					{cancelText}
				</Button>
				<ProgressButton
					type="submit"
					variant="contained"
					color={isError ? 'error' : 'primary'}
					autoFocus
					sxWrap={{alignSelf: 'flex-start'}}
					onClick={onConfirm}
					loading={loading}
				>
					{confirmText}
				</ProgressButton>
			</DialogActions>
		</Dialog>
	);
}