import _ from 'lodash';
import { setLoader } from 'utils/reduxUtils';
import {ActionTypes} from './actions';

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	case 'noMatchUserPass':
		return 'שם המשתמש והסיסמה אינם תואמים';
	default:
		return 'אופס. הבקשה נכשלה';
	}
}

const initialState = {
	loaders: {},
	submitError: false,
	authComplete: false,
	isLoggedIn: false,
	user: null,
	userRoles: null,

};

const authReducer = (state = initialState, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.LOGIN_REQUEST:
		nextState = {...state, ...setLoader(state, 'loading', true), submitError: false};
		break;
	case ActionTypes.LOGIN_SUCCESS:
		nextState = {...state, user: payload, isLoggedIn: true, ...setLoader(state, 'loading', false), authComplete: true};
		break;
	case ActionTypes.LOGOUT_SUCCESS:
		nextState = {...initialState, authComplete: true};
		break;
	case ActionTypes.LOGIN_FAILURE:
		nextState = {...state, isLoggedIn: false, ...setLoader(state, 'loading', false), submitError: formatError(payload)};
		break;
	case ActionTypes.GET_USER_ROLES_REQUEST:
		nextState = {...state, ...setLoader(state, 'loading', true), submitError: false};
		break;
	case ActionTypes.GET_USER_ROLES_SUCCESS:
		nextState = {...state, userRoles: payload, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.GET_USER_ROLES_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false), submitError: formatError(payload)};
		break;
	case ActionTypes.SUBMIT_USER_SETTINGS_FORM_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: false};
		break;
	case ActionTypes.SUBMIT_USER_SETTINGS_FORM_SUCCESS:
		nextState = {...state, user: payload, ...setLoader(state, 'submitting', false), justSubmitted: true};
		break;
	case ActionTypes.SUBMIT_USER_SETTINGS_FORM_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default authReducer;
