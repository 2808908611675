import PropTypes from 'prop-types';
import * as React from 'react';
import DropzoneAreaBase from './DropzoneAreaBase';

const splitDropzoneAreaProps = (props) => {
	const {clearOnUnmount, initialFiles, onChange, onDelete, ...dropzoneAreaProps} = props;
	return [{clearOnUnmount, initialFiles, onChange, onDelete}, dropzoneAreaProps];
};

/**
 * This components creates an uncontrolled Material-UI Dropzone, with previews and snackbar notifications.
 *
 * It supports all props of `DropzoneAreaBase` but keeps the files state internally.
 *
 * **Note** To listen to file changes use `onChange` event handler and notice that `onDelete` returns a `File` instance instead of `FileObject`.
 */
export default class SingleFileDropzoneArea extends React.PureComponent {
	state = {
		value: null,
	}

	addFiles = async(newFileObjects) => {
		const base64 = newFileObjects[0].data;
		this.props.onChange(base64);
	}

	deleteFile = (removedFileObj, removedFileObjIdx, event) => {
		event.stopPropagation();

		const {onDelete} = this.props;
		const {value} = this.state;

		// Calculate remaining fileObjects array
		const remainingFileObjs = value.filter((fileObject, i) => {
			return i !== removedFileObjIdx;
		});

		// Notify removed file
		if (onDelete) {
			onDelete(removedFileObj.file);
		}

		// Update local state
		this.props.onChange(remainingFileObjs);
	}

	render() {
		const [, dropzoneAreaProps] = splitDropzoneAreaProps(this.props);
		const {value} = this.props;

		return (
			<DropzoneAreaBase
				{...dropzoneAreaProps}
				value={value ? [value] : []}
				onAdd={this.addFiles}
				onDelete={this.deleteFile}
			/>
		);
	}
}

SingleFileDropzoneArea.defaultProps = {
	clearOnUnmount: true,
};

SingleFileDropzoneArea.propTypes = {
	...DropzoneAreaBase.propTypes,
	value: PropTypes.string,
	/** Clear uploaded files when component is unmounted. */
	clearOnUnmount: PropTypes.bool,
	/** List containing File objects or URL strings.<br/>
     * **Note:** Please take care of CORS.
    */
	/**
     * Fired when the files inside dropzone change.
     *
     * @param {File[]} loadedFiles All the files currently loaded into the dropzone.
     */
	onChange: PropTypes.func,
	/**
     * Fired when a file is deleted from the previews panel.
     *
     * @param {File} deletedFile The file that was removed.
     */
	onDelete: PropTypes.func,
};
