import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Box, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, IconButton, TableContainer, Table, TableRow, TableCell, TableBody, Button, TableHead, Dialog, DialogTitle, DialogContent, Alert, DialogActions, FormControlLabel} from '@mui/material';
import {
	ExpandMore as ExpandMoreIcon,
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Reorder as ReorderIcon,
	Quiz as QuizIcon,
	CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import Yup from 'services/yup';

// components
import Loader from "components/Loader/Loader";
import {Actions} from 'pages/actions';
import * as QuestionsSelectors from 'modules/questions/selectors';

import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import DraggableList from 'components/DraggableList/DraggableList';
import { Field, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useCloseOnSubmit, usePrevious } from 'utils/hooks';

const mcQuestionScheme = Yup.object().shape({
	title: Yup.string().required(),
	answers: Yup.array().of(
		Yup.object().shape({
		  title: Yup.string().required(),
		  isCorrect: Yup.bool().required(),
		})
	)
		.minOccurences('isCorrect', true, 1, 'יש לבחור לפחות תשובה נכונה אחת'),
	// .compact((v) => !v.isCorrect).length(1, 'צריך בדיוק תשובה נכונה אחת'),
})
const defaultAnswer = {title: '', isCorrect: false};
const initalValues = {title: '', answers: [
	{...defaultAnswer, isCorrect: true}, {...defaultAnswer}, {...defaultAnswer},
]}


function CreateEditMCQuestion({open = false, onClose, mcQuestion, chapter}) {
	const {loading, submitting} = useSelector(QuestionsSelectors.loaders)
	const justSubmitted = useSelector(QuestionsSelectors.justSubmitted)
	const previouslySubmitted = usePrevious(justSubmitted);
	const channelError = useSelector(QuestionsSelectors.submitError)
	const dispatch = useDispatch();
    
	const fieldsDisabled = loading || submitting
	const submitError = channelError

	useCloseOnSubmit(QuestionsSelectors.justSubmitted, open, onClose);

	useEffect(() => {
		if (open) {
			dispatch(Actions.SINGLE_MC_QUESTION_FORM_OPENED());
		}
	}, [open, dispatch])

	useEffect(() => {
		if (open && !previouslySubmitted && justSubmitted) {
			onClose();
		}
	}, [previouslySubmitted, justSubmitted, onClose, open])
      
	function handleFormSubmit(values) {
		dispatch(Actions.MC_QUESTION_SINGLE_FORM_SUBMITTED({...values, chapterId: chapter.id}));
	}

	const title = mcQuestion ? `עדכון שאלה אמריקאית - ${mcQuestion.title || ''}` : "יצירת שאלה אמריקאית";
	return (
		<Dialog open={open} onClose={onClose} fullWidth onBackdropClick={() => false}>
			<DialogTitle sx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}>
				{title}
			</DialogTitle>
			<Formik
				initialValues={_.defaults(mcQuestion, initalValues)}
				enableReinitialize
				validationSchema={mcQuestionScheme}
				submitting={fieldsDisabled}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm, setFieldValue, errors, values }) => (
					<>
						<DialogContent dividers>
							<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
								<PromptIfDirty />
								<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
									<Field
										component={TextField}
										name="title"
										type="text"
										label="שאלה"
										fullWidth
										multiline
										maxRows={3}
										disabled={fieldsDisabled}
									/>
									<TooltipButton
										title="הוספת שאלה"
										icon={AddIcon}
										size="large"
										color="primary"
										onClick={() => setFieldValue('answers', [...values.answers, {...defaultAnswer}])}
									/>
								</Box>
								{values.answers.map((answer, index) => (
									<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
										<TooltipButton
											title="מחיקת שאלה"
											icon={DeleteIcon}
											size="large"
											color="error"
											onClick={() => setFieldValue('answers', values.answers.filter(a => a !== answer))}
											sx={{p: 1}}
										/>
										<Field
											component={TextField}
											name={`answers.${index}.title`}
											type="text"
											label={`תשובה ${index + 1}`}
											fullWidth
											disabled={fieldsDisabled}
											inputProps={{sx: {unicodeBidi: 'plaintext'}}}
										/>
										<FormControlLabel
											control={
												<Field
													component={Switch}
													name={`answers.${index}.isCorrect`}
													disabled={loading}
													type="checkbox"
													onChange={(_e, value) => {
														setFieldValue(`answers.${index}.isCorrect`, value)
													}}
												/>
											}
											sx={{mx: 0}}
											label="נכונה"
											labelPlacement='top'
										/>
									</Box>
								))
								}

								{_.isString(errors.answers) && <Alert severity="error">{errors.answers}</Alert>}
								{submitError && <Alert sx={{mt: 2}} severity="error">{submitError}</Alert>}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose}>ביטול</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								disabled={fieldsDisabled}
								loading={submitting}
							>
								שמירה
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</Dialog>
	)
}

function ActionsColumn({row, onEditClick, onDeleteClick}) {
	return (
		<>
			<Tooltip arrow title="עריכה">
				<IconButton
					aria-label="עריכה"
					sx={{p: 1}}
					onClick={() => onEditClick(row)}
					size="large">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Tooltip arrow title="מחיקה">
				<IconButton
					aria-label="מחיקה"
					sx={{p: 1}}
					onClick={() => onDeleteClick(row)}
					color="error"
					size="large">
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const mcQuestionsCells = [
	{id: 'title', numeric: false, label: 'שאלה'},
	{id: 'correctAnswers', numeric: false, label: 'תשובות נכונות'},
	{id: 'numAnswers', numeric: true, label: '# תשובות', width: 120 },
	{id: 'actions', numeric: false, disablePadding: false, label: 'פעולות', width: 120 },
];

export default function MultipleChoiceSection({chapter, defaultExpanded}) {
	const [isExpanded, setExpanded] = useState(defaultExpanded);
	const mcQuestions = useSelector(QuestionsSelectors.mcQuestions)
	const submitError = useSelector(QuestionsSelectors.submitError)
	const {loading, submitting} = useSelector(QuestionsSelectors.loaders)
	const [confirmation, setConfirmation] = useState({open: false});
	const [questionModal, setQuestionModal] = useState({question: null, open: false});
	const [dragEnabled, setDragEnabled] = useState(false);
	const [orderedQuestions, setOrderedQuestions] = useState(mcQuestions);
	const dispatch = useDispatch();

	useEffect(() => {
		setOrderedQuestions(mcQuestions)
		setDragEnabled(false)
	}, [mcQuestions, setOrderedQuestions])

	function handleDeleteConfirm(mcQuestion) {
		dispatch(Actions.MC_QUESTION_DELETE_SUBMITTED(mcQuestion));
	}

	function cancelDrag() {
		setDragEnabled(false);
		setOrderedQuestions(mcQuestions)
	}

	function openConfirmDelete(mcQuestion) {
		dispatch(Actions.MC_QUESTION_CONFIRM_MODAL_OPENED(mcQuestion));
		setConfirmation({
			open: true,
			title: `מחיקה - ${mcQuestion.title}`,
			subtitle: 'האם למחוק שאלה אמריקאית זו?\nשאלה זו תמחק והסטודנטים לא יוכלו לראות אותה יותר',
			confirmText: 'מחיקה',
			variant: 'error',
			onConfirm: () => handleDeleteConfirm(mcQuestion),
			onCancel: () => setConfirmation({open: false}),
		});
	}

	function handleOrderUpdate() {
		const newOrder = orderedQuestions.map(c => c.id);
		dispatch(Actions.MC_QUESTIONS_ORDER_UPDATED({chapterId: chapter.id, questions: newOrder}));
	}

	function handleModalClose() {
		setQuestionModal({open: false, question: null});
	}
	return (

		<Accordion expanded={isExpanded} onChange={(_, expaned) => setExpanded(expaned)}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{'& .MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'}, bgcolor: 'primary.lightest'}}
			>
				<Typography variant='h4' fontWeight="bold">תרגול - שאלות אמריקאיות</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 2}}>
				<Loader inline loading={loading || submitting}>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography variant='h2' gutterBottom sx={{color: 'text.hint', display: 'flex', alignItems: 'center', gap: 1}}><QuizIcon fontSize='medium' /> שאלות אמריקאיות</Typography>
						<Box sx={{display: 'flex'}}>
							<TooltipButton
								title="ארגון שאלות"
								icon={ReorderIcon}
								size="large"
								onClick={() => dragEnabled ? cancelDrag() : setDragEnabled(true)}
								sx={{p: 1}}
							/>
							<TooltipButton
								title="הוספת שאלה חדשה"
								icon={AddIcon}
								iconProps={{color: 'primary'}}
								size="large"
								onClick={() => setQuestionModal({question: null, open: true})}
								sx={{p: 1}}
							/>
						</Box>
					</Box>
					<TableContainer sx={{maxHeight: 600, minHeight: 200}}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow hover>
									<TableCell padding="none" width={35}></TableCell>
									{mcQuestionsCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											width={headCell.width}
											align="left"
											padding={headCell.disablePadding ? 'none' : 'normal'}
										>
											{headCell.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<DraggableList
								listComponent={TableBody}
								listSX={{display: 'table-row-group'}}
								itemSX={{display: 'table-row'}}
								listItemComponent={TableRow}
								listItemProps={{hover: true}}
								items={orderedQuestions}
								onChange={setOrderedQuestions}
								dragEnabled={dragEnabled}
								renderItemContent={(mcQuestion, drag) => (
									<>
										<TableCell padding="none">
											{drag}
										</TableCell>
										<TableCell sx={{unicodeBidi: 'plaintext'}}>
											{mcQuestion.title}
										</TableCell>
										<TableCell sx={{unicodeBidi: 'plaintext'}}>
											{mcQuestion.answers.filter(a => a.isCorrect).map(a => a.title).join(' | ')}
										</TableCell>
										<TableCell>
											{mcQuestion.answers.length}
										</TableCell>
										<TableCell align="right">
											<ActionsColumn
												row={mcQuestion}
												onDeleteClick={openConfirmDelete}
												onEditClick={(row) => setQuestionModal({question: row, open: true})}
											/>
										</TableCell>
									</>
								)}
							/>
						</Table>
					</TableContainer>
					{dragEnabled && <Box sx={{mt: 2, display: 'flex', gap: 2, justifyContent: 'flex-end'}}>
						<Button onClick={cancelDrag}>ביטול</Button>
						<ProgressButton variant="contained" color="primary" onClick={handleOrderUpdate}>שמירת שינויים</ProgressButton>
					</Box>}
				</Loader>
				<ConfirmDialog
					{...confirmation}
					loading={submitting}
					alert={submitError}
					justSubmittedSelector={QuestionsSelectors.justSubmitted}
				/>
				<CreateEditMCQuestion open={questionModal.open} mcQuestion={questionModal.question} chapter={chapter} onClose={handleModalClose} />
			</AccordionDetails>
		</Accordion>
	)
}
