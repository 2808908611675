import { addHours, getHours, getMinutes, getSeconds, format as formatDate } from 'date-fns';
import _ from 'lodash';

export function dateStringToISODate(dateString) {
	const date = new Date(dateString)
	if (!date) {
		return null;
	}
	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
	return new Date(date.getTime() - userTimezoneOffset).toISOString();
}

export function dateToDateTimeString(date) {
	return formatDate(new Date(date), 'yyyy-MM-dd, HH:mm:ss')
}

export function joinByAnds(strArray) {
	const filtered = strArray.filter(_.identity)
	const last = filtered.slice(-1);
	const notLast = filtered.slice(0, -1);
	
	if (filtered.length <= 1) {
		return filtered[0];
	}
	const notJoinedStr = notLast.join(', ')
	return `${notJoinedStr} and ${last}`;
}

export function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}


export const convertToDuration = (milliseconds) => {
	const normalizeTime = (time) =>
		time.length === 1 ? `0${time}` : time;

	const MINUTES_IN_HOUR = 60;


	const date = new Date(milliseconds);
	const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
	const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

	const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
	const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
	const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

	return `${hours}:${minutes}:${seconds}`;
};