import _ from 'lodash';
import { put, takeLatest, call } from 'redux-saga/effects';
import { Actions } from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';
import axios from 'services/axios';

// import _ from 'lodash';


function* fetchChapterGoldenQuestions(action) {
	const {id} = action.payload;
	if (!id) {return;}

	yield put(Actions.FETCH_GOLDEN_QUESTIONS_REQUEST());
	try {
		const url = `chapters/${id}/goldenQuestions/query?offset=0&limit=-1&orderBy=goldenQuestion.order&order=asc`;
		const {data} = yield call(axios.post, url, {});
		yield put(Actions.FETCH_GOLDEN_QUESTIONS_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_GOLDEN_QUESTIONS_FAILURE(err));
	}
}

function* fetchChapterMCQuestions(action) {
	const {id} = action.payload;
	if (!id) {return;}

	yield put(Actions.FETCH_MC_QUESTIONS_REQUEST());
	try {
		const url = `chapters/${id}/mcQuestions/query?offset=0&limit=-1&orderBy=mcQuestion.order&order=asc`;
		const {data} = yield call(axios.post, url, {});
		yield put(Actions.FETCH_MC_QUESTIONS_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.FETCH_MC_QUESTIONS_FAILURE(err));
	}
}

function* reorderGoldenQuestions(action) {
	yield put(Actions.UPDATE_GOLDEN_QUESTIONS_ORDER_REQUEST());
	try {
		const url = `chapters/${action.payload.chapterId}/goldenQuestions/reorder`;
		const {data} = yield call(axios.post, url, action.payload.questions);
		yield put(Actions.UPDATE_GOLDEN_QUESTIONS_ORDER_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.UPDATE_GOLDEN_QUESTIONS_ORDER_FAILURE(err));
	}
}

function* reorderMCQuestions(action) {
	yield put(Actions.UPDATE_MC_QUESTIONS_ORDER_REQUEST());
	try {
		const url = `chapters/${action.payload.chapterId}/mcQuestions/reorder`;
		const {data} = yield call(axios.post, url, action.payload.questions);
		yield put(Actions.UPDATE_MC_QUESTIONS_ORDER_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(Actions.UPDATE_MC_QUESTIONS_ORDER_FAILURE(err));
	}
}

function* deleteGoldenQuestion(action) {
	const {id} = action.payload;
	if (!id) {
		return;
	}
	yield put(Actions.DELETE_GOLDEN_QUESTION_REQUEST());
	try {
		const {data} = yield call(axios.delete, `goldenQuestions/${id}`);
        
		yield put(Actions.DELETE_GOLDEN_QUESTION_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.DELETE_GOLDEN_QUESTION_FAILURE(err));
	}
}

function* deleteMCQuestion(action) {
	const {id} = action.payload;
	if (!id) {
		return;
	}
	yield put(Actions.DELETE_MC_QUESTION_REQUEST());
	try {
		const {data} = yield call(axios.delete, `mcQuestions/${id}`);
        
		yield put(Actions.DELETE_MC_QUESTION_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(Actions.DELETE_MC_QUESTION_FAILURE(err));
	}
}

function* submitGoldenQuestionForm(action) {
	try {
		yield put(Actions.SUBMIT_GOLDEN_QUESTION_FORM_REQUEST());
		
		const {chapterId, id: questionId, ...question} = action.payload;

		const fields = _.pick(question, ['title', 'answer', 'answerAudio']);

		const method = questionId ? axios.patch : axios.post;
		const url = questionId ? `goldenQuestions/${questionId}` : `chapters/${chapterId}/goldenQuestions`;
		const result = yield call(method, url, fields);

		yield put(Actions.SUBMIT_GOLDEN_QUESTION_FORM_SUCCESS(result.data));
		
	} catch (err) {
		yield put(Actions.SUBMIT_GOLDEN_QUESTION_FORM_FAILURE(err));
	}
}

function* submitMCQuestionForm(action) {
	try {
		yield put(Actions.SUBMIT_MC_QUESTION_FORM_REQUEST());
		
		const {chapterId, id: questionId, ...question} = action.payload;

		const fields = _.pick(question, ['title', 'answers']);

		const method = questionId ? axios.patch : axios.post;
		const url = questionId ? `mcQuestions/${questionId}` : `chapters/${chapterId}/mcQuestions`;
		const result = yield call(method, url, fields);

		yield put(Actions.SUBMIT_MC_QUESTION_FORM_SUCCESS(result.data));
		
	} catch (err) {
		yield put(Actions.SUBMIT_MC_QUESTION_FORM_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActionTypes.SINGLE_CHAPTER_EDIT_MOUNTED, fetchChapterGoldenQuestions),
	takeLatest(PagesActionTypes.STUDENT_GOLDEN_EXERCISE_START, fetchChapterGoldenQuestions),
	takeLatest(PagesActionTypes.SINGLE_CHAPTER_EDIT_MOUNTED, fetchChapterMCQuestions),
	takeLatest(PagesActionTypes.STUDENT_MULTI_CHOICE_EXERCISE_START, fetchChapterMCQuestions),
	// golden
	takeLatest(PagesActionTypes.GOLDEN_QUESTIONS_ORDER_UPDATED, reorderGoldenQuestions),
	takeLatest(PagesActionTypes.GOLDEN_QUESTION_DELETE_SUBMITTED, deleteGoldenQuestion),
	takeLatest(PagesActionTypes.GOLDEN_QUESTION_SINGLE_FORM_SUBMITTED, submitGoldenQuestionForm),
	// mc
	takeLatest(PagesActionTypes.MC_QUESTIONS_ORDER_UPDATED, reorderMCQuestions),
	takeLatest(PagesActionTypes.MC_QUESTION_DELETE_SUBMITTED, deleteMCQuestion),
	takeLatest(PagesActionTypes.MC_QUESTION_SINGLE_FORM_SUBMITTED, submitMCQuestionForm),
]

export default sagas;
