import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {Box} from '@mui/material';

// styles
import style from "./styles";

// components
import AdminHeader from "components/Header/AdminHeader";
import AdminSidebar from "components/Sidebar/AdminSidebar";

// pages
import Error from "pages/error";
import LevelsPage from "pages/levels";
import SingleLevelPage from "pages/singleLevel";
import SingleChapterPage from "pages/singleChapter";
import UsersPage from "pages/users";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout() {
	// global
	var layoutState = useLayoutState();
	return (
		<Box sx={style.root}>
			<AdminHeader kind="admin" />
			<AdminSidebar />
			<Box
				sx={{
					...style.content,
					...(layoutState.isSidebarOpened ? style.contentShift : {}),
				}}
			>
				<Box sx={style.fakeToolbar} />
				<Routes>
					<Route
						exact
						path=""
						element={<Navigate to="admin" />}
					/>
					<Route path="admin" element={<LevelsPage />} />
					<Route path="admin/level/:level" element={<SingleLevelPage />} />
					<Route path="admin/level/:level/chapters/new" element={<SingleChapterPage isNew />} />
					<Route path="admin/level/:level/chapters/:chapterId" element={<SingleChapterPage />} />
					<Route path="admin/users" element={<UsersPage />} />
					<Route path="admin/*" element={<Error />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default Layout;
