export const presentationSteps = [{
	title: 'איך להתכונן לפרזנטציה שלכם באנגלית',
	text: `
מתן פרזנטציה הוא דבר שגורם לרבים מאיתנו תחושת חרדה - גם כשמדברים בשפת האם שלנו. עם זאת, חרדה זו יכולה להתגבר כשאתם צריכים להציג בשפה שאינה שפת האם שלכם. זה יכול להרגיש מאיים וכמעט בלתי אפשרי בין אם אתם מציגים פרזנטציה על החברה שלכם או מציגים הצעה ללקוח חדש. ללא קשר לסיטואציה, זה טבעי לחלוטין להרגיש מתוחים לקראת הצגת פרזנטציה באנגלית

יש הרבה סיבות לכך שתרגישו חרדה בגלל הצורך להציג פרזנטציה באנגלית. קודם כל, אתם עלולים להיות מודאגים משימוש במילה לא נכונה או לקבל בלאקאאוט ולשכוח מה רציתם להגיד. בנוסף, אתם עלולים לדאוג שהקהל שלכם לא יבין לגמרי את מה שאתם אומרים. ולסיום, זה טבעי להרגיש מודאגים מזה שהקהל שלכם אולי ישאל אתכם שאלות שלא תדעו איך לענות עליהן באנגלית. בלי קשר לסיבה שבגללה אתם דואגים, יש כמה דרכים בהן תוכלו לפעול כדי להתכונן לפרזנטציה שלכם באנגלית

היום נציג בפניכם 7 מהדרכים הטובות ביותר שבעזרתן תוכלו להתכונן לפרזנטציה שלכם באנגלית. למרות כל הדאגות שציינו, הצגת פרזנטציה באנגלית בביטחון היא אפשרית לחלוטין. עם קצת מאמץ תוכלו להיות מוכנים טוב יותר לפרזנטציה שלכם! בואו נתחיל

<strong>לדעת מה אתם רוצים להשיג עם הקהל שלכם</strong>

אפילו שאולי זה נראה מובן מאליו שתכנון הוא חלק חשוב מההכנה לקראת הצגת פרזנטציה באנגלית, רבים מאיתנו שוכחים עד כמה חשוב הוא תהליך התכנון. לפני שאתם מתיישבים לכתוב את מה שאתם רוצים לומר, הקדישו קצת זמן לחשוב על מה אתם רוצים לדבר

יש שתי שאלות, שחשוב במיוחד שתשאלו את עצמכם במהלך התכנון. אחת היא איפה נמצא הקהל שלכם מבחינה חשיבתית לפני הפרזנטציה שלכם, והשנייה היא איפה אתם רוצים שהוא יהיה אחרי הפרזנטציה. כל פרזנטציה ניתנת תוך רצון להעביר איזושהי תחושה, רעיון או להתניע פעולה בקרב הקהל שלכם. החליטו קודם כל עם מה אתם רוצים שהקהל שלכם ייצא מהפרזנטציה

ברגע שתשאלו את שתי השאלות החשובות האלה, יהיה לכם קל יותר לתכנן בדיוק מה תרצו להגיד במהלך הפרזנטציה. התמקדו במה שאתם רוצים שהקהל שלכם יקח מהפרזנטציה שלכם. מה אתם רוצים שהקהל שלכם ילמד? מה אתם רוצים שהקהל שלכם ירגיש? איך אתם יכולים לתכנן את הפרזנטציה שלכם בצורה שתשיג את המטרות האלה

בתכנון הפרזנטציה שלכם, חשוב לזכור שיכולים להיות לקהל שלכם דעות מסויימות או דעות קדומות בנושא שעליו אתם מדברים בפרזנטציה. השאיפה שלכם צריכה להתמקד בלהעביר את הקהל שלכם מצורת חשיבה אחת לצורת חשיבה אחרת

<strong>להכיר את הקהל שלכם ואת מטרת הפרזנטציה</strong>

כדי להעביר את הקהל בהצלחה מצורת חשיבה אחת לאחרת באמצעות הפרזנטציה, חשוב שיהיה לכם מושג מיהו הקהל שלכם ומה המטרה של הפרזנטציה שלכם. שני האלמנטים האלה ממלאים תפקיד מרכזי בתהליך ההכנה של הפרזנטציה שלכם באנגלית

שאלו את עצמכם לאיזה סוג אנשים אתם מציגים את הפרזנטציה. פרט זה חשוב כי הוא יכתיב איך אתם יכולים להציע להם את המידע הנכון בשפה הנכונה. הוא יעזור לכם גם לבחור את האביזרים החזותיים הנכונים לפרזנטציה שלכם. בהתאם למיהו הקהל שלכם, סביר להניח שתוכלו להגדיר מה מעניין אותו, מה חשוב לו, ובסופו של דבר כיצד לתכנן את הפרזנטציה שלכם כדי שהיא תוכל לעורר את התגובה שאתם מעוניינים לעורר בצורה הטובה ביותר

כשאתם חושבים על מי הקהל שלכם ומה מטרת הפרזנטציה שלכם, תזכרו לשאול את עצמכם שאלה חשובה: האם הקהל הספציפי לו אתם מציגים רגיל למונחים טכניים מסוימים או שאתם צריכים לפשט את השפה ולהשתמש במונחים פשוטים יותר כדי שיבינו על מה אתם מדברים? חשוב שתדעו באיזה מידה הקהל שלכם יכול להבין מונחים טכניים מסויימים מכיוון שזה יכול להשפיע מאוד על מידת ההבנה של התוכן שאתם מציגים

כשאתם חושבים על מטרת הפרזנטציה ובזמן שאתם מכינים אותה, תגדירו את התוצאה שתרצו להשיג. לרוב, פרזנטציות הן כלי להניע את הקהל שלכם לבצע פעולה מסוימת או ליידע את הקהל על נושא מסוים

ברגע שתבינו מה אתם רוצים שהפרזנטציה שלכם תשיג, יהיה לכם הרבה יותר קל להשתמש בשפה הנכונה ובסגנון הנכון. הבנת המטרה תעזור לכם בארגון הפרזנטציה

<strong>לא רק לספר - להראות!</strong>

בשפה האנגלית, השימוש בתמונות ובעזרים חזותיים אחרים נחשב לאחד האמצעים הטובים ביותר להצגת מידע בפני קהל. אם יצא לכם בעבר להקשיב לפרזנטציה שכללה כמות גדולה של מידע, נתונים סטטיסטיים, נתונים ומספרים, סביר להניח שאתם לא זוכרים אף אחד מהנתונים האלה היום. אם תארגנו את הפרזנטציה שלכם בצורה שמציגה את המידע באופן חזותי לעומת דיבור בלבד, סביר להניח שהקהל שלכם יצליח לזכור את המידע שהצגתם לו

 “show, don't tell”  באנגלית, משמעות הביטוי
תראו, אל תספרו") הוא לעזור לקהל שלכם")
להבין את הנקודות העיקריות שלכם באמצעות
 הדמיה, סיפורים ותמונות. אחרי הכל, לאנשים הרבה יותר קל לזכור מידע כשהוא מוצג באמצעות סיפורים ולא דרך מספרים. השתמשו בתמונות כדי להסביר את הרעיונות שלכם ובעזרים חזותיים שתומכים ברעיונות שלכם

<strong>לדעת איך לארגן את הפרזנטציה שלכם</strong>

כשאתם מתכוננים לפרזנטציה שלכם באנגלית, אל תמעיטו בחשיבות הארגון! ארגון הפרזנטציה שלכם היא הדרך הטובה ביותר להרגיש מוכנים לרגע שבו תצטרכו להציג אותה. קודם כל, חשוב שתבינו את שלושת החלקים של הפרזנטציה באנגלית. החלקים הם: פתיחה או הקדמה, הגוף בו תציגו את הנקודות העיקריות, והמסקנה בה תסכמו כל אחת מנקודות הפרזנטציה שלכם

כשאתם מארגנים את גוף הפרזנטציה, מומלץ מאוד לוודא שיש לכם בין שלוש לחמש נקודות עיקריות. ככה תוכלו להבטיח שבסוף הפרזנטציה הקהל שלכם ירגיש שהוא השכיל מבלי להרגיש מוצף מכמות מידע גדולה מדי שיכולה להקשות עליו לעקוב אחריכם. בסוף הפרזנטציה תוכלו להציג את הנקודות העיקריות האלה מחדש כתזכורת ורענון

<strong>לשמור על צורת דיבור טבעית</strong>

למרות שאתם עשויים לחוות רמת מתח גבוהה, חשוב שלא תקריאו את הפרזנטציה שלכם. עליכם להציג את הפרזנטציה בצורה טבעית והקהל צריך להרגיש שאתם מדברים אליו בצורה ישירה, ושאתם לא רק קוראים מצגת שקופיות. אחרת, מעבר לכך שסביר להניח שהקהל יחשוב שהפרזנטציה שלכם מאוד משעממת, הוא גם יכול לקבל את הרושם שלא התכוננתם מספיק

במקום פשוט לקרוא את כל השקופיות, העזרו בכרטיסיות כדי להקל עליכם לזכור ולהישאר במסגרת הנושא במהלך הפרזנטציה. למרות שכרטיסיות יכולות לעזור לכם להישאר מסודרים ולא לחרוג מהנושא, עדיף לא להסתמך עליהם. הציגו את הנקודות שלכם בצורה של שיח טבעי עם הקהל. תנו לו את ההרגשה כאילו אתם מדברים ישירות אליו. צרו קשר עין. תזוזו. תרגישו בנוחות ובטבעיות כשאתם מדברים. אל תפחדו לקחת את הזמן

זה יכול להיראות כמו אתגר לא פשוט לשמור על צורת דיבור טבעית כשאתם מציגים באנגלית, ולכן תרגול הוא חלק חשוב בתהליך. ככל שתתרגלו יותר, יהיה לכם קל יותר להציג פרזנטציה שתרגיש טבעית ואתם תרגישו הרבה יותר נוח בזמן שאתם מציגים אותה. אם אתם תהנו, סביר להניח שהקהל שלכם גם יהנה

אל תשכחו שכדי שפרזנטציה תהיה טובה זה לא אומר שחייבים לדבר מהר. חשוב לזכור שזו הפעם הראשונה שהקהל שלכם מקבל את המידע הזה, גם אפילו אם התאמנתם על זה מאה פעמים. הקהל שלכם צריך זמן לעבד את המידע שאתם מציגים לו, ואם תתקדמו לאט, תקלו מאוד על הקהל שלכם להבין ולזכור את המידע שאתם מציגים לו. אם תדברו לאט יהיה לכם יותר זמן לחשוב על מה שאתם רוצים להגיד בזמן הפרזנטציה ויש סיכוי טוב יותר שתעשו פחות טעויות

<strong>לתכנן כמה שיותר קדימה</strong>

אחד החלקים המפחידים ביותר בכל פרזנטציה באנגלית, בשביל מי שלא דובר אנגלית שפת אם, הוא החלק של השאלות והתשובות בפרזנטציה. בחלק הזה של הפרזנטציה אתם צריכים לעודד את הקהל שלכם לשאול אתכם כל שאלה שיש לו בנוגע למידע שהצגתם לו. למרות שזה אולי נראה קצת מפחיד, יש כמה דרכים שונות שתוכלו להפוך את החלק הזה בפרזנטציה ליותר נוח עבורכם
 
קודם כל, לאחר שבניתם את המצגת, אני ממליץ לכם לשבת ולרשום את את כל השאלות שאתם סבורים שיישאלו. לאחר מכן, אני ממליץ להעביר את המצגת לקולגות, חברים ואפילו משפחה במידה והם דומים לקהל היעד, ולשאול אותם איזה שאלות יש להם

לאחר שרשמתם את כל השאלות, תרשמו את התשובות לשאלות הללו בעברית. לאחר שרשמתם את כל השאלות והתשובות בעברית, תרגמו אותם לאנגלית כמיטב יכולתכם (זה בפני עצמו תרגיל נהדר ורלוונטי עבורכם). ניתן גם להיעזר בתרגום גוגל. לאחר שרשמתם את הכל באנגלית, תוכלו אפילו להעביר את השאלות והתשובות שכתבתם באנגלית למנטור שלכם כדי שיוכל לבדוק אותם. לאחר שקבלתם משוב מהמנטור, אני מציע שתוודאו שאתם יודעים את כל אוצר המילים החדשות. בנוסף, תרגלו את השאלות והתשובות מול המראה. כאשר אתם מרגישים שהתקדמתם כמיטב יכולתכם, תוכלו להקליט את עצמכם ולשלוח את ההקלטה למנטור שלכם כדי לקבל משוב. לאחר מכן, יש לתרגל שוב את הפרזנטציה כמה שיותר עד למועד הפרזנטציה
!קשה באימונים, קל בקרב

בסופו של דבר, האסטרטגיה האחרונה שבעזרתה תוכלו להתכונן לקראת הפרזנטציה שלכם באנגלית היא תרגול, תרגול ועוד קצת תרגול! אנחנו לא יכולים להגיד את זה מספיק - אתם חייבים להתאמן כדי להעביר בהצלחה את הפרזנטציה שלכם באנגלית. תרגלו את הצגת הפרזנטציה שלכם באנגלית כמה שיותר פעמים לקראת היום הגדול

ככל שתתרגלו יותר, תוכלו להיות יותר בטוחים בעצמכם וכתוצאה מכך תוכלו להציג את עצמכם באופן טבעי יותר בפני הקהל האמיתי שלכם. כשתרגישו מוכנים לאחר שהשתמשתם באסטרטגיות הללו, תוכלו למעשה להיפרד מכל הפחדים הקשורים להצגת פרזנטציה באנגלית. השתמשו באסטרטגיות אלה לטובתכם 

<strong>והכי חשוב - בהצלחה!</strong>
`,
}, {
	title: 'איך להתחיל עם הקדמה נהדרת לפרזנטציה שלכם',
	text: `
האם אתם מתכוננים להעביר פרזנטציה באנגלית? אם כן, תרצו לפתוח עם הקדמה טובה. הקדמה מוצלחת חשובה כי היא נותנת רושם ראשוני, מכינה את הקהל לנושא הפרזנטציה שלכם ומבססת את האמינות שלכם בקרב הקהל. היום, אני אדבר על כל האסטרטגיות שתצטרכו כדי לבנות הקדמה נהדרת לפרזנטציה שלכם באנגלית

לפני שנתחיל, בואו נדבר על מה זאת פרזנטציה. בעוד שאולי אתם מדמיינים ועידה או במת מופעים גדולה בכל פעם שהמילה פרזנטציה מוזכרת, חשבתם פעם שבעצם אנו משתמשים באותם הכישורים בכל סיטואציה שבה אנו חולקים מידע חדש עם חברים, משפחה או קולגות

בעוד שהסיטואציה עצמה או כל משימה שמשלבת דיבור יכולה להיות מעט שונה, אנחנו עדיין זקוקים לאותם הכישורים. בכל אחת מהסיטואציות האלה, יש דברים שונים שאנחנו צריכים לעשות כדי לספר סיפור או להציג מידע. צריך לעורר את עניין הקהל שלכם, להעביר את הנקודות המרכזיות, לחלוק את הדעה או הרעיונות שלכם ולגרום לקהל לבצע פעולה כלשהי

הקדמה חזקה תיתן לכם יתרון אדיר ביישום של כל אחד מהנושאים האלה. ולכן אני נותן לכם היום את כל המידע שדרוש כדי לארגן הקדמה מצויינת לפרזנטציה שלכם. בואו נתחיל

<strong>מה זאת הקדמה מצויינת?</strong>

ההקדמה היא אחד המרכיבים החשובים בכל הפרזנטציה כיוון שהיא מהווה את הרושם הראשוני שהקהל מקבל מכם ומהידע שאתם מציגים בפניו. הקדמה טובה סוללת את הדרך לכל מה שתאמרו אחריה. היא מעוררת עניין בקרב הקהל וגורמת לו לרצות להקשיב ולשמוע את כל מה שיש לכם להגיד. כדי לבנות אמינות, לזכות בתשומת לב הקהל ולגרום לו לרצות לראות את הדברים כמו שאתם רואים אותם, אתם חייבים להתחיל עם הקדמה מעולה

עם זאת, הרגע בו אתם מתחילים לדבר הוא גם הרגע הכי קשה בפרזנטציה. לכן, לדעת איך להכין ולארגן הקדמה מעולה זו הדרך הטובה ביותר להבטיח שתרגישו בטוחים מספיק כדי להעביר פרזנטציה מעולה באנגלית. אבל איך תוכלו לארגן הקדמה בצורה הטובה ביותר לפרזנטציה שלכם? אני שמח ששאלתם

<strong>איך לארגן את ההקדמה שלכם לפרזנטציה באנגלית</strong>

הדבר הראשון שאנחנו צריכים להתמקד בו כשאנחנו מדברים על הדרך הנכונה לארגן את ההקדמה שלכם לפרזנטציה באנגלית הוא לזהות את כל מה שצריך לכלול בהקדמה באנגלית. כדי לעשות את זה, תחשבו על המידע הבא כנוסחה שלכם לבניית הקדמה מעולה

יש סדרת צעדים בסיסית שתוכלו לבצע כדי להבטיח שההקדמה שלכם תהיה מצויינת וקלה להבנה עבור הקהל שלכם. בעזרת ההקדמה הזאת, תוכלו לבסס את האמינות שלכם בפני הקהל, להשאיר רושם ראשוני נהדר ולעניין את הקהל במה שיש לכם לומר. בביצוע הצעדים הספציפיים האלה, אתם תעזרו לקהל שלכם להבין מי אתם, למה אתם מומחים בנושא שעליו אתם מדברים, ומה הוא יכול לצפות מהפרזנטציה שלכם

ראשית, וודאו שאתם מברכים לשלום את הקהל לפני שאתם מציגים את עצמכם. ברכת שלום לקהל שלכם היא דרך נהדרת לקבל את תשומת ליבו ובכך שתיתנו לו מידע עליכם לפני תחילת הפרזנטציה תוכלו לבסס את אמינותכם בנושא בצורה יעילה כבר מההתחלה

שנית, אתם צריכים להשיג את תשומת הלב של הקהל שלכם. אנחנו נדבר על דרכים ספציפיות שתוכלו להשיג את תשומת ליבו במהלך ההקדמה, וכמה מהן כוללות שימוש בנתון מפתיע או בשאלה רטורית כדי להתחיל לגרום לו לחשוב

בשלב הבא, אתם תציגו את הנושא הכולל של הפרזנטציה שלכם, ומה המטרה מספר אחת שלכם בפרזנטציה. ככה תוכלו להבהיר לקהל שלכם מה נושא הפרזנטציה כבר מההתחלה

לאחר מכן, תתארו בזריזות כל נקודת מפתח בפרזנטציה כדי לתת לקהל מושג על מה תפרטו בהמשך הפרזנטציה. זו דרך נהדרת להשיג את עניין הקהל אפילו לפני שאתם מתחילים את הפרזנטציה באופן רשמי. זה גם למעשה סיכום של מה שהקהל שלכם יכול לצפות ללמוד במהלך הפרזנטציה

לבסוף, חשוב מאוד שתיתנו לקהל שלכם הוראות איך אפשר לשאול אתכם שאלות במהלך הפרזנטציה. אם אתם מרגישים בנוח שאנשים ישאלו שאלה בכל רגע נתון, תנו להם לדעת שהם יכולים לעשות זאת. אם אתם מעדיפים שהקהל ישאל את כל השאלות בסוף הפרזנטציה, תודיעו לו שיהיה זמן לשאלות בסוף הפרזנטציה. כך תוכלו להימנע מהפרעות מיותרות שעלולות לשבש את רצף הפרזנטציה שלכם

<strong>הפכו את ההקדמה שלכם לקלה להבנה על ידי שימוש בניסוחים נפוצים</strong>

אחרי שהגדרתם את כל החלקים העיקריים של ההקדמה שלכם בשימוש במתווה הכללי שהצגנו, הגיע הזמן להתמקד בכמה ניסוחים קלים להבנה ונפוצים שבהם תוכלו להשתמש בכל שלב במתווה. בנוסף לכך שזה יעזור לכם לתכנן ולתרגל מה להגיד בכל שלב, זה גם יעזור לכם לדעת איך לומר כל דבר בצורה המשדרת ביטחון ומוכנות

<strong>ניסוחים נפוצים לברך את הקהל שלכם לשלום</strong>

בפרזנטציות באנגלית, זה נחשב מנומס להתחיל עם ברכת קבלת פנים לקהל שלכם בהקדמה. קחו את הזמן הזה להציג את עצמכם בפני הקהל, להסביר מי אתם ולבסס את עצמכם כמומחים

ישנם כמה ניסוחים שונים שבהם תוכלו להשתמש כדי לקבל את פני הקהל במהלך ההקדמה. לרוב זה כולל שימוש בניסוחים כמו “thank you for coming today” ("תודה שהגעתם היום") ו- “good morning/good afternoon” ("בוקר טוב/אחר צהריים טובים"). חוץ מהניסוחים המקובלים האלה, אתם יכולים להסביר מי אתם עם משפטים כמו “My name is… and today I'll be talking about…” ("שמי ... והיום אני אדבר על ...") או “My name is… and I am….” ("שמי ... ואני … במקצועי"). אלה שתי דרכים מעולות להציג את השם שלכם תוך הדגשת הדברים בהם תדונו בהמשך ומה התפקיד שלכם. ציון תפקיד העבודה המקצועית שלכם היא דרך נהדרת ליצור אמינות אצל הקהל שלכם וכדאי לשלב אותה כשאתם מברכים את הקהל לשלום

<strong>ניסוחים נפוצים להשגת תשומת הלב של הקהל שלכם</strong>

אסביר את הדרך הטובה ביותר להשיג את תשומת ליבו של הקהל בהקדמה שלכם בסעיף הבא, אבל בינתיים יש כמה ניסוחים נפוצים שבהם תוכלו להשתמש כדי להתחיל. כל אחד מהניסוחים האלה הוא נוסחה בטוחה למשיכת תשומת לב והוא ישיג את תשומת לב הקהל שלכם באופן מיידי
לרוב, אנשים שנותנים פרזנטציה באנגלית משתמשים בנתון סטטיסטי או עובדה מפתיעה כדי לזכות בתשומת ליבו של הקהל שלהם. כדי להשתמש בדוגמאות ספציפיות מהסוג הזה כדי למשוך תשומת לב, תוכלו להיעזר בניסוחים כמו “Did you know that…” ("האם ידעתם ש ...") או “Have you ever heard of…” ("האם שמעתם אי פעם על ..."). אלה שיטות נהדרות להציג עובדה מפתיעה שתשיג מיד את תשומת ליבו של הקהל
אתם יכולים גם להשתמש בניסוחים עוצמתיים או בשאלות רטוריות כדי לזכות בתשומת לב הקהל במסגרת ההקדמה שלכם. אם אתם לא בטוחים איך להציג ציטוט, אתם יכולים להשתמש בניסוח כגון “When preparing for my presentation I was reminded of this quote…” ("בהכנת הפרזנטציה שלי, נזכרתי בציטוט הזה ..."). אם אתם שואלים שאלה רטורית כדי לגרום לקהל שלכם לחשוב על משהו, פשוט תשאלו את השאלה. תוכלו גם להשתמש בניסוח כמו “What would happen if…” ("מה היה קורה אם ..."), למשל. שאלות אלה לא אמורות לקבל תשובות מהקהל שלכם, אלא פשוט לגרום לו לחשוב ולהכין אותו לתכנים שתציגו

<strong>ניסוחים נפוצים להגדרת המטרה או הנושא שלכם</strong>

חשוב מאוד שתוכלו להגדיר את המטרה או הנושא שלכם בפני הקהל במהלך ההקדמה של הפרזנטציה באנגלית. הסיבה לכך היא שכך תכינו אותם למידע שיגיע אחר כך, וזה יבהיר לקהל את הנושא מההתחלה

אם אתם מעוניינים שהקהל שלכם ינקוט בפעולה כלשהי אחרי הפרזנטציה, עליכם לוודא שלקהל שלכם יש הבנה מלאה של הנושא, ושהוא מבין את נקודת המבט הייחודית שלכם. יש כמה ניסוחים שונים שבהם תוכלו להשתמש כדי להגדיר את המטרה או הנושא שלכם בבירור בהקדמה. אלה כוללים ניסוחים כמו “Today, I would like to present to you…” ("היום, אני רוצה להציג בפניכם ...") או “Today, I'll be discussing…” ("היום אני אדבר על...") או אולי, “By the end of my presentation today, I would like for you to know…” ("בסוף הפרזנטציה שלי היום, אני רוצה שתדעו ..."). הניסוחים האלה מגדירים לקהל שלכם לא רק את הדברים שעליהם אתם מדברים אלא גם עם איזה תובנות אתם רוצים שהוא יצא מהפרזנטציה. זה יקל עליו מאוד בלעקוב אחר התוכן שתציגו

<strong>ניסוחים נפוצים למתווה הפרזנטציה שלכם</strong>

כשמדובר בפרזנטציות באנגלית, אתם צריכים לתרגל חזרתיות. אתם צריכים להזכיר לקהל שלכם את מה שאתם אומרים לו ועל מה אתם מדברים בשלוש פעמים שונות במהלך כל הפרזנטציה שלכם. אחרי הכל, ככה בונים פרזנטציות באנגלית. הסיבה לכך היא שאתם צריכים לתאר שוב ושוב כל נקודה בפרזנטציה כדי לוודא שהיא באמת מובנת ועושה רושם על הקהל

הפעם הראשונה שתתארו את הנושא שלכם תהיה במסגרת ההקדמה. יש כמה ניסוחים נפוצים שונים שבהם תוכלו להשתמש למטרה הזאת כמו “The next thing I'll be sharing with you today is…” ("הדבר הבא שאשתף אתכם היום הוא…) או “First, we'll be discussing… After that, we'll be talking about… Finally, I'll be asking you to….” ("ראשית, נדון ב… אחר כך נדבר על… לבסוף אבקש מכם .... "). הניסוח השני יעיל במיוחד בעזרה לקהל היעד שלכם להבין את המתווה הכללי של הפרזנטציה. בעזרת סוגים שונים של ניסוחים כאלה אתם יכולים לתאר את הפרזנטציה בצורה יעילה ולתת לקהל שלכם מושג למה לצפות

<strong>ניסוחים נפוצים למתן הוראות בנוגע לשאלות</strong>

חשוב שלא רק תעודדו את הקהל שלכם לשאול שאלות, אלא גם תגידו לו מהי הדרך לשאול ומתי הזמן המתאים לשאול את השאלות האלה. ככה תהיו בטוחים שלא תצטרכו לקטוע את הפרזנטציה כדי לענות על שאלות. לרוב עדיף לשמור זמן המוקדש לשאלות ותשובות בסוף הפרזנטציה לאחר שתסיימו לדבר על כל מה שרציתם. עם זאת, אפשר בהתאם להחלטה שלכם, לאפשר שאלות גם במהלך הפרזנטציה. זה עניין של נוחות והעדפות אישיות שלכם

ישנם כמה ניסוחים נפוצים שבהם תוכלו להשתמש בכדי לתת לקהל שלכם הוראות לגבי הדרך המתאימה לשאול שאלות. לדוגמא ניסוחים כמו “If you have any questions throughout my presentation, please hold them until the end for the Q&A session”. ("אם יש לכם שאלות לאורך הפרזנטציה, אנא שמרו אותן עד לזמן השאלות בסוף הפרזנטציה"). אם אין לכם בעיה שהקהל שלכם ישאל שאלות במהלך הפרזנטציה, תאמרו לו שזה בסדר עם ניסוח כמו “If there are any questions at any time throughout my presentation, go ahead and ask” ("אם יש לכם שאלות בכל רגע במהלך הפרזנטציה, תרגישו חופשי לשאול"). אתם יכולים גם לקחת הפסקות ארוכות לאורך הפרזנטציה כדי לשאול את הקהל אם יש למישהו שאלה. זו גם דרך נהדרת לשמר את תשומת הלב שלו. אתם יכולים לעצור ולשאול אם יש שאלות עם ניסוח כמו “Are there any questions at this time?” ("האם למישהו יש שאלה עכשיו?")

<strong>איך להשיג את תשומת לב הקהל בהקדמה שלכם</strong>

עכשיו שאתם מכירים כמה מהניסוחים הנפוצים הטובים ביותר שבהם תוכלו להשתמש בהקדמה שלכם באנגלית, הגיע הזמן לדבר על הדרך הטובה ביותר להשיג את תשומת הלב של הקהל בהקדמה שלכם. אמנם ציינו כמה דוגמאות מפתח לניסוחים הטובים ביותר שאפשר להשתמש בהם, אבל ייתכן שאתם עדיין לא בטוחים לגבי הדרך הטובה ביותר להשיג את תשומת לב הקהל שלכם. זה בסדר! לפניכם מספר אסטרטגיות שבהן תוכלו להשתמש

<strong>תפתחו עם סיפור אישי</strong>

אחת הדרכים הטובות ביותר לא רק להשיג את תשומת הלב הקהל, אלא גם לבסס את האמינות שלכם היא באמצעות סיפור אישי הקשור לנושא שלכם. זה משדר לקהל שיש לכם ניסיון בנושא שאתם מדברים עליו. זה גם עוזר לגרום לפרזנטציה שלכם לזרום בצורה טבעית יותר, כאילו אתם מנהלים שיחה עם הקהל. זו דרך נהדרת לגרום לקהל להקשיב ממש בתחילת הפרזנטציה

<strong>שאלו שאלה רטורית</strong>

אחת הדרכים הטובות ביותר להשיג את תשומת ליבו של הקהל בהקדמה שלכם היא לשאול שאלות רטוריות. שאלות רטוריות הן שאלות שלא נשאלות במטרה לקבל עליהן תשובה אלא כדי לגרום לקהל שלכם לחשוב. בחרו שאלה רטורית שרלוונטית לנושא עליו אתם מדברים והשתמשו בה כדי לגרום לקהל שלכם לחשוב על כל מה שיש לכם לומר במהלך הפרזנטציה. זה דורש את תשומת ליבו

<strong>השתמשו בציטוט מעורר מוטיבציה</strong>

ציטוטים מעוררי מוטיבציה הם דרך נהדרת למשוך את תשומת הלב של הקהל, במיוחד כאשר מטרת הפרזנטציה היא לעורר השראה או לעורר מוטיבציה. בחרו ציטוט מוטיבציה טוב ותהיו בטוחים שהקהל שלכם יתמלא בהשראה עוד הרבה לפני שתציגו את הנקודות העיקריות של הדיון! זאת דרך נהדרת להכין את הבמה לכל מה שיבוא אחר כך בפרזנטציה

<strong>שימוש בסטטיסטיקה מפתיעה או בהצהרה נועזת</strong>

עובדות מפתיעות, סטטיסטיקות והצהרות נועזות הן אחת הדרכים הטובות ביותר להשיג את תשומת הלב של הקהל בהקדמה שלכם. כשאתם מספרים לקהל שלכם עובדה מפתיעה שהוא לא ידע או ציפה לה, תוכלו להשיג את תשומת הלב שלו מייד והוא יקשיב בעניין כדי לראות איזה מידע חדש אתם יכולים לספק לו. זו גם דרך נהדרת לבסס את האמינות שלכם כמקור מידע אמין

<strong>ספרו בדיחה</strong>

אפילו שכדאי להיזהר עם הסעיף הזה ולוודא שהבדיחה שלכם עובדת טוב עם הקהל שלכם, שימוש בקצת הומור בהקדמה היא דרך נהדרת לשבור את הקרח. רק תוודאו שהבדיחה שלכם רלוונטית לנושא שעליו אתם מדברים, הקהל אליו אתם מדברים, וכמובן שהיא מתאימה למצב הנוכחי.
`,
}, {
	title: 'איך לארגן את הפרזנטציה שלכם באנגלית',
	text: `
כשאתם מכינים פרזנטציה באנגלית, חשוב מאוד שתשקיעו את המאמץ הדרוש כדי לארגן אותה בצורה נכונה. אפילו שהכנת פרזנטציה באנגלית יכולה להיות דבר מאתגר, וודאו שאתם מקדישים את הזמן הנחוץ כדי לארגן אותה בצורה נכונה. זוהי נקודה חשובה כי פרזנטציה מסודרת קלה יותר להבנה, מוסרת יותר מידע ואפילו מהנה לקהל שלכם

היום, אנחנו ניתן לכן את כל המידע הדרוש כדי לארגן את הפרזנטציה שלכם באנגלית. בשיעור הזה נתמקד בעיקר בגוף הפרזנטציה. חשוב ששלב הרעיון של תוכן הפרזנטציה יהיה מסודר וקל להבנה. שימוש בטיפים האלה יכין אתכם טוב יותר לפרזנטציה, מה שישפר את סיכויי ההצלחה שלכם. אז בואו נתחיל

<strong>מהו גוף הפרזנטציה?</strong>

גוף הפרזנטציה שלכם הוא החלק שמכיל את התוכן והוא ממוקם בין ההקדמה למסקנות. זה החלק הארוך ביותר של הפרזנטציה בו תציגו את הנקודות החשובות של הדיון שלכם. מטרת גוף הפרזנטציה היא להציג את הנקודות באופן ברור כדי להעביר את הרעיונות שלכם לקהל

בתהליך הכנת גוף הפרזנטציה, ישנן שתי משימות עיקריות שעליכם להשלים. הראשונה, לקבוע את נקודות המפתח שתרצו להעביר. השנייה, לארגן את סדר הנקודות האלה. ארגון נכון של סדר רעיונות המפתח בגוף הפרזנטציה שלכם חשוב כי הוא מעניק לפרזנטציה זרימה חלקה

<strong>איך לארגן את גוף הפרזנטציה שלכם באנגלית</strong>

עכשיו שהבנתם מהו גוף הפרזנטציה ומה המטרה שלו, הגיע הזמן לדבר על הדרך הטובה ביותר לארגן את גוף הפרזנטציה שלכם באנגלית. בעוד שארגון הפרזנטציה יכול להרגיש כמו משימה קצת מאיימת, יש דרך מאוד פשוטה לחשוב על ארגון גוף הפרזנטציה בעזרת הדוגמאות הבאות

לדוגמה, נדמיין שאנחנו מעבירים פרזנטציה של 15 דקות באנגלית. אחרי שחשבנו על נושא הפרזנטציה ומה אנחנו רוצים להעביר לקהל באמצעותה, אנחנו צריכים לארגן את המידע הכלול בפרזנטציה ב-3 נקודות מפתח. כדי להבין מהן שלוש הנקודות האלה, יש לבחור שלושה תתי נושאים בתוך הנושא שלכם שאותם הכי תרצו להעביר לקהל. זה יעזור לכם בארגון גוף הפרזנטציה

בבחירת שלוש הנקודות, מומלץ לגבות כל נקודה בשלושה פרטים שיתמכו בה. מהסיבה הזו, כל אחת משלוש נקודות המפתח שלכם לאורך המצגת צריכה לכלול שלושה פרטים תומכים. זה יסייע לזרימה הכללית של הפרזנטציה שלכם כמו גם יעזור לכם לארגן את המידע בו אתם משתמשים לאורך הפרזנטציה

<strong>כמה נקודות מפתח צריך שיהיו לכם בפרזנטציה?</strong>

חשוב להיות מאוד זהירים לגבי כמה נקודות מפתח אתם משתמשים בעת שאתם מכינים את הפרזנטציה שלכם באנגלית. בעוד שזה חשוב להעביר לקהל את כל הנקודות הבסיסיות שלכם, חשוב גם לשמור על רמת העניין של הקהל בדברים שאתם מעבירים

אם תכללו יותר מדי נקודות מפתח, אתם עלולים לגרום לקהל שלכם להרגיש מוצף במידע בגלל כמות המידע שעליו לעבד. מהסיבה הזו אנחנו ממליצים למקד את הפרזנטציה למספר נקודות בודדות בהתאם לזמן שעומד לרשותכם

כחוק אצבע, אתם יכולים להסתמך על הצגת 3 נקודות מפתח לפרזנטציה באורך של עד 15 דקות. כך לא תציפו את הקהל שלכם במידע וזה יעזור לכם לארגן את הפרזנטציה. לפרזנטציות באורך 15 עד 30 דקות לעומת זאת, מומלץ להשתמש בסביבות ה- 6 נקודות מפתח לכל היותר ועבור פרזנטציה באורך 45-60 דקות מומלץ לחלק אותה ל 8 נקודות מקסימום. כמות הנקודות הזו תמלא את גוף הפרזנטציה שלכם בלי לתת לקהל שלכם הרגשה שהוא מוצף ביותר מדי מידע

<strong>ניסוחים בהם אתם יכולים להשתמש בארגון גוף הפרזנטציה שלכם</strong>

בארגון גוף הפרזנטציה שלכם באנגלית, ישנם ניסוחים באנגלית שיכולים לעזור לקהל שלהם להבין אתכם. שוב, ישנה חשיבות בסיסית לכך שמעבר מנקודת מפתח אחת לאחרת תעשה בצורה זורמת וקלה להבנה. לכן נציג בפניכם ניסוחים שתוכלו לשלב בגוף הפרזנטציה שיעזרו לדברים לזרום בצורה יותר טבעית

<strong>איך להתחיל נושא</strong>

ישנן מספר דרכים שבהן תוכלו להשתמש בניסוחים נפוצים באנגלית כדי להציג את ההתחלה של אחת מנקודות המפתח שלכם. הניסוחים האלה יכולים להיות לדוגמא ״let's start by looking at”,("בואו נתחיל בלהסתכל על…") “I'd like to start by discussing” (בואו נתחיל לדבר על …) או “For starters, I'd like to talk about…” ("ראשית, ארצה לדבר על…")

<strong>מעבר מנושא אחד לשני</strong>

לאחר שהתחלתם והשלמתם את נקודת המפתח הראשונה שלכם, זה הזמן לעבור לנקודה הבאה. יש לציין את הנושא באופן ברור כדי ליידע את הקהל שלכם שסיימתם לדבר על הנושא הקודם ואתם עוברים לנושא הבא. ישנם מספר ניסוחים נפוצים שיכולים לעזור לכם לסמן מעבר לנושא הבא, I'll now move on to my next point” ("וכעת אעבור לנקודה הבאה") או “Now, I'd like to move on to” ("כעת ארצה לעבור לדבר על…") או, “This leads me to the next point of…” ("זה מוביל אותי לנקודה הבאה…"). בשימוש בניסוחים אלה באנגלית תוכלו לסמן מעבר מנושא אחד לאחר באופן שהקהל שלכם יבין

<strong>איך להתייחס למידע קודם שהוצג</strong>

במהלך הפרזנטציה שלכם באנגלית, ייתכן ותרצו לחזור מדי פעם ולהתייחס למידע עליו דיברתם בחלק קודם. זאת דרך מצויינת לרענן לקהל את הנקודות עליהן דיברתם ואסטרטגיה בה כדאי להשתמש באופן שוטף לאורך גוף הפרזנטציה שלכם. ניסוחים נפוצים לציין התייחסות למידע שנאמר קודם כוללים “As we touched upon earlier…” ("כפי שהוזכר קודם לכן…") או “As you may remember…” ("כפי שאתם ודאי זוכרים…") או, “As I mentioned in my introduction…” ("כפי שהצגתי בהקדמה שלי…")

<strong>איך להציג דוגמאות</strong>

כשאתם מציגים את נקודות המפתח שלכם בגוף הפרזנטציה, שימוש בדוגמאות מחיי היומיום יכול להיות דבר יעיל בקישור הנקודות שלכם לקהל. ישנם כמה ניסוחים שיכולים לעזור לשלב דוגמאות מחיי היומיום בהקשר לנקודות המפתח שלכם. הניסוחים האלה יכולים להיות “For instance, there is this example…” ("למשל, הנה דוגמא ל…") או “For this example I'd like to share…” ("כדוגמא לכך אני ארצה לשתף…") או, “A good/bad example of this is…”. ("דוגמא טובה/פחות טובה לכך היא…")

<strong>איך לשאול שאלות רטוריות</strong>

לסיום, מומלץ לפעמים למשוך את תשומת לב הקהל שלכם לאורך גוף הפרזנטציה שלכם בעזרת שאלות רטוריות. שאלות רטוריות הן שאלות שאתם שואלים בלי ציפיה לקבל תשובה. יותר מכך, המטרה של השאלות היא לגרום לקהל שלכם לחשוב על משהו ברמה עמוקה יותר

כמה דוגמאות לשאלות רטוריות שאתם יכולים להשתמש כדי לגרום לקהל שלכם לחשוב בצורה יותר מעמיקה על נקודות המפתח יכולות להיות ”Now, you may be wondering, what does this mean?” ("כעת אתם בוודאי תוהים, מה זה אומר?") או “So what can we learn from these points” ("אז מה אנחנו יכולים ללמוד מהנקודות האלה?") או, “What can we learn from these examples?”.("מה אנחנו יכולים ללמוד מהדוגמאות האלה?").
`,
}, {
	title: 'איך לסיים את הפרזנטציה שלכם באופן עוצמתי',
	text: `
לאחר מתן ההקדמה שלכם והצגת נקודות המפתח בגוף הפרזנטציה, הגיע הרגע לסיומת עוצמתית שתציג את מסקנות הפרזנטציה שלכם. רבים מאיתנו יודעים כמה חשוב ליצור רושם ראשוני טוב בתחילת הפרזנטציה שלנו באנגלית, אבל צריך לזכור שחשוב לא פחות ליצור רושם טוב גם בסוף הפרזנטציה. הסיבה היא שרוב האנשים יזכרו רק את הדבר הראשון והאחרון שתגידו להם! ומהסיבה הזאת, עליכם להציג אמירה עוצמתית עם סיום הפרזנטציה שלכם באנגלית

היום, אנחנו ניתן לכם את כל המידע הדרוש כדי להפוך את נקודות הסיום של הפרזנטציה שלכם לעוצמתיות, ככה שהקהל שלכם יצא עם משהו לזכור. הדקות האחרונות של הפרזנטציה שלכם הן ההזדמנות האחרונה שלכם לעשות רושם אמיתי על הקהל. בעזרת הטיפים האלה תוכלו לעשות רושם חזק כדי להבטיח שהפרזנטציה שלכם באנגלית תהיה הצלחה אמיתית! יש כמה אסטרטגיות עיקריות עליהן נדבר היום, ובאמצעות כל אחת מהן תוכלו לסיים בצורה עוצמתית. אז בואו נתחיל

<strong>לרכוש מחדש את תשומת לב הקהל שלכם</strong>

כשאתם מציגים פרזנטציה באנגלית, סביר להניח (לא משנה כמה טובה הפרזנטציה שלכם) שתשומת הלב של הקהל שלכם תלך לאיבוד במהלך הפרזנטציה. זה טבעי רק בגלל שטווח הקשב האנושי הוא בממוצע באורך שמונה שניות בלבד. זה אומר שאתם צריכים להשתמש במסקנות הפרזנטציה שלכם כדי לרכוש מחדש את תשומת לב הקהל שלכם בצורה מרשימה 
?אבל איך

יש כמה אסטרטגיות שונות בהן תוכלו להשתמש כדי לרכוש מחדש את תשומת הלב של הקהל שלכם. עם זאת, אחת הדרכים הטובות ביותר לעשות זאת היא עם עובדה או נתון סטטיסטי מפתיע שימשוך את תשומת לב הקהל. כדי לחשוב על עובדה טובה או נתון סטטיסטי טוב להשתמש בו, שאלו את עצמכם את השאלות הבאות

1. האם יש משהו שהייתם רוצים שהקהל שלכם יחשוב עליו אחרי סוף הפרזנטציה

2. האם יש עובדה או נתון סטטיסטי שיעזור לקהל שלכם לזכור את נקודות המפתח שלכם בגלל אלמנט ההפתעה שהוא מכיל

חשבו טוב באיזו עובדה או נתון סטטיסטי אתם משתמשים כדי לרכוש מחדש את תשומת לב הקהל. חשוב שתשתמשו בעובדה או נתון סטטיסטי מספיק מפתיע כדי באמת להשיג את תשומת לב הקהל. כשתעשו את זה, אתם בטוח תסיימו בנימה עוצמתית שתגרום לקהל שלכם לחשוב זמן רב לאחר סיום הפרזנטציה שלכם באנגלית

<strong>להתחבר לקהל שלכם</strong>

הדרך הבאה לסיים את הפרזנטציה שלכם בצורה עוצמתית היא על ידי חיבור יעיל לקהל שלכם. תוך כדי הקשבה לפרזנטציה, לפעמים הקהל שלכם יכול בקלות להרגיש מנותק מכם - במיוחד אם אתם נלחצים ונמנעים מקשר עין

מהסיבה הזאת, זה רעיון מצוין לשלב ציטוט השראה/מוטיבציה עוצמתי ומלא רגש בסוף הפרזנטציה, כדי להתחבר לקהל ברמה אנושית עמוקה יותר. בשימוש בציטוט מלא מוטיבציה המעורר רגש חזק, אתם יכולים לגרום לקהל להרגיש יותר מחובר אליכם ולנושא הפרזנטציה

כדאי לשקול להשתמש בנושא שמדבר לחוויה האנושית בה הקהל נתקל בחיי היום יום. תשאלו את עצמכם באיזה סוגי ציטוטים תוכלו להשתמש בכדי לגעת בקהל שלכם ברמה עמוקה יותר, לגרום לו לזכור את הציטוט, ובסופו של דבר לזכור את נקודות המפתח של הפרזנטציה

<strong>למקד את תשומת לב הקהל שלכם וכך לעזור לו לזכור את נקודות המפתח</strong>

דרך אפקטיבית להשיג את תשומת לב הקהל, היא על ידי שימוש בקריאה לפעולה (call-to-action). קריאה לפעולה היא אמירה שבה אתם אומרים לקהל שלכם שאתם רוצים שהוא יבצע פעולה מסוימת לאחר סיום הפרזנטציה. זאת דרך נהדרת לגרום לו לזכור את נקודות המפתח שלכם במידה ותעשו זאת בצורה נכונה

תשאלו את עצמכם אם יש משהו שאתם רוצים שהקהל שלכם יעשה אחרי שהוא הקשיב לפרזנטציה שלכם. אם אתם רוצים שהוא יבצע איזשהי פעולה, תבהירו לו את זה דרך אמירה ברורה ע"י קריאה לפעולה מצידכם. תעוררו בו איזשהו רגש על ידי הסבר כיצד הפעולה שאתם רוצים שהוא יבצע יכולה להועיל לא רק לו אלא גם לאחרים בדרך כלשהי

על ידי יצירת חיבור לקהל שלכם כאדם לאדם, תוכלו לגרום לו לבצע את הפעולה שאתם רוצים שיבצע ביעילות רבה יותר. ברגע שהקהל שלכם יבין מה מטרת הפרזנטציה שלכם דרך הקריאה לפעולה, סביר להניח שהוא יזכור את נקודות המפתח של הפרזנטציה

<strong>ניסוחים נפוצים בהם תוכלו להשתמש כדי לסיים את הפרזנטציה שלכם בצורה עוצמתית</strong>

עכשיו כשאתם מכירים שלוש מהאסטרטגיות הטובות ביותר בהן אתם יכולים להשתמש כדי לסיים את הפרזנטציה שלכם בצורה עוצמתית, הגיע הזמן לדבר על כמה ניסוחים נפוצים באנגלית שבהם תוכלו להשתמש כדי לעשות זאת. בסיכום הפרזנטציה, יש ארבעה צעדים עיקריים שעליכם לנקוט

● שלב 1: סיכום של נקודות המפתח שלכם לפני סגירת הפרזנטציה
● שלב 2: תודה לקהל על תשומת ליבו
● שלב 3: מענה לכל השאלות שהקהל שלכם מעוניין לשאול
● שלב 4: הצגה של הצעדים הבאים או של פרטי הקשר שלכם

	בואו נסתכל על כמה ניסוחים נפוצים שבהם תוכלו להשתמש בכל שלב של הצגת המסקנות שלכם

<strong>סיכום נקודות המפתח שלכם וסגירת הפרזנטציה</strong>

בסיכום נקודות המפתח שלכם, ישנם כמה ניסוחים שונים שבהם תוכלו להשתמש כדי לסגור את הפרזנטציה. למשל: “in summary, I’d like to review the following points” ("לסיכום, אני ארצה לסקור את הנקודות הבאות") או “This concludes my presentation. I’d like to summarize the following points in closing”. ("זהו מסכם הפרזנטציה שלי. אני ארצה לסכם את הנקודות הבאות לסיום")
בעזרת אמירות מהסוג הזה אתם יכולים ליידע את הקהל שלכם שהפרזנטציה הסתיימה לפני שתעברו שוב על הנקודות העיקריות שאתם רוצים שהוא יזכור. זיכרו, סביר להניח שהקהל יזכור את הדברים הראשונים והאחרונים שתאמרו לו. לכן חשוב לסכם את הנקודות העיקריות בסיכום הפרזנטציה

<strong>להודות לקהל שלכם על תשומת לבו</strong>

חשוב מאוד שתודו לקהל שלכם על ההקשבה בסיכום הפרזנטציה באנגלית. זה דבר מאוד מקובל בפרזנטציות באנגלית וזה יגרום לקהל שלכם להרגיש מכובד ומוערך על כך שהוא הקשיב לכם. כמה מהניסוחים המקובלים ביותר באנגלית שבהם תוכלו להשתמש כדי להודות לקהל שלכם על תשומת הלב שלו הם: I’d like to take this opportunity to thank you for listening to my presentation” ("אני רוצה לנצל את ההזדמנות הזו כדי להודות לכם על שהקשבתם לפרזנטציה שלי”) או “I sincerely appreciate that I had this time to speak with you today” (“אני מאוד מעריך/ה את הזמן שניתן לי לדבר איתכם היום")

<strong>לענות על שאלות</strong>

חשוב שתיתנו לקהל שלכם זמן לשאול כל שאלה שנוגעת לתוכן הפרזנטציה שלכם. יתכן שעדיין יש כמה דברים שהוא צריך להבין בנוגע לנקודות העיקריות שלכם. אם יש לקהל שלכם הרבה שאלות זה סימן טוב. זה אומר שהוא הקשיב לאורך כל הפרזנטציה שלכם ושעשיתם עבודה טובה ושמרתם על תשומת ליבו

כשאתם עונים על שאלות, ישנם כמה ניסוחים שונים שאתם יכולים להשתמש כדי לשאול את הקהל אם יש לו שאלות כלשהן, לדוגמא “I will now take this time to answer any questions” ("אני עכשיו אקח את הזמן הזה לענות על שאלות") או “Does anyone have any questions about my presentation at this time?” ("האם למישהו יש שאלות לגבי הפרזנטציה שלי עד כה?")

<strong>להציג את הצעדים הבאים או פרטי הקשר שלכם</strong>

בסוף הפרזנטציה, אתם תרצו להקדיש זמן לדבר על כל הצעדים הבאים שכדאי לקהל שלכם לבצע בכל מה שנוגע לתוכן הפרזנטציה שלכם, או לתת לו את הפרטים ליצירת קשר במידת הצורך. זו ההזדמנות שלכם ליידע את הקהל על כל הצעדים הבאים שבהם תרצו שהוא ינקוט ושיכולים להיות חשובים מאוד בהתאם לתוכן הפרזנטציה. כמה ניסוחים מקובלים שבהם תוכלו להשתמש בכדי להציג את הצעדים הבאים או לתת את הפרטים ליצירת קשר הם: ___If you would like more information, you can contact me at” ("אם תרצו מידע נוסף, תוכלו ליצור איתי קשר דרך___") או ____For more information, you can check out” ("מידע נוסף תוכלו למצוא ב____)

<strong>בהצלחה!</strong>
`,
}]

export const presentationTemplates = [
	{text: "Contemporary", link: "https://drive.google.com/drive/folders/17e2_m0i9s6aUJ8FDbJ0_B2YgPDaHFGbV?usp=sharing"},
	{text: "Corporate", link: "https://drive.google.com/drive/folders/19VAd9-UGNSoT-jfSLk6kCW9vnmrlZrgt?usp=sharing"},
	{text: "Corporate II", link: "https://drive.google.com/drive/folders/1tpTaiy9Bwpt69J8zR66hfvo3mS0n0Enj?usp=sharing"},
	{text: "Covers", link: "https://drive.google.com/drive/folders/1EwgZcpvi-oGU9MXonhOnfYcCtWuvi8Xa?usp=sharing"},
	{text: "Discovery", link: "https://drive.google.com/drive/folders/11Eln9iejF0NmZ5SRKA4VRTwXE4m0MxMG?usp=sharing"},
	{text: "Doodle 1", link: "https://drive.google.com/drive/folders/1jHtZCLstROGRmF6DG-sOUuN6a9kVuMC6?usp=sharing"},
	{text: "Doodle 2", link: "https://drive.google.com/drive/folders/1QL5SiHJGJTH5GWPtUGjL0AwzHwbVSaJ-?usp=sharing"},
	{text: "E Commerce", link: "https://drive.google.com/drive/folders/1ZKTKVSknklxqIAbtyxMN9qyjuwxV0t57?usp=sharing"},
	{text: "Elegant", link: "https://drive.google.com/drive/folders/14VYFKqoyiloLoWbjqhBWYJxH4DCOHJoF?usp=sharing"},
	{text: "Freedom", link: "https://drive.google.com/drive/folders/1snsAOflfEmFLEgH9NVRFnWXm-iYP67SN?usp=sharing"},
	{text: "Metalic Black", link: "https://drive.google.com/drive/folders/1ZERex48QDfD6MiXkOu2RGYycmukJlogW?usp=sharing"},
	{text: "Mobile", link: "https://drive.google.com/drive/folders/1uGlmgkZa9TUEkPdA2LCidtG0BvIFxfcW?usp=sharing"},
	{text: "Modern Dark", link: "https://drive.google.com/drive/folders/18l7f11jk4iSfHlyT6y63a1S3g8WHaK8Q?usp=sharing"},
	{text: "Modern Light", link: "https://drive.google.com/drive/folders/1cZqwd5vhMai6NheMsZ2wBYXuBLDn8Btt?usp=sharing"},
	{text: "Nature", link: "https://drive.google.com/drive/folders/1_2_ZcgHfq-xOlh8qKA5vhhk3BgtLolZ-?usp=sharing"},
	{text: "Neon", link: "https://drive.google.com/drive/folders/1yfXorAZvbaNIY2FNJHOmBqqXzp51yR_Y?usp=sharing"},
	{text: "Ocean Blue", link: "https://drive.google.com/drive/folders/1_qnySCInAeaPCTcBqgg1hCmubVIyylK4?usp=sharing"},
	{text: "Paper Texture", link: "https://drive.google.com/drive/folders/1UgpFDMl6JrmSKCPSFYfhbfHQdiOnivH3?usp=sharing"},
	{text: "Red Ninja", link: "https://drive.google.com/drive/folders/1OBUcwcA_gJnWgyYNVCL-JaUWci_SDciu?usp=sharing"},
	{text: "Relationship", link: "https://drive.google.com/drive/folders/1Z9J5-FtbvbsXi_nmaGTKB_KU4jxdGM7x?usp=sharing"},
	{text: "Retro", link: "https://drive.google.com/drive/folders/1JZvReu9EqpIU_XVgdT6vpbwXy3NAvtmS?usp=sharing"},
	{text: "Socialite", link: "https://drive.google.com/drive/folders/15xif7DPXUK_fGWmRjAPxeSMq30Rjqbqq?usp=sharing"},
	{text: "Squares", link: "https://drive.google.com/drive/folders/1ZgPRXsEqvE0o0ALyHk24-JB7vLixkWzA?usp=sharing"},
	{text: "Startup", link: "https://drive.google.com/drive/folders/1aXdduOBHVbWXG81ec7wic9UnNsSsGc8m?usp=sharing"},
	{text: "Steampunk", link: "https://drive.google.com/drive/folders/17iWqqUTqSb4bHFWpTRurQnvB_IU6d3oJ?usp=sharing"},
	{text: "The urban presentation", link: "https://drive.google.com/drive/folders/1iObXjcFjbgZXgAZCj0_8FEhFfLbh-rfC?usp=sharing"},
	{text: "Web 3.0", link: "https://drive.google.com/drive/folders/1JFWRBwIBQodMhyl3GR_65vR8Unv8PEP2?usp=sharing"},
]