import _ from 'lodash';
import axios from 'services/axios';
import config from 'config/config';

export const fetchPublishers = async() => {
	const res = await axios.post('publishers/query?offset=0&limit=-1');
	return res.data.items;
}

export const permissionsOptions = [
	{id: 'core', name: 'Core'},
	{id: 'byTheWay', name: 'By The Way'},
	{id: 'words', name: '5k Words'},
	{id: 'smallTalk', name: 'Small Talk'},
	{id: 'emailHelper', name: 'Email Helper'},
]
export const permissionsOptionsMap = _.keyBy(permissionsOptions, 'id');

export const levelOptions = _.map(config.levels, (val, key) => ({id: key, name: val}))
