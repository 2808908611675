import _ from 'lodash';
import {ActionTypes} from './actions';
import {ActionTypes as PageAT} from 'pages/actions';
import { filterBy, mergeBy, setLoader } from 'utils/reduxUtils';

const initialValue = {
	loaders: {},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	default:
		return 'אופס. הבקשה נכשלה';
	}
}
const chaptersReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	// Golden Questions
	case ActionTypes.FETCH_GOLDEN_QUESTIONS_REQUEST:
		nextState = {...state, goldenQuestions: [], ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_GOLDEN_QUESTIONS_SUCCESS:
		nextState = {...state, goldenQuestions: payload.items, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_GOLDEN_QUESTIONS_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false), goldenQuestions: []};
		break;
	case ActionTypes.UPDATE_GOLDEN_QUESTIONS_ORDER_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true)};
		break;
	case ActionTypes.UPDATE_GOLDEN_QUESTIONS_ORDER_SUCCESS:
		nextState = {...state, goldenQuestions: payload.items, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.UPDATE_GOLDEN_QUESTIONS_ORDER_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.DELETE_GOLDEN_QUESTION_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: false};
		break;
	case ActionTypes.DELETE_GOLDEN_QUESTION_SUCCESS:
		nextState = {...state, goldenQuestions: filterBy(state.goldenQuestions, payload), justSubmitted: true, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.DELETE_GOLDEN_QUESTION_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case ActionTypes.SUBMIT_GOLDEN_QUESTION_FORM_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false};
		break;
	case ActionTypes.SUBMIT_GOLDEN_QUESTION_FORM_SUCCESS:
		nextState = {...state, goldenQuestions: mergeBy(state.goldenQuestions, payload, 'id', false), ...setLoader(state, 'submitting', false), justSubmitted: true};
		break;
	case ActionTypes.SUBMIT_GOLDEN_QUESTION_FORM_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case PageAT.SINGLE_GOLDEN_QUESTION_FORM_OPENED:
		nextState = {...state, justSubmitted: false, submitError: false};
		break;
	// MC Questions
	case ActionTypes.FETCH_MC_QUESTIONS_REQUEST:
		nextState = {...state, mcQuestions: [], ...setLoader(state, 'loading', true)};
		break;
	case ActionTypes.FETCH_MC_QUESTIONS_SUCCESS:
		nextState = {...state, mcQuestions: payload.items, ...setLoader(state, 'loading', false)};
		break;
	case ActionTypes.FETCH_MC_QUESTIONS_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false), mcQuestions: []};
		break;
	case ActionTypes.UPDATE_MC_QUESTIONS_ORDER_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true)};
		break;
	case ActionTypes.UPDATE_MC_QUESTIONS_ORDER_SUCCESS:
		nextState = {...state, mcQuestions: payload.items, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.UPDATE_MC_QUESTIONS_ORDER_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.DELETE_MC_QUESTION_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false, submitError: false};
		break;
	case ActionTypes.DELETE_MC_QUESTION_SUCCESS:
		nextState = {...state, mcQuestions: filterBy(state.mcQuestions, payload), justSubmitted: true, ...setLoader(state, 'submitting', false)};
		break;
	case ActionTypes.DELETE_MC_QUESTION_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case ActionTypes.SUBMIT_MC_QUESTION_FORM_REQUEST:
		nextState = {...state, ...setLoader(state, 'submitting', true), justSubmitted: false};
		break;
	case ActionTypes.SUBMIT_MC_QUESTION_FORM_SUCCESS:
		nextState = {...state, mcQuestions: mergeBy(state.mcQuestions, payload, 'id', false), ...setLoader(state, 'submitting', false), justSubmitted: true};
		break;
	case ActionTypes.SUBMIT_MC_QUESTION_FORM_FAILURE:
		nextState = {...state, ...setLoader(state, 'submitting', false), submitError: formatError(payload)};
		break;
	case PageAT.SINGLE_MC_QUESTION_FORM_OPENED:
		nextState = {...state, justSubmitted: false, submitError: false};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default chaptersReducer;
