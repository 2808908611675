import _ from 'lodash';
import {ActionTypes} from './actions';
import { setLoader } from 'utils/reduxUtils';

const initialValue = {
	loaders: {},
}

const formatError = (payload) => {
	const errorCode = _.get(payload, 'response.data.key');
	switch (errorCode) {
	default:
		return 'אופס. הבקשה נכשלה';
	}
}

const moduleReducer = (state = initialValue, action) => {
	let nextState;
	const {type, payload} = action;
	switch (type) {
	case ActionTypes.FETCH_UNIT_PAGE_FAILURE:
		nextState = {...state, ...setLoader(state, 'loading', false), submitError: formatError(payload)};
		break;
	case ActionTypes.FETCH_UNIT_PAGE_REQUEST:
		nextState = {...state, selectedPage: null, ...setLoader(state, 'loading', true), submitError: false};
		break;
	case ActionTypes.FETCH_UNIT_PAGE_SUCCESS:
		nextState = {...state, selectedPage: payload, ...setLoader(state, 'loading', false)};
		break;
	default:
		nextState = state;
	}
	return nextState;
};

export default moduleReducer;
