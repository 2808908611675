import _ from 'lodash';
// import {ActionTypes} from './actions';
// import {ActionTypes as PagesActions} from 'pages/actions';
// import { filterBy, mergeBy, setLoader } from 'utils/reduxUtils';

const initialValue = {
	loaders: {},
}

// const formatError = (payload) => {
// 	const errorCode = _.get(payload, 'response.data.key');
// 	switch (errorCode) {
// 	default:
// 		return 'אופס. הבקשה נכשלה';
// 	}
// }
const levelsReducer = (state = initialValue, action) => {
	let nextState;
	const {type} = action;
	switch (type) {

	default:
		nextState = state;
	}
	return nextState;
};

export default levelsReducer;
