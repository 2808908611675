
const style = {
	wrapper: {
		position: 'relative',
		paddingInline: 2,
	},
	buttonProgress: {
		color: theme => theme.palette.primary[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	dialogTitle: {
		background: theme => theme.palette.primary.main,
		color: theme => theme.palette.background.light,
		'& + div.MuiDialogContent-root': {
			pt: 3,
		},
	},
	dialogContent: {
		padding: theme => `${theme.spacing(3)} ${theme.spacing(3)}`,
		paddingInlineEnd: theme => theme.spacing(5),
		whiteSpace: 'pre-line',
	},
	bgError: {
		background: theme => theme.palette.error.main,
	},
};

export default style;
