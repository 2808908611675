
const style = {
	menuButton: {
		marginRight: 12,
		marginLeft: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		flexShrink: 0,
		whiteSpace: "nowrap",
		maxHeight: '100vh',
	},
	content: {
		flexGrow: 1,
		padding: theme => theme.spacing(3),
	},
	sidebarListMargin: {
		marginTop: theme => theme.spacing(-3),
	},
	mobileBackButton: {
		marginTop: theme => theme.spacing(0.5),
		marginRight: theme => theme.spacing(3),
		display: {
			md: 'none',
		},
	},
	logo: {
		width: '100%',
		maxWidth: 140,
	},
};

export default style;
