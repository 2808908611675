const config = {
	appName: 'Confidence',
	publicFolder: process.env.PUBLIC_URL,
	idleThrottle: 5,
	logoutThrottle: 1000,
	sessionIdleExpiration: 3 * 60 * 60,
	heartbitPollingTime: 5 * 60 * 1000,
	loginPaths: ['/login'],
	paginationOptions: [5, 10, 20, 50],
	minPassLength: 6,
	tinyEditorApiKey: 'gacqw6fuxqw8b2m3y7mp4ntch6z7kw1jrvrr1h61pv65aiid',
	levels: {
		beginner: 'קל',
		intermediate: 'בינוני',
		advanced: 'מתקדם',
	},
	units: {
		btw: {
			title: `English By The Way`,
			pages: [
				{label: "ברוכים הבאים", link: "/app/units/btw/welcome"},
				{label: "מבוא", link: "/app/units/btw/intro"},
				{label: "מילים ועוד מילים... מגדילים את האוצר שלך!", link: "/app/units/btw/vocabulary"},
				{label: "איך לומדים אנגלית \"על הדרך\"?", link: "/app/units/btw/how"},
				{label: "מפצחים את השיטה", link: "/app/units/btw/method"},
				{label: "חבילת הרחבה למתקדמים", link: "/app/units/btw/advanced"},
				{label: "סיכום והכנה מנטאלית", link: "/app/units/btw/summary"},
			],
		},
		words: {
			title: `5k Words`,
			pages: [
				{label: "5,000 המילים הכי שמישות באנגלית", link: "/app/units/words/list"},
			],
		},
		smallTalk: {
			title: `Small Talk`,
			levels: {
				beginner: {
					title: `Small Talk - Beginner`,
					pages: [
						{label: "Welcome to Small Talk", link: "/app/units/smallTalk/beginner/welcome"},
						{label: "At Check-in", link: "/app/units/smallTalk/beginner/checkIn"},
						{label: "At The Park", link: "/app/units/smallTalk/beginner/park"},
						{label: "At The Pool", link: "/app/units/smallTalk/beginner/pool"},
						{label: "How was your weekend?", link: "/app/units/smallTalk/beginner/howWasYourWeekend"},
						{label: "Introductions", link: "/app/units/smallTalk/beginner/introductions"},
						{label: "Meeting a Tourist", link: "/app/units/smallTalk/beginner/meetingTourist"},
						{label: "On The Bus", link: "/app/units/smallTalk/beginner/onBus"},
						{label: "The New Colleague", link: "/app/units/smallTalk/beginner/newColleague"},
						{label: "What do you like to do in your spare time?", link: "/app/units/smallTalk/beginner/spareTime"},
					],
				},
				intermediate: {
					title: `Small Talk - Intermediate`,
					pages: [
						{label: "Welcome to Small Talk", link: "/app/units/smallTalk/intermediate/welcome"},
						{label: "Talking About Work", link: "/app/units/smallTalk/intermediate/talkingAboutWork"},
						{label: "At The Museum", link: "/app/units/smallTalk/intermediate/museum"},
						{label: "Ski Vacation in Italy", link: "/app/units/smallTalk/intermediate/skiVacationInItaly"},
						{label: "Talking About Sports", link: "/app/units/smallTalk/intermediate/talkingAboutSports"},
						{label: "The New Colleague 2", link: "/app/units/smallTalk/intermediate/newColleague2"},
						{label: "An American in Tel-Aviv", link: "/app/units/smallTalk/intermediate/americanInTelAviv"},
						{label: "A Parisian in London", link: "/app/units/smallTalk/intermediate/parisianInLondon"},
						{label: "Get Healthy", link: "/app/units/smallTalk/intermediate/getHealthy"},
						{label: "Traveling to Dubai", link: "/app/units/smallTalk/intermediate/travelingToDubai"},
						{label: "Greetings and Invitations", link: "/app/units/smallTalk/intermediate/greetingsInvitations"},
					],
				},
				advanced: {
					title: `Small Talk - Advanced`,
					pages: [
						{label: "Welcome to Small Talk", link: "/app/units/smallTalk/advanced/welcome"},
						{label: "First meeting", link: "/app/units/smallTalk/advanced/firstMeeting"},
						{label: "Traffic Chaos", link: "/app/units/smallTalk/advanced/trafficChaos"},
						{label: "Executives Meet", link: "/app/units/smallTalk/advanced/executivesMeet"},
						{label: "Sustainable Energy", link: "/app/units/smallTalk/advanced/sustainableEnergy"},
						{label: "A Friend At the Airport", link: "/app/units/smallTalk/advanced/friendAirport"},
						{label: "Looking for an Apartment", link: "/app/units/smallTalk/advanced/lookingForApartment"},
						{label: "Trouble in the North", link: "/app/units/smallTalk/advanced/troubleInNorth"},
						{label: "Online Sales", link: "/app/units/smallTalk/advanced/onlineSales"},
						{label: "Positions at HQ", link: "/app/units/smallTalk/advanced/positionsHq"},
					],
				},
			},
		},
		bonuses: {
			pages: [
				{label: "המדריך לבניית מצגת מנצחת", link: "/app/bonus/presentation"},
				{label: "איך להתכונן לראיון עבודה", link: "/app/bonus/interview"},
				{label: "קבוצת פייסבוק", link: "/app/bonus/facebook"},
			],
		},
	},
	chapters: {
		exercise: {
			delay: {
				nextMillis: 2000,
			},
		},
	},
};
export default config;
