import React from "react";
import { Button, Box, Typography } from '@mui/material';

// styles
import styles from "./styles";
import { Helmet } from "react-helmet-async";
import config from "config/config";

// components

export default function PageTitle(props) {
	return (
		<Box sx={{display: "flex", justifyContent: "space-between", mb: 4, marginTop: [0, 5]}}>
			<Helmet><title>{props.navigationTitle || props.title} - {config.appName}</title></Helmet>
			<Box sx={styles.titleWrapper}>
				{props.icon && React.createElement(props.icon, {sx: styles.icon})}
				<Typography sx={styles.typo} variant="h1" size="sm">
					{props.title}
				</Typography>
			</Box>
			{props.button && (
				<Button
					sx={styles.button}
					variant="contained"
					size="large"
					color="secondary"
				>
					{props.button}
				</Button>
			)}
		</Box>
	);
}
