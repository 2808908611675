import React, {useRef, useState} from 'react';
import {Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

export default function RichEditor({name, label, ...props}) {
	const context = useFormikContext();
	const error = (context.touched[name] && context.errors[name]);
	const editorRef = useRef(null);
	const [isFocused, setFocus] = useState(false)

	return (
		<Field
			name={name}
			id={name}
			fullWidth
			{...props}
		>
			{({ field }) => (
				<FormControl fullWidth color="primary" focused={isFocused} error={!!error}>
					<InputLabel variant="outlined" htmlFor="select-multiple-accounts" shrink focused={isFocused}>
						{label}
					</InputLabel>
					<OutlinedInput
						color="primary"
						inputComponent={Editor}
						notched
						fullWidth
						label={label}
						sx={{
							padding: '2px',
							paddingTop: '6px',
							display: 'block',
							'& .tox.tox-tinymce': {
								border: 'none',
							},
						}}
						inputProps={{
							disabled: props.disabled,
							onInit: (evt, editor) => editorRef.current = editor,
							apiKey: config.tinyEditorApiKey,
							..._.omit(field, ['onChange']),
							onEditorChange: (value) => {
								field.onChange({ target: { name, value} });
							},
							onFocus: () => setFocus(true),
							onBlur: () => setFocus(false),
							init: {
								height: 520,
								menubar: false,
								plugins: [
									'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
									'searchreplace', 'visualblocks', 'code', 'fullscreen',
									'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'media', 'directionality',
								],
								toolbar: 'media undo redo bold italic underline strikethrough' +
                  ' fontsize blocks ltr rtl alignleft aligncenter alignright alignjustify' + 
                  ' outdent indent numlist bullist' +
                  ' forecolor backcolor removeformat link',
								content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								branding: false,
								directionality: 'rtl',
								language: 'he_IL',
							},
						}
						} />
					{error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
				</FormControl>
			)}
		</Field>
	);
}

//