import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
	'FETCH_LEVEL_CHAPTERS_REQUEST',
	'FETCH_LEVEL_CHAPTERS_SUCCESS',
	'FETCH_LEVEL_CHAPTERS_FAILURE',

	'UPDATE_CHAPTERS_ORDER_REQUEST',
	'UPDATE_CHAPTERS_ORDER_SUCCESS',
	'UPDATE_CHAPTERS_ORDER_FAILURE',

	'FETCH_SINGLE_CHAPTER_REQUEST',
	'FETCH_SINGLE_CHAPTER_SUCCESS',
	'FETCH_SINGLE_CHAPTER_FAILURE',

	'SUBMIT_CHAPTER_FORM_REQUEST',
	'SUBMIT_CHAPTER_FORM_SUCCESS',
	'SUBMIT_CHAPTER_FORM_FAILURE',

	'DELETE_SINGLE_CHAPTER_REQUEST',
	'DELETE_SINGLE_CHAPTER_SUCCESS',
	'DELETE_SINGLE_CHAPTER_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
