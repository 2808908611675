import _ from 'lodash';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	borderRadius: '0 10px 10px 0',
	[`&.${linearProgressClasses.determinate}`]: {
	  backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
	  borderRadius: '0 10px 10px 0',
	},
}));

export default function LabeledLinearProgress({progress = 0, text}) {
	const label = String(text || `${progress}%`);
	return (
		<Box sx={{width: '100%', height: '100%', position: 'relative'}}>
			<BorderLinearProgress variant="determinate" value={progress} color="success" sx={{width: '100%', height: '100%'}} />
			<Typography sx={{position: 'absolute', top: '50%', left: 10, transform: 'translateY(-50%)'}}>{label}</Typography>
		</Box>
	)
}