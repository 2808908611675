import _ from 'lodash';

import { Navigate } from 'react-router-dom';
import * as AuthSelectors from 'modules/auth/selectors';
import { useSelector } from 'react-redux';

export default function PermittedRoute({page: Page, pageProps = {}, permission}) {
	const userPermissions = useSelector(AuthSelectors.userPermissions);

	if (userPermissions && !userPermissions.includes(permission)) {
		return <Navigate to="/app" />;
	}

	return <Page {...pageProps} />;
}