import { createSelector } from 'reselect';

export const sliceSelector = (state) => state.auth;
export const isLoggedIn = createSelector(sliceSelector, slice => !!slice.isLoggedIn)
export const loaders = createSelector(sliceSelector, slice => slice.loaders)
export const submitError = createSelector(sliceSelector, slice => slice.submitError)
export const loginFailureMessage = createSelector(sliceSelector, slice => slice.loginFailureMessage)
export const isAuthComplete = createSelector(sliceSelector, slice => slice.authComplete)
export const user = createSelector(sliceSelector, slice => slice.user)
export const userRole = createSelector(sliceSelector, slice => slice.user?.primaryRole)
export const userPermissions = createSelector(sliceSelector, slice => slice.user?.permissions || [])
export const userLevel = createSelector(sliceSelector, slice => slice.user?.selectedLevel)
