const style = {
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
	},
	typo: {
		color: theme => theme.palette.text.hint,
	},
	icon: {
		fontSize: 40,
		color: theme => theme.palette.text.hint,
	},
};

export default style;
