import React, { useState, useEffect } from "react";
import { Drawer as MuiDrawer, List, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {
	WorkspacePremium as WorkspacePremiumIcon,
	Group as GroupIcon,
} from "@mui/icons-material";
import { useTheme, styled } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";
import * as LevelSelectors from 'modules/levels/selectors';
import * as AuthSelectors from 'modules/auth/selectors';
import {useDispatch, useSelector} from 'react-redux';

// styles
import styles from "./styles";
import Logo from 'images/logo.png';

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
	toggleSidebar,
	useLayoutDispatch,
	useLayoutState,
} from "../../context/LayoutContext";
import { Actions } from "pages/actions";
import { levelOptions } from "utils/autoComplete";

const structure = [
	{ label: "משתמשים", link: "/app/admin/users", icon: <GroupIcon /> },
];


const Drawer = styled(MuiDrawer)(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: theme.sidebar.admin.width,
			paddingTop: theme.spacing(6),
			[theme.breakpoints.up('md')]: {
				paddingTop: theme.spacing(8),
			},
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9),
				},
			}),
		},
	}),
);

export default function AdminSidebar() {
	var theme = useTheme();
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const selectedLevel = useSelector(LevelSelectors.matchingLevelAlias) || '';
	const user = useSelector(AuthSelectors.user);
	const location = useLocation();

	// global
	var { isSidebarOpened } = useLayoutState();
	const layoutDispatch = useLayoutDispatch();
	// local
	var [isPermanent, setPermanent] = useState(true);

	useEffect(() => {
		if (!isPermanent) {
			toggleSidebar(layoutDispatch, false);
		}
	}, [location.key, isPermanent, layoutDispatch]);
	
	useEffect(function() {
		window.addEventListener("resize", handleWindowWidthChange);
		handleWindowWidthChange();
		return function cleanup() {
			window.removeEventListener("resize", handleWindowWidthChange);
		};
	});

	useEffect(() => {
		if (selectedLevel) {
			dispatch(Actions.SIDEBAR_LEVEL_CHANGED({level: selectedLevel}));
		}
	}, [selectedLevel, dispatch]);

	function handleLevelSelect(event) {
		if (event.target.value) {
			navigation(`/app/admin/level/${event.target.value}`);
		} else {
			navigation(`/app/admin`);
		}
			
	}
	const {role} = user || {};
	return (
		<Drawer
			variant={isPermanent ? "permanent" : "temporary"}
			sx={{
				...styles.drawer,
				...(isSidebarOpened ? styles.drawerOpen : styles.drawerClose),
			}}
			open={isSidebarOpened}
			anchor="left"
		>
			<Box sx={{p: '20px 10px', textAlign: 'center', filter: 'brightness(0)' }}>
				<img src={Logo} alt="logo" style={styles.logo} />
			</Box>
			<List sx={isSidebarOpened ? styles.sidebarListMargin : null}>
				<SidebarLink
					key="l"
					isSidebarOpened={isSidebarOpened}
					link={selectedLevel ? `/app/admin/level/${selectedLevel}` : '/app/admin'}
					exact
					label={(
						<FormControl fullWidth size="small">
							<InputLabel id="levelSelect">בחירת שלב</InputLabel>
							<Select size="small" label="בחירת שלב" labelId="levelSelect" fullWidth onChange={handleLevelSelect} onClick={e => {e.preventDefault(); e.stopPropagation();}} value={selectedLevel}>
								<MenuItem key="none" value="">בחירת שלב</MenuItem>
								{levelOptions.map(({id, name}) => (
									<MenuItem key={id} value={id}>{name}</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					icon={<WorkspacePremiumIcon />}
				/>
				{structure.filter(s => !s.hide && (role === 'admin' || !s.adminOnly)).map((link, i) => (
					<SidebarLink
						key={link.id || i}
						isSidebarOpened={isSidebarOpened}
						{...link}
					/>
				))}
			</List>
		</Drawer>
	);

	// ##################################################################
	function handleWindowWidthChange() {
		var windowWidth = window.innerWidth;
		var breakpointWidth = theme.breakpoints.values.md;
		var isSmallScreen = windowWidth < breakpointWidth;

		if (isSmallScreen && isPermanent) {
			setPermanent(false);
		} else if (!isSmallScreen && !isPermanent) {
			setPermanent(true);
		}
	}
}
