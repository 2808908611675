import _ from 'lodash';
import { put, takeLatest, call } from 'redux-saga/effects';
import { Actions as UserActions } from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';
import axios from 'services/axios';


function* fetchUsers(action) {
	yield put(UserActions.FETCH_USERS_REQUEST());
	try {
		const {pagination, filter, sortModel} = action.payload;
		const offset = pagination.pageSize * pagination.page;
		const payload = {query: {search: filter.text}};
		const orderBy = sortModel.length ? `user.${sortModel[0].field}` : '';
		const order = sortModel.length ? sortModel[0].sort : '';
		const url = `users/query?offset=${offset}&limit=${pagination.pageSize}&orderBy=${orderBy}&order=${order}`;
		const {data} = yield call(axios.post, url, payload);
		yield put(UserActions.FETCH_USERS_SUCCESS(data));        
	} catch (err) {
		console.error(err);
		yield put(UserActions.FETCH_USERS_FAILURE(err));
	}
}


// function* fetchSingleUser(action) {
// 	yield put(UserActions.FETCH_SINGLE_USER_REQUEST());
// 	try {
// 		const url = `users/${action.payload.id}`;
// 		const {data} = yield call(axios.get, url);
// 		yield put(UserActions.FETCH_SINGLE_USER_SUCCESS(data));        
// 	} catch (err) {
// 		console.error(err);
// 		yield put(UserActions.FETCH_SINGLE_USER_FAILURE(err));
// 	}
// }

function* deleteUser(action) {
	const {id} = action.payload;
	if (!id) {
		return;
	}
	yield put(UserActions.DELETE_USER_REQUEST());
	try {
		const {data} = yield call(axios.delete, `users/${id}`);
        
		yield put(UserActions.DELETE_USER_SUCCESS(data));
	} catch (err) {
		console.error(err);
		yield put(UserActions.DELETE_USER_FAILURE(err));
	}
}

function* submitUserForm(action) {
	try {
		yield put(UserActions.SUBMIT_USER_FORM_REQUEST());
		
		const {payload} = action;
		const userId = payload.id;

		const fields = _.pick(payload, [
			'name', 'email', 'permissions', 'selectedLevel', 'generatePassword',
		]);

		const method = userId ? axios.patch : axios.post;
		const url = userId ? `users/${userId}` : `/users`;
		const result = yield call(method, url, fields);

		yield put(UserActions.SUBMIT_USER_FORM_SUCCESS(result.data));
		
	} catch (err) {
		yield put(UserActions.SUBMIT_USER_FORM_FAILURE(err));
	}
}

const sagas = [
	takeLatest(PagesActionTypes.USERS_FILTERING_CHANGED, fetchUsers),
	takeLatest(PagesActionTypes.USER_DELETE_SUBMITTED, deleteUser),
	takeLatest(PagesActionTypes.SINGLE_USER_FORM_SUBMITTED, submitUserForm),
	takeLatest(PagesActionTypes.USER_RESET_PASSWORD_SUBMITTED, submitUserForm),
]

export default sagas;
