import React from "react";
import {Paper, Box, Grid, Typography, ButtonBase} from '@mui/material';
import {
	WorkspacePremium as WorkspacePremiumIcon,
	MenuBook as MenuBookIcon,
	DirectionsWalk as DirectionsWalkIcon,
	ListAlt as ListAltIcon,
	Chat as ChatIcon,
} from '@mui/icons-material';

// components
import PageTitle from "../../components/PageTitle";
import {Link} from 'react-router-dom';
import { useSelector } from "react-redux";
import * as AuthSelectors from 'modules/auth/selectors';
import * as ChaptersSelectors from 'modules/chapters/selectors';
import config from 'config/config';

function getUnits(user, chapters) {
	const units = [];
	const {permissions, selectedLevel} = user;

	if (permissions.includes('core')) {
		units.push({label: "קורס קונפידנס", link: `/app/chapters/${chapters[0]?.id}`, icon: MenuBookIcon })
	}

	if (permissions.includes('smallTalk')) {
		const page = config.units.smallTalk.levels[selectedLevel].pages[0];
		units.push({label: "Small Talk", link: page.link, icon: ChatIcon })
	}

	if (permissions.includes('byTheWay')) {
		units.push({label: "English By The Way", link: "/app/units/btw/welcome", icon: DirectionsWalkIcon })
	}

	if (permissions.includes('byTheWay')) {
		units.push({label: "5k Words", link: "/app/units/words/list", icon: ListAltIcon })
	}

	return units;
}

export default function PickUnitPage() {
	const chapters = useSelector(ChaptersSelectors.chapters);
	const user = useSelector(AuthSelectors.user);
	const units = getUnits(user, chapters)
	return (
		<>
			<PageTitle title="בחירת יחידה" icon={WorkspacePremiumIcon} />
			<Grid container spacing={{xs: 2, md: 10}} justifyContent="center">
				{units.map(({icon: Icon, label, link}, index) => (
					<Grid item key={index}>
						<ButtonBase>
							<Box component={Link} to={link} sx={{textDecoration: 'none'}}>
								<Paper sx={{px: 6, py: 10, textAlign: 'center', minWidth: 300}}>
									<Box>
										<Icon color="primary" sx={{fontSize: 60}} />
									</Box>
									<Typography variant='h1'>{label}</Typography>
								</Paper>
							</Box>
						</ButtonBase>
					</Grid>
				))}
			</Grid>
		</>
	)
}
