import { combineReducers } from 'redux';
import {reducer as authReducer, Sagas as authSagas} from 'modules/auth';
import {reducer as levelsReducer, Sagas as levelsSagas} from 'modules/levels';
import {reducer as chaptersReducer, Sagas as chaptersSagas} from 'modules/chapters';
import {reducer as usersReducer, Sagas as usersSagas} from 'modules/users';
import {reducer as unitReducer, Sagas as unitSagas} from 'modules/units';
import {reducer as questionReducer, Sagas as questionSagas} from 'modules/questions';
import {Sagas as navigationSagas} from 'modules/navigation';
import {Sagas as notifySagas} from 'modules/notify';
import { all } from 'redux-saga/effects'

export const getRootReducer = (options) => combineReducers({
	router: options.routerReducer,
	auth: authReducer,
	levels: levelsReducer,
	chapters: chaptersReducer,
	users: usersReducer,
	units: unitReducer,
	questions: questionReducer,
})

export function * rootSaga() {
	yield all([
		...authSagas,
		...navigationSagas,
		...notifySagas,
		...levelsSagas,
		...chaptersSagas,
		...usersSagas,
		...unitSagas,
		...questionSagas,
	])
}